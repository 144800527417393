import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { SearchService } from '../../services/search/search.service';
import { AnimalTypeInputValue, AnimalTypeStatus } from '../../models/animal-type.model';
import { OrderEntryService } from '../../../order-entry/order-entry.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cl-animal-type-field',
  templateUrl: './animal-type-field.component.html',
  styleUrls: ['../search-select/search-select.component.scss', './animal-type-field.component.scss'],
  providers: [
    SearchService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AnimalTypeFieldComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AnimalTypeFieldComponent,
      multi: true,
    },
  ],
})
export class AnimalTypeFieldComponent
  extends SearchSelectComponent<AnimalTypeInputValue>
  implements ControlValueAccessor
{
  // value can be an animal type or the missing info glyph
  value: AnimalTypeInputValue | string;
  @Input()
  searchUrl: string;
  @Input()
  operationalRegionCode: string;
  missingInformationGlyph = this.orderEntryService.missingInformationGlyph;
  // when this is true we will auto select the first returned item
  // and move to the next field
  autoselectShortCodeMatch = false;
  searchSub: Subscription;
  // update event does not fire on missing info glyph
  @Output()
  update = new EventEmitter<AnimalTypeInputValue | string>();

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor(
    public orderEntryService: OrderEntryService,
    public searchService: SearchService<AnimalTypeInputValue[]>,
    private translate: TranslateService
  ) {
    super();
  }

  handleEnter(event) {
    if (this.displayedData.length) {
      this.selectItem(this.displayedData[0]);
    } else if (!this.displayedData.length && this.displayValue && this.searching) {
      this.autoselectShortCodeMatch = true;
    }
  }

  selectItem(item: AnimalTypeInputValue | string, goToNext = true) {
    this.onValueChange(item, goToNext);
    this.hideOptions();
  }

  // Invalidate input if the user leaves the input after initiating search, but before selecting a value
  handleIncompleteInteraction() {
    requestAnimationFrame(() => {
      if (!this.isInputActiveElement() && !this.value) {
        this.selectItem(null);
        this.autoselectShortCodeMatch = false;
        this.onChange(null);
        this.hideOptions();
      }
    });
  }

  handleSearch(value: string) {
    this.hideOptions();

    // we're triggering a new search so any existing value needs to be cleared
    this.onValueChange(null);

    if (value) {
      if (value !== this.missingInformationGlyph) {
        this.searching = true;

        if (this.searchSub) {
          this.searchSub.unsubscribe();
        }

        this.searchSub = this.searchService
          .search({
            url: this.searchUrl,
            value: value,
            // URL parameters that will be added to the given searchUrl
            params: () => ({}),
            // URL parameter matches that are already existing in the given searchUrl
            interpolate: () => ({
              operationalRegionCode: this.operationalRegionCode,
            }),
          })
          .subscribe((res: AnimalTypeInputValue[]) => {
            this.searching = false;

            if (res.length) {
              if (!this.autoselectShortCodeMatch) {
                // Multiple matches, show options
                this.displayedData = res;
                // trigger control updates but do not write a value
                // this allows for validators to run
                this.onChange(null);
              } else {
                // Exact shortcode match
                this.autoselectShortCodeMatch = false;
                this.selectItem(res[0]);
              }
            } else {
              this.autoselectShortCodeMatch = false;
              this.onChange(null);
              this.hideOptions();
            }

            this.handleIncompleteInteraction();
          });
      } else {
        this.autoselectShortCodeMatch = false;
        this.onValueChange(this.missingInformationGlyph);
      }
    } else {
      this.searching = false;
      this.autoselectShortCodeMatch = false;
    }
  }

  handleBackspace() {
    setTimeout(() => {
      this.onChange(this.value);
    });
  }

  writeValue(value: AnimalTypeInputValue | string) {
    if (value === null) {
      this.resetInputValue();
    }

    this.onValueChange(value, false, false);
  }

  onValueChange(value: AnimalTypeInputValue | string, goToNext = true, emitEvent = true) {
    this.value = value;

    if (!this.value) {
      return;
    }

    if (emitEvent) {
      this.onChange(value);
    }

    if (value['animalTypeCode'] === this.missingInformationGlyph) {
      this.displayValue = this.missingInformationGlyph;
    } else if (this.value !== this.missingInformationGlyph) {
      if (AnimalTypeInputValue.isAnimalTypeInputValue(value) && value.status === AnimalTypeStatus.INACTIVE) {
        this.translate.get('ERRORS_AND_FEEDBACK.INACTIVE').subscribe((translation: string) => {
          this.displayValue = AnimalTypeInputValue.createLabel(value, translation);
        });
      } else {
        this.displayValue = AnimalTypeInputValue.createLabel(value as AnimalTypeInputValue);
      }

      // we dont fire this when we have a missing info glyph
      // we leave that to the clMoveNextOnMissing directive

      setTimeout(() => {
        if (goToNext) {
          this.goToNext.emit(true);
        }
      });
    }

    this.update.emit(this.value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  handleEscape($event) {
    if (this.displayedData?.length > 0) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.hideOptions();
      this.input.nativeElement.focus();
    }
  }

  validate(ctrl: FormControl): ValidationErrors | null {
    if (ctrl.dirty && this.searching === false) {
      if (ctrl.value === null && !this.displayValue) {
        return {
          invalid: true,
          required: { message: 'ERRORS_AND_FEEDBACK.REQUIRED', value: true },
        };
      } else if (ctrl.value === null && this.displayValue && !this.displayedData.length) {
        return {
          invalid: true,
          required: { message: 'ERRORS_AND_FEEDBACK.NO_MATCHES', value: true },
        };
      }
    } else {
      return null;
    }
  }

  protected readonly AnimalTypeStatus = AnimalTypeStatus;
  protected readonly AnimalTypeInputValue = AnimalTypeInputValue;
}
