import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgeDobComponent } from './age-dob.component';
import { ComponentsModule } from '../../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormModule } from '../date-form/date-form.module';
import { LuxLayoutModule } from '@lims-common-ux/lux';

@NgModule({
  declarations: [AgeDobComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    LuxLayoutModule,
    DateFormModule,
  ],
  exports: [AgeDobComponent],
})
export class AgeDobModule {}
