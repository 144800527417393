export class BrowserUtil {
  static _window: { navigator: any } = window;

  static isWindows(): boolean {
    return this._window.navigator.userAgent.toLowerCase().indexOf('windows') !== -1;
  }

  static isMac(): boolean {
    return this._window.navigator.userAgent.toLowerCase().indexOf('macintosh') !== -1;
  }
}
