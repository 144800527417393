import { DateTime } from 'luxon';

/*
This file is our only interface with luxon. This will allow us to change date-time tools if necessary in the
future with minimal effort.
*/

export class DateTimeUtil {
  private static zone: string;

  static get currentZone(): string {
    return DateTimeUtil.zone;
  }

  static setDefaultZone(zone: string) {
    this.zone = zone;
  }

  // Given a GMT string, translates to local time zone, and returns a string formatted in the given pattern
  // For acceptable patterns: See https://moment.github.io/luxon/#/formatting?id=presets
  static format(timestamp: string, pattern: string): string {
    const zdt = DateTime.fromISO(timestamp, { zone: this.zone });
    if (!zdt.isValid) {
      throw new Error(`Parse error: ${timestamp} can not be parsed`);
    }
    return zdt.toFormat(pattern);
  }

  // Returns zoned, current date time object
  static now(): any {
    return DateTime.local({ zone: this.zone });
  }

  // Returns zoned, current date in ISO-8601 string format, yyyy-mm-dd
  static getCurrentISODateString(): string {
    const _now = this.now().toISO();
    return this.format(_now, 'yyyy-MM-dd');
  }

  // Returns zoned, current date in UTC string, yyyy-mm-ddTHH:mm:ss.SSSZ
  static getCurrentUTCString(): string {
    return this.now().setZone('utc').toISO();
  }

  // Returns zoned, current date year as a string in format yyyy
  static getCurrentYearString(): string {
    return this.now().year.toString();
  }

  // Given a date in ISO-8601 string format yyyy-mm-dd, returns current UTC string, yyyy-mm-ddTHH:mm:ss.SSSZ
  static getISODateString(date: string): string {
    return DateTime.fromISO(date, { zone: this.zone }).toISO();
  }

  // Given a date string in the ISO-8601 format yyyy-mm-dd, and a number of years,
  // returns boolean evaluation of whether the period between the given and current
  // exceeds the given number of years.
  static greaterThanYears(date: string, years: number): boolean {
    const checkDate = DateTime.fromISO(this.getCurrentISODateString(), { zone: 'utc' });
    const difference = checkDate.diff(DateTime.fromISO(date, { zone: 'utc' }), ['years', 'months', 'days']);

    return difference.years > years || (difference.years === years && (difference.months > 0 || difference.days > 0));
  }

  // Given a date string in the ISO-8601 format yyyy-mm-dd,
  // returns true if given date is in the future compared to current
  static isFutureDate(date: string): boolean {
    const now = DateTime.fromISO(this.getCurrentISODateString(), { zone: 'utc' });
    return now < DateTime.fromISO(date, { zone: 'utc' });
  }

  // Given a date string in the ISO-8601 format yyyy-mm-dd,
  // returns true if given date can be parsed into a local date
  static isValidDate(date: string): boolean {
    if (!date) {
      return false;
    }

    const parsedDate = DateTime.fromISO(date, { zone: this.zone });
    return parsedDate.isValid;
  }

  // Given a year as a string, or number < 4-digits long,
  // returns an inferred year as a number.
  // This functionality makes an assumption of which century and which decade to return.
  // This might not be desirable in the future, or at all from a UX standpoint.
  static inferFullYear(year: number | string | any): number {
    year = year + '';
    const current = this.getCurrentYearString() + '',
      part1: any = current.slice(0, Math.max(0, current.length - year.length)),
      part2: any = current.slice(current.length - year.length, current.length);

    // The year zero does not exist in the Anno Domini (AD) system commonly used to number years in the Gregorian
    // calendar and in its predecessor, the Julian calendar. In this system, the year 1 BC is followed by AD 1.
    // https://en.wikipedia.org/wiki/Year_zero#:~:text=The%20year%20zero%20does%20not,is%20followed%20by%20AD%201.
    if (!year || year < 1 || isNaN(year)) {
      return null;
    } else if (year * 1 <= part2 * 1) {
      return parseInt(part1 + year, 10) || 0;
    } else {
      return parseInt(((part1 && `${part1 - 1}`) || '') + year, 10);
    }
  }

  // Given a Period in years, months, weeks, and days, returns an estimated past date in ISO-8601 format (yyyy-mm-dd)
  static estimateDateFromPeriod(period: { years: number; months: number; weeks: number; days: number }): string {
    const _estimatedDate = this.now()
      .minus({ years: period.years, months: period.months, weeks: period.weeks, days: period.days })
      .toISO();

    return this.format(_estimatedDate, 'yyyy-MM-dd');
  }

  // Given a number of years, returns current year minus given years as a 4-digit number
  static minusYears(years: number): number {
    return this.now().minus({ years }).year;
  }
}
