<div
  luxLayout="row"
  (keydown)="handleEvent($event)"
  (focusout)="handleEvent($event)"
  (paste)="handleEvent($event)"
  (input)="handleEvent($event)"
  (focus)="handleEvent($event)">
  <cl-field luxFill [errorValidations]="errorValidations" [microText]="microText" [label]="label">
    <cl-input
      #clInput
      autocorrect="off"
      spellcheck="false"
      (input)="onInput($event)"
      [(ngModel)]="searchString"
      [inputType]="'text'"
      [className]="'autocomplete-input'"
      [ngClass]="getInputClass()"
      [functionIndicator]="loading ? 'loader' : functionIndicator"
      [loading]="loading"
      [placeholder]="placeholder"></cl-input>
  </cl-field>
</div>
<!-- Matches Component allows AutocompleteComponent to dictate the rendering of a set of matches -->
<ng-template #matchesComponent></ng-template>
