<div
  luxLayout="row"
  luxFlexFill
  luxLayoutAlign="start center"
  [attr.is-dob-entry-mode]="dobEntryMode ? dobEntryMode : null"
  id="patientAgeDOB">
  <!-- AGE DISPLAY -->
  <div *ngIf="value && value.age && !value.dateOfBirth" luxLayout="row" id="ageSegments">
    <ng-container *ngFor="let ageSegment of value.segmentArray(); let index = index; let first = first">
      <a
        href="javascript:void(0)"
        class="pill-wrapper"
        (keydown.backspace)="removeAgeSegment(ageSegment)"
        (keydown.delete)="removeAgeSegment(ageSegment)"
        *ngIf="value.age[ageSegment.ageKey]">
        <cl-pill
          class="cl-age-dob-pill"
          [type]="'leopard-box'"
          [removable]="false"
          (remove)="removeAgeSegment(ageSegment)">
          {{ value.age[ageSegment.ageKey] }}{{ ageSegment.label | translate }}
        </cl-pill>
      </a>
    </ng-container>
  </div>
  <!-- /AGE DISPLAY -->

  <form [formGroup]="ageDOBForm" luxFlex luxLayout="row">
    <!-- BASE ENTRY MODE -->
    <input
      #ageInput
      *ngIf="dobEntryMode === false"
      title="{{ placeholder }}"
      class="spot-form__input cl-age-dob-input"
      formControlName="ageInput"
      type="text"
      (blur)="handleBlur()"
      (keydown.backspace)="removeAgeSegment()"
      (keydown.enter)="addAgeSegment()"
      (keydown)="handleKeydown($event)"
      [placeholder]="placeholder" />
    <!-- /BASE ENTRY MODE -->

    <!-- DOB MODE -->
    <cl-date-form
      *ngIf="dobEntryMode === true"
      #dobEntryForm
      luxFill
      [boundaryYears]="boundaryYears"
      formControlName="dobEntryForm">
    </cl-date-form>
    <!-- /DOB MODE -->
  </form>
</div>
