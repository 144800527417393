import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderEntryService, OrderIdResource } from '../order-entry.service';
import { AppStateService } from '../../app-state.service';
import { Customer } from '../../shared/models/customer.model';
import { SamplesAssociationsService } from '../../sample-association/samples-associations.service';

@Component({
  selector: 'cl-escalate-to-support',
  templateUrl: './escalate-to-support.component.html',
  styleUrls: ['./escalate-to-support.component.scss'],
})
export class EscalateToSupportComponent {
  @Input() preventOrderSave: boolean;
  @Input() orderEntryForm: FormGroup;
  @Input() orderResource: OrderIdResource;
  @Input() selectedCustomer: Customer;
  @Input() validationId: string;

  @ViewChild('escalateToCustomerSupport', { static: true }) escalateToCustomerSupportEl: ElementRef;

  loadingCustomerSupportEmail: boolean = false;

  constructor(
    private orderEntryService: OrderEntryService,
    private appStateService: AppStateService,
    private samplesAssociationsService: SamplesAssociationsService
  ) {}

  getCustomerSupportEmail(form: FormGroup) {
    this.loadingCustomerSupportEmail = true;

    this.orderEntryService
      .getCustomerSupportEmail(
        this.appStateService.existingAccession || this.orderResource,
        form,
        this.selectedCustomer,
        this.validationId,
        this.samplesAssociationsService.getSavableAssociations(form.get('testCodes').value)
      )
      .subscribe({
        next: (customerSupportEmail) => {
          this.loadingCustomerSupportEmail = false;
          const mailToLink = encodeURI(
            `mailto:${customerSupportEmail.to?.address || ''}?subject=${customerSupportEmail.subject}&body=${
              customerSupportEmail.body
            }`
          );
          window.open(mailToLink);
          this.escalateToCustomerSupportEl.nativeElement.focus();
        },
        error: () => {
          this.loadingCustomerSupportEmail = false;
        },
      });
  }
}
