import { HttpParams } from '@angular/common/http';
export class UrlUtil {
  static interpolateUrl(url: string, interpolationParams: { [key: string]: any }): string {
    interpolationParams = interpolationParams || {};
    // regex below replaces parts of strings between "{" and "}"
    // for example:
    //  interpolateUrl("hello/{value}", {value: "world"}) outputs "hello/world"
    //  interpolateUrl("{first}{second}{third}", {first: "1", second: "2", third: "3"}) outputs "123"
    const result = url.replace(/\{[^\}]{1,1000}\}/g, (param) => {
      param = param.slice(1, -1);
      if (param[0] === '?') {
        // ? is an indicator of optional query string parameter
        param = param.slice(1);
        const params = param.split(',');
        const paramsMapped = [];
        params.forEach((internalParam) => {
          if (internalParam in interpolationParams) {
            paramsMapped.push(`${internalParam}=${encodeURIComponent(interpolationParams[internalParam])}`);
          }
        });
        return paramsMapped.length ? `?${paramsMapped.join('&')}` : '';
      }

      return param in interpolationParams ? encodeURIComponent(interpolationParams[param]) : '';
    });
    return result;
  }

  static getQueryStringParams(name): string[] | null {
    const currentUrlHttpParams = new HttpParams({ fromString: window.location.href.split('?')[1] });
    return currentUrlHttpParams.getAll(name);
  }
}
