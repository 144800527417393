import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NgControl,
  NgForm,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FieldValidators } from '../../shared/components/field/field.validators';
import { Customer } from '../../shared/models/customer.model';

import { OrderEntryService } from '../order-entry.service';

@Component({
  selector: 'cl-customer-barcode-field',
  templateUrl: './customer-barcode-field.component.html',
  styleUrls: ['./customer-barcode-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerBarcodeFieldComponent),
      multi: true,
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CustomerBarcodeFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input', { static: false })
  input: ElementRef;
  control: AbstractControl = null;
  @Input()
  searching = false;
  previousSearchValue: string = null;
  disabled: boolean = false;
  val: string = null;
  @Output()
  beginSearch: EventEmitter<true> = new EventEmitter<true>();
  @Output()
  endSearch: EventEmitter<Customer[]> = new EventEmitter<Customer[]>();
  hasNoMatches = false;
  hasServiceError = false;
  onChange: Function = () => {};
  onTouched: Function = () => {};

  set value(val: string) {
    if (this.val !== val) {
      this.val = val;

      if (!this.val) {
        this.searching = false;
      }

      this.previousSearchValue = null;
      this.hasNoMatches = false;
      this.hasServiceError = false;

      if (this.input) {
        this.onChange(this.val);
      }
    }
  }

  get value(): string {
    return this.val;
  }

  constructor(
    private orderEntryService: OrderEntryService,
    private injector: Injector
  ) {}

  ngAfterViewInit(): void {
    const model = this.injector.get(NgControl);
    this.control = model.control;
  }

  handleInput(fieldVal: string): void {
    this.writeValue(fieldVal);
  }

  handleBlur() {
    if (this.control.errors !== null) {
      this.focus();
    }
  }

  handleLosingFocus($event: Element) {
    if (($event as HTMLButtonElement)?.name !== 'reset' && ($event as HTMLButtonElement)?.name !== 'save') {
      this.search();
    }
  }

  search() {
    this.onTouched(this.value);

    if (this.canSearch()) {
      this.disable();

      this.focus();

      this.beginSearch.emit(true);

      this.searching = true;
      this.previousSearchValue = this.value;

      this.orderEntryService
        .getCustomer({
          barcodeId: this.value,
        })
        .subscribe({
          next: (response) => {
            if (response.hasNoMatches || response.hasServiceError) {
              this.hasNoMatches = response.hasNoMatches;
              this.hasServiceError = response.hasServiceError;

              this.publishSearchResult(null);
            } else {
              this.publishSearchResult(response.data);
            }
          },
          error: (err) => {
            this.publishSearchResult(null);
          },
        });
    } else if (this.searching) {
      this.focus();
    }
  }

  publishSearchResult(customers: Customer[]) {
    this.searching = false;

    this.onChange(this.val);

    this.endSearch.emit(customers);

    this.enable();

    // setTimeout because we want to focus after fully toggling disabled state
    setTimeout(() => {
      this.focus();
    });
  }

  enable(): void {
    this.setDisabledState(false);
  }

  focus(): void {
    this.input.nativeElement.focus();
  }

  disable(): void {
    this.setDisabledState(true);
  }

  canSearch(): boolean {
    const hasNonSearchableErrors = FieldValidators.containsErrorsThatAreNot(this.control, [
      'serviceError',
      'serviceLoading',
      'noMatches',
    ]);

    return !!(
      !hasNonSearchableErrors &&
      this.value &&
      this.searching === false &&
      this.value !== this.previousSearchValue &&
      this.disabled === false
    );
  }

  // Start ControlValueAccessor implementaton
  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any): void {
    this.onTouched = onTouch;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // End ControlValueAccessor implementaton
}
