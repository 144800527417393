<div class="mail-to-button">
  <button
    #escalateToCustomerSupport
    id="escalateToCustomerSupport"
    [disabled]="preventOrderSave"
    data-cy="mail-to-link"
    (click)="!preventOrderSave && getCustomerSupportEmail(orderEntryForm)"
    class="spot-button spot-button--link spot-button--with-icon">
    <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
      <title></title>
      <use xlink:href="/assets/icons/spot_icons.svg#email"></use>
    </svg>
    <span class="spot-button__text">{{ 'LABELS.ESCALATE_TO_SUPPORT' | translate }}</span>
  </button>
  <svg *ngIf="loadingCustomerSupportEmail" class="spot-icon spot-loading-spinner" aria-labelledby="title">
    <title></title>
    <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
  </svg>
</div>
