<div class="sample-draw-time-field" data-cy="sample-draw-time-field">
  <label data-cy="sample-draw-time-label" [class.has-errors]="hasError" class="spot-form__label" tabindex="-1">
    {{ 'LABELS.DRAW_TIME' | translate }}
  </label>
  <div class="cl-field-wrapper" data-cy="sample-draw-time-field-wrapper" [class.spot-form--error]="hasError">
    <input
      #input
      tabindex="1"
      (keydown.backspace)="handleBackspace($event)"
      (keydown.arrowLeft)="handleArrowLeft()"
      (keydown.arrowRight)="handleArrowRight()"
      (keydown.arrowUp)="handleArrowUp()"
      (keydown.arrowDown)="handleArrowDown()"
      (keydown.shift.tab)="handleArrowUp()"
      (keydown.tab)="handleArrowDown()"
      (keydown.enter)="handleEnter($event)"
      data-cy="sample-draw-time-input"
      class="spot-form__input"
      type="text"
      autocomplete="off"
      placeholder="{{ 'LABELS.DRAW_TIME_PLACEHOLDER' | translate }}"
      [value]="fieldValue"
      (input)="handleInput($event)"
      name="sample-draw-time" />
  </div>
  <div data-cy="sample-draw-time-microcopy" class="spot-form__microcopy">
    <div class="cl-microcopy">{{ 'ERRORS_AND_FEEDBACK.DRAW_TIME_ENTRY' | translate }}</div>
  </div>
</div>
