import { AnimalTypeInputValue } from './animal-type.model';
import { PatientSex } from './patient-sex.model';
import { PatientAge } from '@lims-common-ux/lux';

export class Patient {
  animalType: AnimalTypeInputValue;
  customAnimalType: string;
  name: string;
  dateOfBirth: string;
  patientAge: PatientAge;
  microChip: string;
  sex: PatientSex;

  constructor() {}
}
