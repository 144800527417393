import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { LabsService } from '@lims-common-ux/lux';

@Component({
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss'],
})
export class UnavailableComponent implements OnInit {
  constructor(
    private router: Router,
    private labsService: LabsService
  ) {}

  ngOnInit() {
    if (this.labsService.currentLab == null) {
      this.router.navigateByUrl('/');
    }
  }
}
