import {
  ViewChild,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  Output,
  EventEmitter,
} from '@angular/core';

const DEFAULTS = {
  TYPE: 'leopard',
  OUTLINE: false,
  EDITABLE: false,
  REMOVABLE: false,
  CAN_UNDO_REMOVE: false,
};

@Component({
  selector: 'cl-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit, OnChanges {
  @ViewChild('removeIcon') removeIcon: ElementRef;

  @Input() outline: boolean = DEFAULTS.OUTLINE;
  @Input() type:
    | 'primary'
    | 'secondary'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'leopard'
    | 'leopard-box'
    | 'inactive'
    | string = DEFAULTS.TYPE;
  @Input() editable: boolean = DEFAULTS.EDITABLE;
  @Input() removable: boolean = DEFAULTS.REMOVABLE;
  @Input() canUndoRemove: boolean = DEFAULTS.CAN_UNDO_REMOVE;
  @Output() remove: EventEmitter<Event> = new EventEmitter();
  @Output() unremove: EventEmitter<Event> = new EventEmitter();

  constructor(
    public element: ElementRef,
    public renderer: Renderer2
  ) {}

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    if (this.editable) {
      this.renderer.setAttribute(this.element.nativeElement, 'contenteditable', 'true');
    } else {
      this.renderer.removeAttribute(this.element.nativeElement, 'contenteditable');
    }
  }

  doRemove($event) {
    if ($event) {
      $event.preventDefault();
    }
    this.remove.emit($event);
  }

  undoRemove($event) {
    if ($event) {
      $event.preventDefault();
    }
    this.unremove.emit($event);
  }

  getPillClass() {
    return `cl-pill spot-pill spot-pill--${this.type} ${this.outline ? 'spot-pill--outline' : ''}`;
  }

  focusRemove() {
    this.removeIcon.nativeElement.focus();
  }
}
