export enum AnimalTypeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class AnimalTypeInputValue {
  animalTypeCode: string = '';
  groupType: string = '';
  group: string = '';
  subGroup: string = ''; // This is the "OTHER" translated value
  supportsCustomType?: boolean;
  shortCode?: string = '';
  label?: string = '';
  status?: AnimalTypeStatus;

  static createLabel(animalType: AnimalTypeInputValue, inactiveAnimalType?: string): string {
    let label: string;
    let inactiveAnimalTypeLabel = '';
    if (inactiveAnimalType) {
      inactiveAnimalTypeLabel = `${inactiveAnimalType} `;
    }

    label = `${inactiveAnimalTypeLabel}${animalType.groupType}${
      (animalType.group && ` \u25B8 ${animalType.group}`) || ''
    }${(animalType.subGroup && ` \u25B8 ${animalType.subGroup}`) || ''}`;
    return label;
  }

  static isAnimalTypeInputValue(value: string | AnimalTypeInputValue): value is AnimalTypeInputValue {
    return (value as AnimalTypeInputValue)?.animalTypeCode !== undefined;
  }
}
