<div
  luxLayout="column"
  luxLayoutAlign="center center"
  luxFlex
  class="order-entry-general-information section"
  id="unavailableContainer">
  <p class="unavailable-message">
    {{ 'ERRORS_AND_FEEDBACK.TECHNICAL_DIFFICULTIES' | translate }}
    <a href="/">{{ 'ERRORS_AND_FEEDBACK.PLEASE_TRY_AGAIN' | translate }}</a>
  </p>
</div>
