<div>
  <cl-field-wrapper [label]="'LABELS.PET_OWNER_BILLING' | translate" [required]="pobRequired">
    <!-- MODAL TRIGGER -->
    <button
      #openButton
      id="pob-open-button"
      (click)="open()"
      (focus)="onFocus()"
      [ngClass]="_hasData ? 'has-data' : ''"
      class="spot-button spot-button--secondary spot-button--with-icon cl-pob-open cl-label-part">
      <span *ngIf="_hasData" [ngClass]="infoNeeded ? 'cl-pob-info-needed' : ''">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>{{ 'BUTTONS.EDIT' | translate }}</title>
          <use xlink:href="/assets/icons/spot_icons.svg#edit"></use>
        </svg>
      </span>
      <span *ngIf="!_hasData" [ngClass]="infoNeeded ? 'cl-pob-info-needed' : ''">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>{{ 'BUTTONS.ADD' | translate }}</title>
          <use xlink:href="/assets/icons/spot_icons.svg#add"></use>
        </svg>
      </span>
    </button>
    <!--/ MODAL TRIGGER -->

    <!-- POB ADDRESS DISPLAY -->
    <div *ngIf="data && lab && _hasData" id="selectedPetOwnerBilling" class="cl-owner-address spot-form__input">
      <div luxLayout="row" luxLayoutAlign="start start" luxLayoutGap="13px">
        <cl-address-display *ngIf="data" [address]="data"></cl-address-display>
      </div>
    </div>
    <!-- /POB ADDRESS DISPLAY -->

    <!-- MODAL -->
    <lux-modal-container
      #modal
      class="cl-pob-modal"
      (modalClose)="close()"
      [formGroup]="_form"
      [modalId]="'petOwnerBillingModal'"
      [modalWidth]="'80%'"
      [showHeader]="false"
      [visible]="visible"
      (keydown)="onKeyDown($event)">
      <!-- MODAL HEADER -->
      <div luxLayout="row" luxLayoutAlign="space-between center" style="padding: 0 10px">
        <h2>
          {{ 'LABELS.PET_OWNER_BILLING' | translate }}
        </h2>

        <div luxLayoutAlign="column" style="position: relative">
          <!-- GOOGLE PLACES SEARCH INPUT -->
          <cl-input
            #lookup
            class="cl-pob-lookup"
            (input)="lookupAddress($event)"
            (focusin)="handleLookupFocusin($event)"
            (focusout)="handleLookupFocusout($event)"
            [functionIndicator]="'searcher'"
            [placeholder]="'PLACEHOLDERS.TYPE_A_PLACE' | translate"
            (keydown)="onKeyDown($event)"></cl-input>
          <!-- /GOOGLE PLACES SEARCH INPUT -->

          <div
            id="addressSuggestionPopover"
            #popover
            (focusout)="hidePopover($event)"
            [attr.position]="'bottom-right'"
            *ngIf="!!(_showPopover && _addressSuggestions.length)"
            class="address-popover">
            <div
              *ngFor="let place of _addressSuggestions; let i = index; trackBy: addressByIndex"
              class="cl-pob-lookup-suggestion">
              <a
                #suggestion
                href="javascript:void(0)"
                class="suggestion-text"
                (blur)="blurPlace()"
                (click)="selectPlace(place)"
                (focus)="focusPlace(place)"
                (keydown.escape)="cancel().close()"
                (mouseenter)="previewPlace(place)"
                (mousedown)="prevent($event)"
                (mouseleave)="stopPreview()"
                [tabindex]="_showPopover ? 0 : -1">
                {{ place ? place.description : '' }}
              </a>

              <div class="google-logo" *ngIf="i === _addressSuggestions.length - 1"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- /MODAL HEADER -->

      <!-- DELETE ADDRESS -->
      <div>
        <a
          *ngIf="_hasData"
          class="cl-pob-delete"
          (click)="delete()"
          (keydown)="onKeyDown($event)"
          href="javascript:void(0)">
          <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
            <use xlink:href="/assets/icons/spot_icons.svg#delete"></use>
          </svg>
        </a>
      </div>
      <!-- /DELETE ADDRESS -->

      <!-- ADDRESS SEARCH FORM -->
      <form
        class="spot-form cl-pob-form"
        autocomplete="off"
        name="_form"
        (keydown.enter)="save($event)"
        (keydown)="onKeyDown($event)"
        [formGroup]="_form">
        <!-- MODAL BODY -->
        <div luxLayout="column" class="cl-pob-fields" luxLayoutGap="20px">
          <!-- ADDRESS FORM -->
          <div luxLayout="row" luxLayoutGap="20px" #fieldWrapper class="cl-pob-field-wrapper">
            <div
              #fields
              luxLayout="column"
              class="cl-pob-fields"
              luxLayoutGap="20px"
              [class.smaller]="_showPopover && _addressSuggestions.length">
              <div luxLayout="row" luxLayoutGap="20px">
                <cl-input
                  luxFlex="100%"
                  #street1
                  id="street1"
                  formControlName="street1"
                  [errorValidations]="getErrorValidations('street1')"
                  [class.preview]="(_previewData && _previewData['street1']) || false"
                  [label]="'LABELS.ADDRESS' | translate"></cl-input>
              </div>
              <div luxLayout="row" luxLayoutGap="20px">
                <cl-input
                  luxFlex="33%"
                  #countryCode
                  id="countryCode"
                  [class.preview]="(_previewData && _previewData['countryCode']) || false"
                  formControlName="countryCode"
                  [errorValidations]="getErrorValidations('countryCode')"
                  [label]="'LABELS.COUNTRY' | translate"></cl-input>
                <cl-input
                  luxFlex="33%"
                  #postalCode
                  id="postalCode"
                  [class.preview]="(_previewData && _previewData['postalCode']) || false"
                  formControlName="postalCode"
                  [errorValidations]="getErrorValidations('postalCode')"
                  [label]="'LABELS.POSTAL_CODE' | translate"></cl-input>
                <cl-input
                  luxFlex="33%"
                  #city
                  id="city"
                  formControlName="city"
                  [class.preview]="(_previewData && _previewData['city']) || false"
                  [errorValidations]="getErrorValidations('city')"
                  [label]="'LABELS.CITY' | translate"></cl-input>
              </div>
              <div luxLayout="row" luxLayoutGap="20px">
                <cl-input
                  luxFlex="66%"
                  #province
                  id="province"
                  [class.preview]="(_previewData && _previewData['province']) || false"
                  formControlName="province"
                  [errorValidations]="getErrorValidations('province')"
                  [label]="'LABELS.PROVINCE' | translate"></cl-input>
                <cl-input
                  luxFlex="33%"
                  #fiscalCode
                  id="fiscalCode"
                  [class.preview]="(_previewData && _previewData['fiscalCode']) || false"
                  formControlName="fiscalCode"
                  [errorValidations]="getErrorValidations('fiscalCode')"
                  [label]="'LABELS.FISCAL_CODE' | translate"></cl-input>
              </div>
            </div>
          </div>
          <!-- /ADDRESS FORM -->
        </div>
        <!-- /MODAL BODY -->
      </form>
      <!-- /ADDRESS SEARCH FORM -->

      <!-- FOOTER -->
      <div class="spot-modal__footer cl-modal-footer" luxLayout="row" luxLayoutAlign="end center" luxLayoutGap="20px">
        <cl-button
          (click)="save($event)"
          (keydown)="onKeyDown($event)"
          [disabled]="_form.invalid"
          [icon]="'save'"
          [title]="'BUTTONS.SAVE' | translate"
          [type]="'primary'"
          class="cl-modal-primary"
          >{{ 'BUTTONS.SAVE' | translate }}
        </cl-button>
        <cl-button
          #resetButton
          (click)="cancel().clear()"
          (keydown)="onKeyDown($event)"
          [icon]="'refresh-redo'"
          [title]="'BUTTONS.RESET' | translate"
          [type]="'secondary'"
          class="cl-modal-secondary1">
          {{ 'BUTTONS.RESET' | translate }}
        </cl-button>
      </div>
      <!-- /FOOTER -->
    </lux-modal-container>
    <!-- MODAL -->
  </cl-field-wrapper>
</div>
