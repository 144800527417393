export enum SAMPLE_ATTRIBUTE_TYPE {
  COLLECTION_METHOD = 'collectionMethod',
  DRAW = 'draw',
  TEMPERATURE = 'temperature',
}

export interface SelectedSampleAttributes {
  collectionMethod: SampleAttribute;
  draw: SampleAttribute;
  temperature: SampleAttribute;
}

export class SampleAttribute {
  name: string;
  value: string;

  static createFromReference(attributeRef: SampleAttribute): SampleAttribute {
    return {
      name: attributeRef.name,
      value: attributeRef.value,
    } as SampleAttribute;
  }
}

export class SampleAttributesByType {
  COLLECTION_METHOD: SampleAttribute[] = [];
  DRAW: SampleAttribute[] = [];
  TEMPERATURE: SampleAttribute[] = [];

  constructor() {}

  static createSampleAttributesByTypeFromRef(ref: SampleAttributesByTypeRef): SampleAttributesByType {
    const sampleAttributesByType = new SampleAttributesByType();
    if (ref) {
      Object.keys(ref).forEach((typeName) => {
        ref[typeName].forEach((attributeRef, i) => {
          sampleAttributesByType[typeName][i] = SampleAttribute.createFromReference(attributeRef);
        });
      });
    }
    return sampleAttributesByType as SampleAttributesByType;
  }
}

export interface SampleAttributesByTypeRef {
  COLLECTION_METHOD: SampleAttribute[];
  DRAW: SampleAttribute[];
  TEMPERATURE: SampleAttribute[];
}
