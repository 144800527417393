<form [formGroup]="dateForm" class="spot-form__input cl-date-form" [ngClass]="dateForm.disabled ? 'disabled' : ''">
  <ng-container *ngFor="let segment of order; let first = first; let last = last">
    <cl-date-segment-input
      #segmentInput
      [first]="first"
      [formControlName]="segment"
      [last]="last"
      [max]="CONFIG[segment.toUpperCase()].MAX"
      [min]="CONFIG[segment.toUpperCase()].MIN"
      [ngClass]="'cl-date-' + segment"
      [placeholder]="placeholders[segment]"
      (pastedData)="handlePasteEvent($event)"></cl-date-segment-input>

    <span class="cl-date-segment-separator" *ngIf="!last">
      {{ separator }}
    </span>
  </ng-container>
</form>
