import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { tap, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderEntryService } from '../order-entry.service';
import { Customer } from '../../shared/models/customer.model';
import { Field } from '../../shared/components/field/field.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { LoggerService } from '@lims-common-ux/lux';

@Component({
  selector: 'cl-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss'],
})
export class CustomerSearchComponent extends Field implements OnInit, OnDestroy {
  @Input()
  openLabel: string;
  @Input()
  duplicateIndicator: boolean = false;

  @Output()
  orderCustomer = new EventEmitter();

  @ViewChild('openButton', { static: true }) openButton;
  @ViewChild('searchButton', { static: true }) searchButton: ButtonComponent;
  @ViewChild('resetButton', { static: true }) resetButton: ButtonComponent;
  @ViewChild('cancelButton') cancelButton: ButtonComponent;

  @ViewChildren('customerCardLinks')
  customerCardLinks: QueryList<any>;

  @ViewChild('customerSearchResults', { static: true })
  customerSearchResults: ElementRef;

  @ViewChild('customerSearchCode', { static: true })
  customerSearchCode;

  _form: FormGroup = new FormGroup({});
  private _visible: boolean = false;

  customerMatchesSub: Subscription;
  customerMatches: Customer[];

  selectedCustomer: Customer;
  readyToSearch: boolean = false;
  displayResults: boolean = true;
  loading: boolean = false;
  phoneMicroText: string;
  phoneMicroTextDisplay: string; // the translated value
  formValueSub: Subscription;
  previousActiveElement: HTMLElement;
  windowWidth: number = window.innerWidth;
  processingEorder: boolean = false;

  constructor(
    public orderEntryService: OrderEntryService,
    public translate: TranslateService,
    element: ElementRef,
    private loggerService: LoggerService
  ) {
    super(element);
  }

  get visible(): boolean {
    return this._visible;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  ngOnInit() {
    this._form = new FormGroup({
      customerSearchCode: new FormControl('', []),
      customerSearchName: new FormControl('', []),
      customerSearchAddress: new FormControl('', []),
      customerSearchPostalCode: new FormControl('', []),
      customerSearchCity: new FormControl('', []),
      customerSearchProvince: new FormControl('', []),
      customerSearchCountry: new FormControl('', []),
      customerSearchPhone: new FormControl('', [Validators.minLength(3)]),
    });

    this.onChanges();

    // setup the microtext value for the phone field
    this.translate.get('ERRORS_AND_FEEDBACK.MIN_CHARACTERS_THREE').subscribe((translation: string) => {
      this.phoneMicroTextDisplay = translation;
    });

    this.reset();
  }

  ngOnDestroy(): void {
    this.formValueSub.unsubscribe();

    if (this.customerMatchesSub) {
      this.customerMatchesSub.unsubscribe();
    }
  }

  jumpReset() {
    if (!this._visible) {
      return;
    }

    this.resetButton.focusInput();
  }

  jumpSearch($event) {
    if (!this._visible) {
      return;
    }
    if ($event) {
      $event.preventDefault();
    }
    this.searchButton.focusInput();
  }

  onChanges(): void {
    this.formValueSub = this._form.valueChanges.subscribe((fields) => {
      this.readyToSearch = false;
      Object.keys(fields).forEach((fieldName) => {
        if (fields[fieldName] !== null && fields[fieldName] !== '') {
          let phoneValue: string;

          if (fieldName !== 'customerSearchPhone') {
            this.readyToSearch = true;
          } else {
            phoneValue = this._form.controls.customerSearchPhone.value;

            if (!this._form.controls.customerSearchPhone.valid || phoneValue.length < 3) {
              this.phoneMicroText = this.phoneMicroTextDisplay;
            } else {
              this.readyToSearch = true;

              this.phoneMicroText = '';
            }
          }
        } else {
          if (fieldName === 'customerSearchPhone') {
            this.phoneMicroText = '';
          }
        }
      });
    });
  }

  onKeyDown($event: KeyboardEvent) {
    if (!$event) {
      return;
    }

    // This component contains both the search input and the modal. We require the
    // following check for the modal._visible to allow for event bubbling.
    // If the search modal isn't visible, then bubble the event for handling
    if (!this._visible) {
      return $event;
    }

    switch ($event.key.toUpperCase()) {
      case 'S':
        if ($event.altKey) {
          this.preventDefaultStopPropagation($event);
          if (this.readyToSearch) {
            this.search($event);
          }
        }
        break;
      case 'R':
        if ($event.altKey) {
          this.preventDefaultStopPropagation($event);
          this.jumpReset();
        }
        break;
      case 'ENTER':
        if (this.readyToSearch) {
          this.preventDefaultStopPropagation($event);
          this.search($event);
        }
        break;
      case 'ESCAPE':
        this.preventDefaultStopPropagation($event);
        this.close();
        break;
      case 'TAB':
        if (
          this.customerCardLinks.last &&
          this.customerCardLinks.last?.nativeElement === $event.target &&
          !$event.shiftKey
        ) {
          // Trap tab on last search result card and focus first search result card
          this.preventDefaultStopPropagation($event);
          this.customerCardLinks.first?.nativeElement.focus();
        }
        break;
      default:
        return;
    }
  }

  selectCustomer(customer: Customer) {
    if (!customer.active) {
      return;
    }

    this.selectedCustomer = customer;
    this.orderCustomer.emit({
      customer: this.selectedCustomer,
      isEorder: this.processingEorder,
    });
    this.close();
  }

  reset(focusFirst: boolean = true): CustomerSearchComponent {
    this.displayResults = true;
    this.customerMatches = null;
    this.selectedCustomer = null;
    this.phoneMicroText = '';
    this._form.reset();
    this.setLoading(false);

    if (focusFirst) {
      this.customerSearchCode?.input.nativeElement.focus();
    }

    return this;
  }

  handleTriggerEvent($event) {
    this.preventDefaultStopPropagation($event);

    this.focusInput();

    requestAnimationFrame(() => {
      this.open();
    });
  }

  // Open modal, optionally with customer results to display from parent
  open(customers?: Observable<Customer[]>, isEorder?: boolean): CustomerSearchComponent {
    this.processingEorder = isEorder;
    this.duplicateIndicator = false;

    this.previousActiveElement = document.activeElement as HTMLElement;

    if (customers) {
      this.duplicateIndicator = true;

      customers.subscribe((matches) => {
        this.loggerService.logAction('oe-customer-search-modal-opened', {
          customers: matches.map((customer) => ({
            customerCode: customer?.customerCode,
            customerId: customer?.customerId,
          })),
        });
        this.customerMatches = matches;
        this.focusFirstResult();
      });
    }

    if (!this._visible) {
      this._visible = true;
      if (!customers) {
        // Wait for the modal and its content to fully paint before focusing the first input. Remote acceptance tests
        // require more of a timeout than is strictly necessary.
        setTimeout(() => {
          this.customerSearchCode.input.nativeElement.focus();
        }, 100);
      }
    }

    return this;
  }

  close(): CustomerSearchComponent {
    // Reset form field if customer search modal is opened but closed with no selection
    if (!this.selectedCustomer) {
      this.orderCustomer.emit({
        customer: null,
        isEorder: this.processingEorder,
      });
    }

    this.reset(false);

    if (this._visible) {
      this._visible = false;

      if (this._onTouched) {
        this._onTouched();
      }

      setTimeout(() => {
        if (!this.selectedCustomer && this.previousActiveElement) {
          this.previousActiveElement.focus();
        }
      }, 0);
    }

    return this;
  }

  prepareSearchOptions(): any {
    return {
      customerCode: this._form.get('customerSearchCode').value,
      name: this._form.get('customerSearchName').value,
      street1: this._form.get('customerSearchAddress').value,
      postalCode: this._form.get('customerSearchPostalCode').value,
      city: this._form.get('customerSearchCity').value,
      province: this._form.get('customerSearchProvince').value,
      countryCode: this._form.get('customerSearchCountry').value,
      phoneNumber: this.parsePhoneNumber(this._form.get('customerSearchPhone').value),
    };
  }

  search($event?: Event) {
    if ($event) {
      $event.preventDefault();
    }
    if (this.readyToSearch) {
      this.selectedCustomer = null;
      this.setLoading(true);
      this.displayResults = true;

      this.customerMatches = null;

      const searchOptions = this.prepareSearchOptions();

      this.customerMatchesSub = this.orderEntryService
        .searchCustomers(searchOptions)
        .pipe(
          tap((matches) => {
            this.setLoading(false);

            this.displayResults = !!matches.length;

            this.customerMatches = matches;

            this.focusFirstResult();
          })
        )
        .subscribe();
    }
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  focusFirstResult() {
    setTimeout(() => {
      if (this.displayResults && this.customerMatches && this.customerMatches.length > 0) {
        this.customerCardLinks.first?.nativeElement.focus();
      }
    }, 0);
  }

  setFocusPosition($event) {
    const leftOffset = $event.target.offsetLeft;
    const visibleAreaCenter = (this.windowWidth * 0.65) / 2;

    if (leftOffset > visibleAreaCenter) {
      this.customerSearchResults.nativeElement.scrollLeft = leftOffset - 470;
    }
  }

  focusInput() {
    this.openButton.nativeElement.focus();
  }

  blurInput() {
    this.openButton.nativeElement.blur();
  }

  parsePhoneNumber(phone: string): any {
    if (phone && phone.length > 2) {
      return phone;
    } else {
      return null;
    }
  }

  preventDefaultStopPropagation($event: KeyboardEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
