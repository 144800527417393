<label
  *ngIf="parentField.label || fieldLabelContent"
  class="spot-form__label"
  tabindex="-1"
  [ngClass]="errorValidations?.length > 0 ? 'has-errors' : ''">
  <abbr
    *ngIf="parentField.required"
    class="spot-form__label-required-indicator"
    title="This field is required."
    aria-hidden="true"
    >*</abbr
  >
  <span *ngIf="parentField.required" class="spot--screenreader-only"> Required Field. </span>
  <ng-content select="[clFieldLabel]"></ng-content>
  {{ parentField.label }}
</label>
<div [ngClass]="getClassName()">
  <ng-content></ng-content>
</div>
<div *ngIf="errorValidations?.length" class="spot-form__field-error">
  <span
    *ngFor="let error of errorValidations; last as isLast"
    [ngStyle]="!isLast && { marginBottom: 0 }"
    class="cl-field-inline-error-text spot-form__field-error-text"
    role="alert">
    {{ error && error.text ? (error.text | translate: error.args) : (error | translate) }}
  </span>
</div>
<div *ngIf="_getMicroTexts()?.length" class="spot-form__microcopy cl-microcopy">
  <ng-container *ngFor="let microText of _getMicroTexts()">
    <span *ngIf="errorValidations && errorValidations.length"><br /></span>
    <div>
      {{ microText && microText.text ? (microText.text | translate: microText.args) : (microText | translate) }}
    </div>
  </ng-container>
</div>
