<span contenteditable="false" [ngClass]="getPillClass()" (keydown.backspace)="doRemove($event)">
  <span class="cl-pill-text-content spot-pill__icon-label">
    <span class="cl-pill-content-wrapper">
      <ng-content></ng-content>
    </span>
    <!-- Cypress was being really tough on me here.
              Angular handles click and keydown.enter the same way,
              and using both on the same anchor element shouldn't be necessary,
              but I had to use both click and keydown.enter bindings here
              to get the acceptance tests working.-->
    <a
      *ngIf="removable"
      #removeIcon
      class="cl-pill-remove"
      href="javascript:void(0)"
      (keydown.enter)="doRemove($event)"
      (click)="doRemove($event)">
      <svg class="spot-icon" aria-labelledby="title">
        <title></title>
        <use xlink:href="/assets/icons/spot_icons.svg#delete"></use>
      </svg>
    </a>

    <a
      *ngIf="canUndoRemove"
      #unremoveIcon
      class="cl-pill-unremove"
      href="javascript:void(0)"
      (keydown.enter)="undoRemove($event)"
      (click)="undoRemove($event)">
      <svg class="spot-icon" aria-labelledby="title">
        <title></title>
        <use xlink:href="/assets/icons/spot_icons.svg#undo"></use>
      </svg>
    </a>
  </span>
</span>
