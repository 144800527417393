import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { StringUtil } from '../../utils';

export interface FieldValidator {
  (): FieldValidator;

  key(): string;

  key(v: string): FieldValidator;

  validator(): any;

  validator(v: any): FieldValidator;

  message(): Function;

  message(v: (result: any) => string | { text: string; args: Object }): FieldValidator;
}

export class FieldValidators {
  static autocompleteRequired = () => {
    return FieldValidators.create((field: FormControl) => {
      const errMsg = {
        required: true,
        text: 'ERRORS_AND_FEEDBACK.REQUIRED',
      };

      if (!field.value || (Array.isArray(field.value) && field.value.length === 0)) {
        if (!(field.dirty || field.touched)) {
          errMsg.text = '';
        }

        return errMsg;
      }

      return null;
    }).message((errMsg) => errMsg);
  };

  static requiresValidSampleValue = () => {
    return FieldValidators.create((field: FormControl) => {
      const errMsg = {
        required: true,
        text: 'ERRORS_AND_FEEDBACK.REQUIRED',
      };

      // NOTE: the field.value is of type SampleFormValue
      let removedValuesLength;
      if (field.value && field.value['samples']) {
        removedValuesLength = field.value['samples'].filter(
          (item) => item['isRemoved'] || item.status === 'REMOVED'
        ).length;
      }

      if (
        !field.value ||
        ((field.value['samples'].length === 0 ||
          (removedValuesLength > 0 && field.value['samples'].length === removedValuesLength)) &&
          !field.value['missingSample'])
      ) {
        if (!(field.dirty || field.touched)) {
          errMsg.text = '';
        }

        return errMsg;
      }

      return null;
    }).message((errMsg) => errMsg);
  };

  static autocompleteNoMatches = (
    cmp: SearchSelectComponent<any>,
    missingInformationGlyph: string,
    singleValue = true
  ) => {
    return FieldValidators.create((field: FormControl) => {
      const errMsg = {
        text: 'ERRORS_AND_FEEDBACK.NO_MATCHES',
      };

      if (
        ((!field.value && singleValue) || !singleValue) &&
        cmp.displayValue &&
        !cmp.loading &&
        !cmp.searching &&
        cmp.displayValue !== missingInformationGlyph &&
        !cmp.displayedData.length
      ) {
        return errMsg;
      }

      return null;
    }).message((errMsg) => errMsg);
  };

  static requiredEvenOnTouch = () => {
    return FieldValidators.create((field: FormControl) => {
      const errMsg = {
        required: true,
        text: 'ERRORS_AND_FEEDBACK.REQUIRED',
      };

      if (!field.value) {
        if (!(field.dirty || field.touched)) {
          errMsg.text = '';
        }

        return errMsg;
      }

      return null;
    }).message((errMsg) => errMsg);
  };

  static emailAndFax = (valueCallback) => {
    return FieldValidators.create(() => {
      const cmpValues = valueCallback();

      if (cmpValues) {
        const { value } = cmpValues;
        const hideError = cmpValues.hideError;

        if (value && hideError === false) {
          if (!StringUtil.isEmail(value) && !StringUtil.isFax(value)) {
            return {};
          }
        }
      }

      return null;
    }).message(() => 'ERRORS_AND_FEEDBACK.INVALID_EMAIL_FAX');
  };

  static noMatches = (hasNoMatchesFn: () => boolean, message?) =>
    FieldValidators.create((field) => (hasNoMatchesFn() ? { noMatches: true } : null)).message(
      message || (() => 'ERRORS_AND_FEEDBACK.NO_MATCHES')
    );

  static notLoading = (getLoadingComponentFn: () => { loading: boolean }) =>
    FieldValidators.create((control) => {
      if (getLoadingComponentFn().loading) {
        return { notLoading: true };
      }
      return null;
    }).message(() => '');

  static serviceError = (errorFn: (control?) => boolean | any, message?) =>
    FieldValidators.create((control) =>
      errorFn(control) ? { serviceError: 'ERRORS_AND_FEEDBACK.SERVICE_ERROR' } : null
    ).message(message || ((errors) => (errors ? { text: errors.serviceError } : null)));

  static inactiveCustomerError = (errorFn: (control?) => boolean | any, message?) =>
    FieldValidators.create((control) =>
      errorFn(control) ? { inactiveCustomerError: 'ERRORS_AND_FEEDBACK.INACTIVE_CUSTOMER' } : null
    ).message(message || ((errors) => (errors ? { text: errors.inactiveCustomerError } : null)));

  static serviceLoading = (loadingFn: (control?) => boolean | any, message?) =>
    FieldValidators.create((control) => (loadingFn(control) ? { serviceLoading: '' } : null)).message(() => '');

  static exactLength = (value, message?) =>
    FieldValidators.create((control) =>
      !control.value || `${control.value}`.length === value ? null : { exactLength: true }
    ).message(message || (() => ({ text: 'ERRORS_AND_FEEDBACK.EXACT_LENGTH', args: { value } })));

  static minLength = (value, message?) =>
    FieldValidators.create(Validators.minLength(value)).message(
      message || (() => ({ text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH', args: { value } }))
    );

  static maxLength = (value, message?) =>
    FieldValidators.create(Validators.maxLength(value)).message(
      message || (() => ({ text: 'ERRORS_AND_FEEDBACK.MAX_LENGTH', args: { value } }))
    );

  static pattern = (value, message?) =>
    FieldValidators.create(Validators.pattern(value)).message(
      message || (() => ({ text: 'ERRORS_AND_FEEDBACK.PATTERN', args: { value } }))
    );

  static create(validator): FieldValidator {
    let getMessage, key;

    const accessor = function _FieldValidator() {
      const result = validator.apply(this, arguments),
        message = getMessage && getMessage(result),
        objKey = result ? Object.keys(result)[0] : undefined,
        k = key !== undefined ? key : `_${objKey}`;

      if (result && k !== undefined) {
        result[k] = {
          value: result[objKey],
          message,
        };
      }
      return result;
    } as any;
    accessor['key'] = function (v): FieldValidator {
      if (arguments.length) {
        key = v;
      } else {
        return key;
      }
      return this;
    };
    accessor['validator'] = function (v) {
      if (arguments.length) {
        validator = v;
      } else {
        return validator;
      }
      return this;
    };
    accessor['message'] = function (v) {
      if (arguments.length) {
        getMessage = v;
      } else {
        return getMessage;
      }
      return this;
    };
    return accessor as FieldValidator;
  }

  // helper function that determines if the given control contains any errors that are not in the exclusion list
  static containsErrorsThatAreNot(control: AbstractControl, notErrors: string[]) {
    if (control && control.errors) {
      const errors = control.errors;
      let allowedErrors = 0;
      notErrors.forEach((error) => {
        if (error in errors) {
          allowedErrors += 1;
        }
        if (`_${error}` in errors) {
          allowedErrors += 1;
        }
      });
      if (Object.keys(errors).length > allowedErrors) {
        return true;
      }
    }
    return false;
  }
}
