import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { ReferenceData } from '../shared/models/reference-data.model';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { AppStateService } from '../app-state.service';
import { Lab, LabsService } from '@lims-common-ux/lux';
import { OperationalRegionComponent } from './operational-regions/operational-region.component';

@Component({
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.scss'],
})
export class OrderEntryComponent implements OnInit, OnDestroy {
  @ViewChild('operationalRegion') operationalRegionComponent: OperationalRegionComponent;

  routeSub: Subscription;

  get selectedLab(): Lab {
    return this.labsService.currentLab;
  }

  get referenceData(): ReferenceData {
    return this.appState.referenceData;
  }

  constructor(
    private route: ActivatedRoute,
    private labsService: LabsService,
    private appState: AppStateService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    // this drives accession view and order entry form presentation
    this.routeSub = this.route.params
      .pipe(
        tap((params) => {
          if (!params.accessionId) {
            this.appStateService.resetAccession();
          }
        })
      )
      .subscribe((params) => {});
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
