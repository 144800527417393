<ng-container [ngSwitch]="selectedLab.operationalRegionCode">
  <ng-container *ngSwitchCase="'CENTRAL_EUROPE'">
    <cl-central-europe-region
      #operationalRegion
      [editViewDefaultField]="'collectionDate'"
      [supportsBarcodeTranslation]="!!referenceData._links.barcodeTranslation"
      [availablePatientSexes]="referenceData.sexes"
      [availableDiscounts]="referenceData.discounts"
      [animalTypeSearchUrl]="referenceData._links.animalTypeSearch.href"
      [availableCustomerMessages]="referenceData.standardCustomerMessages"
      [availableSamples]="referenceData.samples">
    </cl-central-europe-region>
  </ng-container>
  <ng-container *ngSwitchCase="'UK'">
    <cl-uk-region
      #operationalRegion
      [editViewDefaultField]="'requisitionInfo'"
      [supportsBarcodeTranslation]="!!referenceData._links.barcodeTranslation"
      [availablePatientSexes]="referenceData.sexes"
      [availableDiscounts]="referenceData.discounts"
      [animalTypeSearchUrl]="referenceData._links.animalTypeSearch.href"
      [availableCustomerMessages]="referenceData.standardCustomerMessages"
      [availableSamples]="referenceData.samples">
    </cl-uk-region>
  </ng-container>
  <ng-container *ngSwitchCase="'AUSTRALIA'">
    <cl-australia-region
      #operationalRegion
      [editViewDefaultField]="'requisitionInfo'"
      [supportsBarcodeTranslation]="!!referenceData._links.barcodeTranslation"
      [availablePatientSexes]="referenceData.sexes"
      [availableDiscounts]="referenceData.discounts"
      [animalTypeSearchUrl]="referenceData._links.animalTypeSearch.href"
      [availableCustomerMessages]="referenceData.standardCustomerMessages"
      [availableSamples]="referenceData.samples">
    </cl-australia-region>
  </ng-container>
  <ng-container *ngSwitchCase="'CANADA'">
    <cl-canada-region
      #operationalRegion
      [editViewDefaultField]="'customerCode'"
      [supportsBarcodeTranslation]="!!referenceData._links.barcodeTranslation"
      [availablePatientSexes]="referenceData.sexes"
      [availableDiscounts]="referenceData.discounts"
      [animalTypeSearchUrl]="referenceData._links.animalTypeSearch.href"
      [availableCustomerMessages]="referenceData.standardCustomerMessages"
      [availableSamples]="referenceData.samples">
    </cl-canada-region>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <!-- USA Region is default -->
    <cl-us-region
      #operationalRegion
      [editViewDefaultField]="'customerCode'"
      [supportsBarcodeTranslation]="!!referenceData._links.barcodeTranslation"
      [availablePatientSexes]="referenceData.sexes"
      [availableDiscounts]="referenceData.discounts"
      [animalTypeSearchUrl]="referenceData._links.animalTypeSearch.href"
      [availableCustomerMessages]="referenceData.standardCustomerMessages"
      [availableSamples]="referenceData.samples">
    </cl-us-region>
  </ng-container>
</ng-container>
