import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DateFormComponent } from './date-form.component';
import { DateFormService } from './date-form.service';
import { DateSegmentInputComponent } from './date-segment-input/date-segment-input.component';
import { DateSegmentInputService } from './date-segment-input/date-segment-input.service';
import { ComponentsModule } from '../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DateFormComponent, DateSegmentInputComponent],
  exports: [DateFormComponent, DateSegmentInputComponent],
  imports: [CommonModule, ComponentsModule, ReactiveFormsModule, TranslateModule.forChild({})],
  providers: [DateFormService, DateSegmentInputService],
})
export class DateFormModule {}
