import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  SAMPLE_ATTRIBUTE_TYPE,
  SampleAttribute,
  SampleAttributesByType,
  SelectedSampleAttributes,
} from '../../models/sample-attribute.model';
import { Sample, DrawTime } from '../../models/sample.model';
import { AppStateService } from '../../../app-state.service';
import { SampleDrawTimeComponent } from '../sample-draw-time/sample-draw-time.component';

@Component({
  selector: 'cl-sample-attributes',
  templateUrl: './sample-attributes.component.html',
  styleUrls: ['./sample-attributes.component.scss'],
})
export class SampleAttributesComponent implements OnInit {
  // Close flyout on whitespace click/loss of focus within
  @HostListener('document:mousedown', ['$event'])
  handleDocumentMousedown() {
    if (this.showAttributes && !this.hasFocusWithin) {
      this.toggleHideAttributes();
    }
  }

  // The trigger to show or hide the attribute selection panel may be hidden
  @Input()
  showEditTrigger: boolean = true;

  @Input()
  sample: Sample;

  // Set id prefix to distinguish new sample vs. previously added sample implementations
  @Input()
  sampleAttributeIdPrefix: string;

  // Outputting a Sample with currently assigned attributes
  @Output()
  attributesAssigned: EventEmitter<Sample> = new EventEmitter<Sample>();

  // Output hasNoChanges event
  @Output()
  newSampleAttributesFlyoutClosedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('sampleAttributesWrapper')
  sampleAttributesWrapper!: ElementRef;

  @ViewChild('sampleAttributesTrigger')
  sampleAttributesTrigger!: ElementRef;

  @ViewChild('drawTime')
  drawTime!: SampleDrawTimeComponent;

  selectedSampleAttributes: SelectedSampleAttributes;

  showAttributes: boolean;

  sampleAttributesByType: SampleAttributesByType;

  hasFocusWithin: boolean;

  drawTimeValue: DrawTime = {};

  constructor(private appStateService: AppStateService) {}

  ngOnInit(): void {
    // If no supported attributes exist on the sample, check the reference data
    if (this.sample.supportedAttributes?.length < 1) {
      const found = this.appStateService.referenceData.samples.find(
        (sampleRef) => sampleRef.code === this.sample.code
      )?.supportedAttributes;
      if (found?.length > 0) {
        this.sample.supportedAttributes = found;
      }
    }

    this.sampleAttributesByType = this.createSampleAttributesByTypeFromRef();

    if (this.sample.drawTime) {
      this.drawTimeValue = this.sample.drawTime;
    }
  }

  handleDrawTimeChange($event) {
    if (this.drawTimeValue) {
      this.sample.drawTime = this.drawTimeValue;
    } else {
      this.sample.drawTime = null;
    }
  }

  createSampleAttributesByTypeFromRef(): SampleAttributesByType {
    return SampleAttributesByType.createSampleAttributesByTypeFromRef(
      this.appStateService.referenceData.sampleAttributesByType
    );
  }

  toggleShowAttributes($event?) {
    $event?.preventDefault();
    $event?.stopImmediatePropagation();

    // Initialize selected attributes each time the flyout opens
    this.initializeSelectedAttributes();

    this.showAttributes = true;

    // Handle focus and presentation alignment
    requestAnimationFrame(() => {
      this.translatePosition();
      this.focusFirstAttribute();
    });
  }

  translatePosition(): void {
    const sampleElementId = this.sample.code ? this.sample.code : '';
    // Viewport
    const formRect = document.getElementById('orderEntryForm').getBoundingClientRect();
    // Sample line item: Either new sample vs. previously added sample implementation
    const sampleRect = document.getElementById(this.sampleAttributeIdPrefix + sampleElementId).getBoundingClientRect();
    // Attributes flyout
    const boundingRect = this.sampleAttributesWrapper.nativeElement.getBoundingClientRect();
    const offset = formRect.height - sampleRect.y - (boundingRect.height + 50);
    if (offset < 0) {
      this.sampleAttributesWrapper.nativeElement.style.setProperty('top', offset + 'px');
    } else {
      this.sampleAttributesWrapper.nativeElement.style.setProperty('top', boundingRect.height / 2 + 'px');
    }
  }

  toggleHideAttributes($event?) {
    $event?.preventDefault();
    $event?.stopImmediatePropagation();
    this.showAttributes = false;

    // The sampleAttributesTrigger is visible when editing a previously added sample
    if (this.sampleAttributesTrigger?.nativeElement) {
      requestAnimationFrame(() => {
        this.sampleAttributesTrigger?.nativeElement.focus();
      });
    } else {
      // When the flyout is closed while adding a new sample, emit
      this.newSampleAttributesFlyoutClosedEvent.emit(true);
    }
  }

  // Emit selected attributes to host parent
  assignAttributes() {
    if (
      this.sample.collectionMethod !== this.selectedSampleAttributes.collectionMethod?.value ||
      this.sample.draw !== this.selectedSampleAttributes.draw?.value ||
      this.sample.temperature !== this.selectedSampleAttributes.temperature?.value
    ) {
      this.sample.collectionMethod = this.selectedSampleAttributes.collectionMethod;
      this.sample.draw = this.selectedSampleAttributes.draw;
      this.sample.temperature = this.selectedSampleAttributes.temperature;

      if (this.drawTimeValue) {
        this.sample.drawTime = this.drawTimeValue;
      }

      this.attributesAssigned.emit(this.sample);
    }

    this.toggleHideAttributes();
  }

  toggleAttributeSelection(attribute, $event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    // Maintain visual consistency between keyboard vs mouse navigation highlights. There are nested
    // focusable-on-click DOM elements (label and input) in the spot component.
    // We need to explicitly control focus on click vs. focus on keyboard interactions when
    // selecting and de-selecting attributes
    if ($event.type === 'click') {
      document.getElementById(this.sampleAttributeIdPrefix + attribute.value).focus();
    }

    this.setSelectedAttribute(attribute);
  }

  initializeSelectedAttributes(): void {
    // Initialize the attribute selection panel with existing sample attribute selections
    this.selectedSampleAttributes = {} as SelectedSampleAttributes;
    this.selectedSampleAttributes.collectionMethod = this.sample.collectionMethod as SampleAttribute;
    this.selectedSampleAttributes.draw = this.sample.draw as SampleAttribute;
    this.selectedSampleAttributes.temperature = this.sample.temperature as SampleAttribute;
  }

  // Add or remove an attribute to the selected attributes, and allow only one selection by type
  setSelectedAttribute(attribute: SampleAttribute) {
    let found = false;
    let foundType = '';
    Object.keys(this.sampleAttributesByType).forEach((attributeType) => {
      if (!found) {
        found = this.sampleAttributesByType[attributeType].filter(
          (attributeByType) => attributeByType.value === attribute.value
        )[0];
        if (found) {
          foundType = SAMPLE_ATTRIBUTE_TYPE[attributeType];
        }
      }
    });
    const dupe = this.selectedSampleAttributes[foundType]?.value === attribute.value;
    this.selectedSampleAttributes[foundType] = dupe ? null : attribute;
  }

  // Utility to determine spot checkbox presentation
  isSelected(attribute: SampleAttribute): boolean {
    return (
      this.selectedSampleAttributes.collectionMethod?.value === attribute.value ||
      this.selectedSampleAttributes.draw?.value === attribute.value ||
      this.selectedSampleAttributes.temperature?.value === attribute.value
    );
  }

  // Utility to determine close on click out
  handleFocusIn(): void {
    this.hasFocusWithin = true;
  }

  // Utility to determine close on click out
  handleFocusOut(): void {
    this.hasFocusWithin = false;
  }

  // Keyboard navigation bindings
  handleArrowLeft($event, i): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    const numTypes = this.sample.supportedAttributes.length;

    // close the flyout or focus first selection in previous column
    const typeId = i - 1 < 0 ? numTypes - 1 : i - 1;

    if (i === 0) {
      this.toggleHideAttributes($event);
    } else {
      document.getElementById(this.getAttributeId(typeId, '0')).focus();
    }
  }

  handleArrowRight($event, i): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    const numTypes = this.sample.supportedAttributes.length + 1;

    // focus first selection in first column or first selection in next column
    const typeId = i + 1 > numTypes - 1 ? '0' : i + 1;

    if (typeId !== numTypes - 1) {
      document.getElementById(this.getAttributeId(typeId, '0')).focus();
    } else {
      this.drawTime.focus();
    }
  }

  handleArrowUp($event, i, j): void {
    if (i === 0 && j === 0) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      this.drawTime.focus();
    } else {
      return $event;
    }
  }

  handleArrowDown($event, i, j): void {
    if (this.getAttributeId(i, j) === this.getLastAttributeId()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      this.drawTime.focus();
    } else {
      return $event;
    }
  }

  focusFirstAttribute(): void {
    document.getElementById(this.getAttributeId('0', '0')).focus();
  }

  focusLastAttribute(): void {
    document.getElementById(this.getLastAttributeId()).focus();
  }

  getLastAttributeId(): string {
    const numSupportedTypes = this.sample.supportedAttributes.length - 1;
    const lastTypeName = this.sample.supportedAttributes[numSupportedTypes];
    const numSampleAttributesByLastType = this.sampleAttributesByType[lastTypeName].length - 1;
    return this.getAttributeId(numSupportedTypes, numSampleAttributesByLastType);
  }

  getAttributeId(i, j): string {
    return this.sampleAttributeIdPrefix + i + '_' + j;
  }

  handleDrawTimeArrowDown() {
    requestAnimationFrame(() => {
      this.focusFirstAttribute();
    });
  }

  handleDrawTimeArrowLeft() {
    requestAnimationFrame(() => {
      this.handleArrowLeft(new KeyboardEvent('ArrowLeft'), this.sample.supportedAttributes.length);
    });
  }

  handleDrawTimeArrowRight() {
    requestAnimationFrame(() => {
      this.focusFirstAttribute();
    });
  }

  handleDrawTimeArrowUp() {
    requestAnimationFrame(() => {
      this.focusLastAttribute();
    });
  }
}
