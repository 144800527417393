import { from as observableFrom, Observable, Subject, Subscription, tap } from 'rxjs';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { DomUtil, StringUtil } from '../../utils';
import { Field } from '../field/field.component';
import { InputComponent } from '../input/input.component';
import { SearchService } from '../../services/search/search.service';
import * as _ from 'lodash';
import { MatchesComponent } from '../matches/matches.component';
import { KeyboardService } from '@lims-common-ux/lux';

@Component({
  selector: 'cl-autocomplete-component',
  templateUrl: './autocomplete.component.html',
  // SearchService needs to be in providers to create a separate instance per component
  providers: [...Field.Providers(AutocompleteComponent), SearchService],
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent<T extends { label: string } = any>
  extends Field
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
  @ViewChild('clInput', { static: true }) clInput: InputComponent;
  @ViewChild('matchesComponent', { read: ViewContainerRef, static: true })
  public matchesCmp: ViewContainerRef;
  matchingCmp: any;

  onSelectSub: Subscription; // subscription to the child matching component for selections
  @Input()
  matchesComponent = MatchesComponent;

  @Input()
  interpolate: () => { [key: string]: any };

  @Input()
  params: () => { [key: string]: any };

  @Input()
  useFuzzyMatch: boolean = false;

  // Returns object selected
  @Output()
  onSelect = new EventEmitter<T | string>();
  @Output()
  noMatchesCallback = new EventEmitter();
  @Output()
  inputchange = new EventEmitter<string>();
  @Output()
  onSearchComplete = new EventEmitter();

  @Input()
  useSearchService: boolean | (() => boolean);
  @Input()
  searchUrl: string = 'http://localhost:4200/pages/order-entry';
  @Input()
  mapResponse: (any) => T[];
  @Input()
  resetOnBlur: boolean = false;

  @Input()
  placeholder: string;

  // Data for the search
  @Input()
  data: T[];
  // Number of characters before search
  @Input()
  minLength: number;
  // Max number of matches to show
  @Input()
  matchesLimit: number;
  // Attribute to match on
  @Input()
  matchAttributes: string[];
  // Attribute of selection to present in input field, emits to parent if not set
  @Input()
  retainSelection: string;
  @Input()
  disabled: boolean;
  @Input()
  allowMultipleEntry: boolean = false;
  @Input()
  allowFreeText: boolean = false;
  @Input()
  functionIndicator: string = 'searcher';
  @Input()
  allowMultipleLines: boolean = false;
  @Input()
  allowSelectionEdit: boolean = false;
  @Input()
  allowSelectionEditEnabled: boolean = false;

  @Input()
  missingInformationGlyph: string = '';
  @Input()
  missingInformationGlyphObj: any;
  @Input()
  loading: boolean = false;
  @Output()
  initiateOrderValidation = new EventEmitter();

  _searchString: string = '';
  showAll: boolean = false;

  action = new Subject<Event>();

  processEnterAfterSearch = false;
  newValueSearchSub: Subscription;

  set searchString(value) {
    if (value !== this._searchString) {
      this._searchString = value;
      this.inputchange.emit(value || '');
    }
  }

  get searchString() {
    return this._searchString;
  }

  @Input()
  matches: T[] = [];
  matchesObs: Observable<T[]>;
  active: any;
  index: number;
  selectedValue: string = null;

  constructor(
    public element: ElementRef,
    public componentFactoryResolver: ComponentFactoryResolver,
    public searchService: SearchService<any>,
    @Optional() public keyboardService: KeyboardService
  ) {
    super(element);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.searchService.mapResponse = this.mapResponse;
  }

  ngOnInit() {
    this.matchesLimit = this.matchesLimit || 200;
    this.minLength = this.minLength || 1;

    this.action.subscribe((event) => {
      if (
        !this.matchingCmp.instance.preventAutoCompleteActions ||
        (event instanceof KeyboardEvent && event.key === 'Escape')
      ) {
        if (event instanceof KeyboardEvent) {
          switch (event.key) {
            case 'ArrowDown':
              this.handleArrowDown(event);
              break;
            case 'ArrowUp':
              this.handleArrowUp(event);
              break;
            case 'Enter':
              this.handleEnter(event);
              break;
            case 'Escape':
              this.handleEscape(event);
              break;
            case 'Tab':
              this.handleTab(event);
              break;
          }
        } else if (event instanceof ClipboardEvent) {
          this.handlePaste(event);
        } else if (
          event instanceof FocusEvent &&
          event.type === 'focusout' &&
          (event.target as HTMLElement)?.classList.contains('autocomplete-input')
        ) {
          this.onBlurMethod();
        } else if (event.type === 'input') {
          if (!this.clInput.value) {
            this._onChange(null);
          }
        }
      }
    });

    this.matchingCmp = this.setupMatchesComponent();

    if (this.matchingCmp) {
      this.onSelectSub = this.matchingCmp.instance.onSelect.subscribe((resultArr) => {
        this.selectMatch(resultArr[0], resultArr[1]);
      });
    }

    this.updateMatchesComponent();
  }

  ngOnDestroy() {
    this.onSelectSub.unsubscribe();
  }

  setupMatchesComponent(): ComponentRef<MatchesComponent> {
    let factory;

    this.matchesCmp.clear();

    factory = this.componentFactoryResolver.resolveComponentFactory(this.matchesComponent);

    return this.matchesCmp.createComponent(factory);
  }

  handlePaste($event) {
    if (!this.allowMultipleLines) {
      let text = $event.clipboardData.getData('Text');
      $event.preventDefault();
      text = text.replace(/\n/g, ' ');
      $event.clipboardData.clearData('Text');
      document.execCommand('insertText', false, text);
    }
  }

  handleArrowDown($event) {
    $event.preventDefault();

    if (this.matches && this.matches.length) {
      $event.stopPropagation();
      this.nextMatch();
    } else {
      this.onBlurMethod($event);
    }
  }

  handleArrowUp($event) {
    $event.preventDefault();
    if (this.matches && this.matches.length) {
      $event.stopPropagation();
      this.previousMatch();
    }
  }

  handleEnter($event) {
    if (!this.allowMultipleLines || !$event.shiftKey) {
      $event.preventDefault();
    }

    if (!$event['shiftKey']) {
      if (
        (this.matchesObs || this.allowFreeText || this.allowMultipleEntry) &&
        !this.showAll &&
        this.searchString !== ''
      ) {
        if ((this.matches && this.matches.length) || this.allowFreeText || this.allowMultipleEntry) {
          this.selectMatch();
        } else {
          this.processEnterAfterSearch = true;
        }

        this.showAll = false;
      } else if (this.searchString === '' && !this.matchesObs && !this.useSearchService) {
        // Show all
        this.showAll = true;
        this.findMatches();
      } else if (this.searchString === this.selectedValue && !this.showAll && !$event.altKey) {
        if (!$event.shiftKey && (this.matches.length || this.allowFreeText || this.allowMultipleEntry)) {
          this.selectMatch();
        }
      } else if (this.showAll) {
        // Select current active from show all
        this.selectMatch();
      } else {
        this.showAll = false;
        this._onChange();
      }
    }
  }

  handleEscape($event) {
    if (this.matches.length > 0) {
      $event?.preventDefault();
      $event?.stopImmediatePropagation();
    }

    this.resetAutocomplete();
  }

  handleTab($event) {
    this.onBlurMethod();
  }

  onInput($event) {
    this.selectedValue = null;
    if (!$event.altKey) {
      const value = this.clInput.input.nativeElement.value;
      if (value.length) {
        this.searchString = value;
        this.showAll = false;
        this.processEnterAfterSearch = false;
        this.findMatches();
      } else {
        this.resetAutocomplete();
      }
    }
  }

  findMatches() {
    const { searchString, data, showAll, useSearchService, searchUrl, matchAttributes } = this,
      items = [];
    let _matches = [];

    this.matches = [];

    if (searchString.length >= this.minLength) {
      if (useSearchService && typeof useSearchService === 'function' ? useSearchService() : useSearchService) {
        this.matchesObs = this.searchService.search({
          params: this.params,
          interpolate: this.interpolate,
          value: this.searchString,
          url: searchUrl,
        });
      } else if (matchAttributes) {
        const normalizedQueryString = StringUtil.toLower(this.searchString);
        matchAttributes.forEach((attribute, index) => {
          if (data && data.length) {
            data.forEach((item, itemIndex) => {
              // Match on each attribute
              const _attributeValue = StringUtil.toLower(item[attribute]);
              // Match unbroken substring
              let matchIndex = 0;
              if (!showAll) {
                // Matches entire search string to attribute
                matchIndex = _attributeValue.indexOf(normalizedQueryString);
              }
              // Compile non-fuzzy matches
              if (matchIndex > -1 && items.indexOf(item) < 0) {
                items.push(item);
                _matches.push({
                  rank: matchIndex - (1 - index),
                  matchLength: _attributeValue.length,
                  item,
                });
              }

              // Prepare substring match target for fuzzy match
              if (!item['targetDigest']) {
                item['targetDigest'] = '';
              }
              if (_attributeValue && item['targetDigest'].indexOf(_attributeValue) < 0) {
                item['targetDigest'] += ' ' + _attributeValue;
              }
            });
          }
        });

        // Find fuzzy matches
        let queryComponents = [];
        if (this.useFuzzyMatch && normalizedQueryString.indexOf(' ')) {
          queryComponents = normalizedQueryString.split(' ');

          const matchTargets = [];
          data.forEach((item) => {
            let matchConfirmed: boolean = true;
            queryComponents.forEach((substring) => {
              if (item['targetDigest'].indexOf(substring) < 0) {
                matchConfirmed = false;
                return;
              }
            });

            if (matchConfirmed) {
              matchTargets.push(item);
            } else {
              matchTargets.forEach((matchTarget, index) => {
                if (matchTarget.code === item['code']) {
                  matchTargets.splice(index, 1);
                }
              });
            }
          });

          _matches = [];
          matchTargets.forEach((item) => {
            // Set rank to sort 100% matches to higher placement in matches
            let itemRank: number = 0;
            item['targetDigest'].split(' ').forEach((part) => {
              queryComponents.forEach((component) => {
                if (component === part) {
                  itemRank = -100;
                }
              });
            });
            _matches.push({
              rank: itemRank,
              matchLength: 1,
              item,
            });
          });
        }

        this.matchesObs = observableFrom([
          _.orderBy(_matches, ['rank', 'matchLength'], ['asc', 'asc']).map(({ item }) => item),
        ]);
      }
    } else if (showAll) {
      this.matchesObs = observableFrom([this.data] as any[]);
    } else {
      this.matchesObs = null;
    }

    const matchesObs = this.matchesObs;

    if (this.newValueSearchSub) {
      this.newValueSearchSub.unsubscribe();
    }

    if (matchesObs) {
      this.loading = true;
      this.active = '';
      this._onChange('');

      this.newValueSearchSub = matchesObs
        .pipe(
          tap((res) => {
            if (this.matchesObs !== matchesObs) {
              this.onSearchComplete.emit(res);
            }
          })
        )
        .subscribe((results) => {
          if (this.matchesObs !== matchesObs) {
            return;
          }

          this.loading = false;

          this.matches = results;

          if (results) {
            this.active = results[0];
          }

          this.hasNoMatches() ? this.noMatchesCallback.emit(true) : this.noMatchesCallback.emit(false);

          this._onChange(null);

          this.updateMatchesComponent();

          if (this.processEnterAfterSearch && this.matches && this.matches.length) {
            this.selectMatch();
          }

          this.processEnterAfterSearch = false;
        });
    } else {
      this.active = '';
      this._onChange(this.active);
    }

    this.updateMatchesComponent();
  }

  hasNoMatches(): boolean {
    let hasNoMatches = true;

    if (!this.showAll && this.matches) {
      hasNoMatches = this.matches.length < 1 && this.searchString !== '';
    }

    if (!this.matchesObs || this.searchString === this.missingInformationGlyph) {
      hasNoMatches = false;
    }

    return hasNoMatches;
  }

  selectMatch($event?, selected?, preventMoveFwd?: boolean) {
    if (selected) {
      this.active = selected;
    } else if (this.matches && this.matches.length > 0) {
      this.active = this.matches[0];
    }

    if (!this.active && (!this.allowFreeText || this.allowMultipleEntry)) {
      this.active = this.searchString;
    }
    this.index = 1;
    // Retain selection or wipe if presenting elsewhere

    if (this.active) {
      this.matches = [];
      this.matchesObs = null;
      this.noMatchesCallback.emit(false);
      this._onChange(this.active);

      if (this.retainSelection) {
        const retained = this.active[this.retainSelection];

        this.updateInput(retained);
        this.selectedValue = retained;
      } else {
        this.updateInput('');
        this.selectedValue = null;
      }

      this.onSelect.emit(this.active);
    }

    this.active = null;
    this.matches = [];
    this.matchesObs = null;
    this.showAll = false;

    /* Advance focus */
    if (this.keyboardService && this.clInput.input.nativeElement.value && !preventMoveFwd) {
      this.keyboardService.focusNext();
    }

    this.updateMatchesComponent();

    return false;
  }

  getInputClass() {
    return `autocomplete-input
        ${this.allowMultipleLines ? 'multi-line' : 'no-multi-line'}
        ${this.allowSelectionEdit ? 'selections-editable' : ''}`;
  }

  updateInput(value) {
    this.searchString = this.selectedValue = value;
    const inputElement = this.clInput.input.nativeElement;

    if (document.activeElement === inputElement) {
      DomUtil.persistCursor(inputElement, async () => {
        inputElement.value = this.searchString || '';
      });
    } else {
      inputElement.value = this.searchString || '';
    }
  }

  nextMatch() {
    if (this.matches.length) {
      const index = this.matches.indexOf(this.active);
      this.matches.splice(index, 1);
      this.matches.push(this.active);
      this.active = this.matches[0];

      this.updateMatchesComponent();
    }
  }

  previousMatch() {
    if (this.matches.length) {
      const index = this.matches.length - 1;
      this.active = this.matches[index];
      this.matches.splice(index, 1);
      this.matches.splice(0, 0, this.active);

      this.updateMatchesComponent();
    }
  }

  public resetAutocomplete(preventNoMatches?: boolean) {
    const hadValue = this.active || this.selectedValue || this.searchString;

    if (this.resetOnBlur) {
      this.selectedValue = null;
      this.active = '';
      this.searchString = '';
    }

    this.loading = false;
    this.matches = [];
    this.matchesObs = null;
    this.showAll = false;

    if (!preventNoMatches) {
      this.noMatchesCallback.emit(false);
    }

    if (hadValue && this.selectedValue) {
      this._onChange(this.selectedValue);
    }

    this.updateMatchesComponent();
  }

  public setFocus() {
    this.focusInput();
  }

  isMatchesVisible() {
    return !!this.matchingCmp.instance.matches.length;
  }

  public updateMatchesComponent() {
    if (this.matchingCmp) {
      this.matchingCmp.instance.matches = this.matches;
      this.matchingCmp.instance.active = this.active;
      this.matchingCmp.instance.showAll = this.showAll;
      this.matchingCmp.instance.action = this.action;
    }
  }

  /*
BOILERPLATE REQUIRED BY IMPLEMENTATION
     */

  selectContents() {
    if (document.activeElement === this.clInput.input.nativeElement) {
      DomUtil.selectContents(this.clInput.input.nativeElement);
    }
  }

  onBlurMethod($event?) {
    if (this._onTouched) {
      this._onTouched();
    }

    if (!this.selectedValue && !this.hasNoMatches()) {
      if (this.clInput.input.nativeElement.value) {
        this.resetAutocomplete();
      } else {
        this.resetAutocomplete(true);
      }
    }
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.clInput.value = '';
    }
  }

  focusInput() {
    this.clInput.input.nativeElement.focus();
  }

  blurInput() {
    this.clInput.input.nativeElement.blur();
  }

  setDisabledState(disabled) {
    this.disabled = this.clInput.input.nativeElement.disabled = disabled;
  }

  handleEvent(event) {
    this.action.next(event);
  }
}
