import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { LocaleService } from '@lims-common-ux/lux';

@Injectable({
  providedIn: 'root',
})
export class LabResolver {
  constructor(
    private appService: AppService,
    private localeService: LocaleService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.appService.loadingSpinnerVisible = true;
    this.localeService.setUrlLocale(route.queryParams['locale']);
    const labId = route.paramMap.get('lab');
    return this.appService.setupLab(labId);
  }
}
