import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LuxLayoutModule, ApplicationPipesModule, ModalContainerModule } from '@lims-common-ux/lux';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Field, FieldComponent, FieldLabelDirective } from './field/field.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ListComponent } from './list/list.component';
import { InputComponent } from './input/input.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { PillComponent } from './pill/pill.component';
import { EmailFaxComponent } from './email-fax/email-fax.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DirectivesModule } from '../directives/directives.module';
import { ButtonComponent } from './button/button.component';
import { AddressDisplayComponent } from './address-display/address-display.component';
import { MatchesComponent } from './matches/matches.component';
import { SamplesComponent } from './samples/samples.component';
import { SampleAttributesComponent } from './sample-attributes/sample-attributes.component';

import {
  CapitalizedTextDirective,
  MoveToNextOnMissingDirective,
  SelectSelfDirective,
  FieldWrapperComponent,
} from './field-wrapper/field-wrapper.component';
import { PatientSexFieldComponent } from './sex-field/patient-sex-field.component';
import { SearchSelectComponent } from './search-select/search-select.component';
import { DiscountFieldComponent } from './discount-field/discount-field.component';
import { AnimalTypeFieldComponent } from './animal-type-field/animal-type-field.component';
import { TestCodeFieldComponent } from './test-code-field/test-code-field.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RequisitionInfoFieldComponent } from './requisition-info-field/requisition-info-field.component';
import { ElectronicOrderFieldComponent } from '../../order-entry/electronic-order-field/electronic-order-field.component';
import { CustomerCodeFieldComponent } from '../../order-entry/customer-code-field/customer-code-field.component';
import { CustomerBarcodeFieldComponent } from '../../order-entry/customer-barcode-field/customer-barcode-field.component';
import { CancelReasonSelectorComponent } from './cancel-reason-selector/cancel-reason-selector.component';
import { SampleDrawTimeComponent } from './sample-draw-time/sample-draw-time.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    LuxLayoutModule,
    BrowserAnimationsModule,
    ModalContainerModule,
    ApplicationPipesModule,
  ],
  providers: [TranslatePipe],
  declarations: [
    FieldComponent,
    FieldLabelDirective,
    InputComponent,
    AutocompleteComponent,
    PillComponent,
    EmailFaxComponent,
    ListComponent,
    CheckboxComponent,
    ButtonComponent,
    AddressDisplayComponent,
    MatchesComponent,
    SamplesComponent,
    SampleAttributesComponent,
    Field,
    FieldWrapperComponent,
    SelectSelfDirective,
    MoveToNextOnMissingDirective,
    CapitalizedTextDirective,
    PatientSexFieldComponent,
    SearchSelectComponent,
    DiscountFieldComponent,
    AnimalTypeFieldComponent,
    TestCodeFieldComponent,
    RequisitionInfoFieldComponent,
    ElectronicOrderFieldComponent,
    CustomerCodeFieldComponent,
    CustomerBarcodeFieldComponent,
    CancelReasonSelectorComponent,
    SampleDrawTimeComponent,
  ],
  exports: [
    PillComponent,
    FieldComponent,
    FieldLabelDirective,
    InputComponent,
    AutocompleteComponent,
    EmailFaxComponent,
    ListComponent,
    CheckboxComponent,
    ButtonComponent,
    AddressDisplayComponent,
    FieldWrapperComponent,
    SelectSelfDirective,
    MoveToNextOnMissingDirective,
    CapitalizedTextDirective,
    PatientSexFieldComponent,
    DiscountFieldComponent,
    AnimalTypeFieldComponent,
    TestCodeFieldComponent,
    RequisitionInfoFieldComponent,
    ElectronicOrderFieldComponent,
    CustomerCodeFieldComponent,
    CustomerBarcodeFieldComponent,
    CancelReasonSelectorComponent,
    SamplesComponent,
    SampleAttributesComponent,
    SampleDrawTimeComponent,
  ],
})
export class ComponentsModule {}
