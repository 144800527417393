import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'cl-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent<T> {
  @Input()
  data: any[] = [];
  @Input()
  errors;
  @Input()
  displayValue = '';
  @Input()
  placeholder: string = '';
  @Input()
  displayCnt = 5; // starting from 0
  value: any;

  @Output()
  update = new EventEmitter();
  @Output()
  goToNext: EventEmitter<true> = new EventEmitter();

  @ViewChild('componentWrapper')
  componentWrapper: ElementRef;
  @ViewChild('input') input: ElementRef;

  displayedData: T[] = [];
  sortingAttributes = ['shortCode', 'label']; // attributes to check in filter
  // used track when search data is going to be waiting on a time based interaction (server-calls)
  searching = false;
  // used to map secondary calls that happen outside of initial value-driven search
  loading = false;
  // search-select component is widely extended by components with controlValueAccessor
  // to not mix the names naming it onValueChange
  onValueChange(item: unknown): void {
    throw new Error('You should implement this method');
  }

  // filter the given values in each input
  // filtered values are then assigned/displayed via displayedData
  @HostListener('input', ['$event.target'])
  onInput(field) {
    if (field.type === 'text') {
      this.handleSearch(field.value);
    }
  }

  // on enter we open the fill display list
  // unless a value is existing
  // if the list is already open we select the first item
  @HostListener('keydown.enter', ['$event'])
  onEnter(event) {
    this.handleEnter(event);
  }

  // Navigate top-to-bottom when options are displayed
  onArrowDown(event) {
    this.moveOptionToBottom(event);
  }

  // make sure tab will trigger field-to-field navigation
  onTabDown(event) {
    if (this.displayedData.length) {
      this.stopEvent(event);

      this.hideOptions();

      requestAnimationFrame(() => {
        this.input.nativeElement.focus();
        this.goToNext.next(true);
      });
    }
  }

  // Navigate bottom-to-top when options are displayed
  onArrowUp(event) {
    this.moveOptionToTop(event);
  }

  // Invalidate input if the user leaves the input after initiating search, but before selecting a value
  handleIncompleteInteraction() {}

  isInputActiveElement(): boolean {
    return document.activeElement === this.input.nativeElement;
  }

  selectItem(item: T) {
    this.onValueChange(item);
    this.hideOptions();
  }

  handleSearch(value: string) {
    if (!value) {
      this.resetInputValue();
      this.hideOptions();
      this.onValueChange(null);

      return;
    }

    this.displayValue = value;

    this.filter(value);

    this.onValueChange(null);
  }

  filter(value?: string) {
    const matches: any[] = [];
    const items = [];

    if (value) {
      value = value?.toLowerCase();

      this.data.forEach((item) => {
        let added = false;

        this.sortingAttributes.forEach((attribute, index) => {
          if (item[attribute] !== undefined) {
            const _attributeValue = item[attribute]?.toLowerCase();
            const matchIndex = _attributeValue.indexOf(value);

            if (matchIndex > -1 && items.indexOf(item) < 0 && !added) {
              // item has made it into the set, so we dont want to add it again if another attribute matches
              added = true;

              items.push({
                rank: matchIndex - (1 - index),
                matchLength: _attributeValue.length,
                item,
              });
            }
          }
        });
      });

      // put the shortest matches on top
      items.sort((m1, m2) => {
        if (m1.matchLength < m2.matchLength) {
          return -1;
        } else {
          return 1;
        }
      });

      // put partial matches into order from largest to smallest
      items.sort((m1, m2) => {
        if (m1.rank < m2.rank) {
          return -1;
        } else {
          return 1;
        }
      });

      items.forEach((match) => {
        matches.push(match.item);
      });
    } else {
      let count = 0;
      this.data.forEach((item) => {
        if (count <= this.displayCnt) {
          count++;
          items.push(item);
        }
      });
      items.forEach((match) => {
        matches.push(match);
      });
    }

    // Limit displayed result to configured displayCnt
    let count = 0;
    this.displayedData = matches.filter((item) => {
      if (count <= this.displayCnt) {
        count++;
        return true;
      }
      return false;
    });
  }

  // when the component loses focus we need to hide the displayed options
  handleFocusOut($event) {
    this.hideOptions();

    this.handleIncompleteInteraction();
  }

  hideOptions() {
    this.displayedData = [];
  }

  resetInputValue() {
    this.displayValue = '';

    if (this.input) {
      this.input.nativeElement.value = '';
    }

    this.searching = false;
  }

  handleClick($event, item: any) {
    this.stopEvent($event);

    this.selectItem(item);
  }

  handleEnter(event) {
    if (!this.displayedData.length && !this.displayValue) {
      this.displayedData = [...this.data];
    } else if (this.displayedData.length) {
      this.selectItem(this.displayedData[0]);
    }
  }

  stopEvent(event: MouseEvent | KeyboardEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  moveOptionToTop(event) {
    if (this.displayedData.length) {
      this.stopEvent(event);

      const itemToMoveToTop = this.displayedData.splice(this.displayedData.length - 1, 1)[0];

      this.displayedData.unshift(itemToMoveToTop);
    }
  }

  moveOptionToBottom(event) {
    if (this.displayedData.length) {
      this.stopEvent(event);

      const itemToMoveToBottom = this.displayedData.splice(0, 1)[0];

      this.displayedData.push(itemToMoveToBottom);
    }
  }
}
