import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { Field } from '../field/field.component';

let ids = 0;

@Component({
  selector: 'cl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: Field.Providers(CheckboxComponent),
})
export class CheckboxComponent extends InputComponent {
  @Input() toggle: 'alert' | true | false; // accepts alert
  @Input() text: string; // text is the text displayed adjacent to the checkbox
  @Input() set indeterminate(v) {
    if (this._indeterminate !== v) {
      this._indeterminate = v;
      this.input.nativeElement.indeterminate = v;
    }
  }
  @Output()
  updateParent = new EventEmitter<boolean>();
  isFocused: boolean = false; // used to manage focus class in toggle mode

  get indeterminate() {
    return this._indeterminate;
  }

  _value: boolean = false;

  _id: string = `--cl-checkbox-${ids++}`;
  _indeterminate: boolean = false;

  getClassName() {
    return this.toggle
      ? `cl-checkbox-input spot-toggle ${typeof this.toggle === 'string' ? `spot-toggle--${this.toggle}` : ''}`
      : 'cl-checkbox-input spot-form__checkbox-input';
  }

  @HostListener('keydown', ['$event'])
  onKeyDown($event) {
    if ($event.key === 'Enter') {
      this.toggleValue();
    }
  }

  toggleValue() {
    this.value = !this._value;
    requestAnimationFrame(() => {
      this.updateParent.emit(this.value);
    });
  }

  reset() {
    this.value = false;
  }

  doDefaultAction() {
    this.onFocus();

    super.doDefaultAction();
  }

  onFocus() {
    if (this.toggle === true) {
      this.isFocused = !this.isFocused;
    }
  }

  handleClick() {
    this.input.nativeElement.focus();
    this.toggleValue();
  }
}
