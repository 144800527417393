import { SAMPLE_ATTRIBUTE_TYPE, SampleAttribute } from './sample-attribute.model';
import { v4 as uuid } from 'uuid';
import { SampleTestAssociation } from '../../sample-association/sample-test-association';

export enum SAMPLE_STATUS {
  ACTIVE = 'ACTIVE',
  REMOVED = 'REMOVED',
}

export interface SampleFormValue {
  samples: Sample[];
  missingSample: boolean;
}

export interface DrawTime {
  time?: string;
  interval?: {
    time: number;
    unit: 'MINUTES' | 'HOURS';
  };
}

export enum SampleTypeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class Sample {
  saved = false;
  isRemoved = false;
  sampleTypeStatus: SampleTypeStatus;

  constructor(
    sample?: Sample,
    public barcode: string = sample ? sample.barcode : '',
    public code: string = sample ? sample.code : '',
    public label: string = sample ? sample.label : '',
    public name: string = sample ? `${sample.label}` : '',
    public shortCode: string = sample ? sample.shortCode : '',
    public sampleTypeCode: string = sample ? sample.sampleTypeCode : '',
    public id: string = sample && sample.id ? sample.id : '',
    public supportedAttributes: SAMPLE_ATTRIBUTE_TYPE[] = sample ? sample.supportedAttributes : [],
    public collectionMethod: SampleAttribute | string = sample?.collectionMethod ? sample.collectionMethod : '',
    public draw: SampleAttribute | string = sample?.draw ? sample.draw : '',
    public temperature: SampleAttribute | string = sample?.temperature ? sample.temperature : '',
    public associatedTests: string[] = sample?.associatedTests ? sample.associatedTests : null,
    public status: SAMPLE_STATUS = sample?.status ? sample.status : SAMPLE_STATUS.ACTIVE,
    public drawTime: DrawTime = sample?.drawTime ? sample.drawTime : null
  ) {
    if (sample?.sampleTypeStatus) {
      this.sampleTypeStatus = sample.sampleTypeStatus;
    }
  }

  // Pre-save order entry and accession edits
  static createFromReferenceData(sampleRef: SampleReferenceData): Sample {
    const sample: Sample = new Sample();

    sample.code = sampleRef.code;
    sample.label = sampleRef.label;
    sample.name = `${sample.label}`;
    sample.shortCode = sampleRef.shortCode;
    sample.supportedAttributes = sampleRef.supportedAttributes ? sampleRef.supportedAttributes : [];
    sample.collectionMethod = null;
    sample.draw = null;
    sample.temperature = null;
    if (sampleRef.status) {
      sample.sampleTypeStatus = sampleRef.status;
    }

    sample.setId();

    return sample;
  }

  // Translate accession resource sample data into order form
  static createFromAccessionedSample(orderedSample: AccessionedSample): Sample {
    const sample: Sample = new Sample();

    sample.barcode = orderedSample.barcode ? orderedSample.barcode : '';
    sample.code = orderedSample.sampleTypeCode;
    sample.label = orderedSample.label;
    sample.name = `${sample.label}`;
    sample.shortCode = orderedSample.shortCode ? orderedSample.shortCode : undefined;
    sample.supportedAttributes = orderedSample.supportedAttributes ? orderedSample.supportedAttributes : [];
    sample.id = orderedSample.id;
    sample.collectionMethod = orderedSample.collectionMethod ? orderedSample.collectionMethod : null;
    sample.draw = orderedSample.draw ? orderedSample.draw : null;
    sample.temperature = orderedSample.temperature ? orderedSample.temperature : null;
    sample.saved = true;
    sample.status = orderedSample.status ? orderedSample.status : SAMPLE_STATUS.ACTIVE;
    sample.drawTime = orderedSample.drawTime;
    if (orderedSample.sampleTypeStatus) {
      sample.sampleTypeStatus = orderedSample.sampleTypeStatus;
    }
    return sample;
  }

  static createOrderValidationSample(sample: Sample): OrderValidationSample {
    let orderValidationSample: OrderValidationSample = {} as OrderValidationSample;

    orderValidationSample.sampleTypeCode = sample.code;

    if (sample.collectionMethod && typeof sample.collectionMethod !== 'string') {
      orderValidationSample.collectionMethod = sample.collectionMethod.value;
    }

    if (sample.draw && typeof sample.draw !== 'string') {
      orderValidationSample.draw = sample.draw.value;
    }

    if (sample.temperature && typeof sample.temperature !== 'string') {
      orderValidationSample.temperature = sample.temperature.value;
    }

    return orderValidationSample;
  }

  static createSavableSample(
    formSample: Sample,
    isExistingAccession?: boolean,
    sampleTestAssociations?: SampleTestAssociation[]
  ) {
    const savableSample = {} as SavableSample;
    if (formSample.barcode) {
      savableSample.barcode = formSample.barcode;
    }
    savableSample.sampleTypeCode = formSample.code;
    if (isExistingAccession && formSample.saved) {
      savableSample.id = formSample.id;
    }
    if (sampleTestAssociations?.length > 0) {
      savableSample.associatedTests = [];
      sampleTestAssociations?.forEach((association: SampleTestAssociation) => {
        if (association.sampleId === formSample.id) {
          savableSample.associatedTests.push(association.contextualizedTestId);
        }
      });
    }

    if (formSample.collectionMethod && typeof formSample.collectionMethod !== 'string') {
      savableSample.collectionMethod = formSample.collectionMethod.value;
    }

    if (formSample.draw && typeof formSample.draw !== 'string') {
      savableSample.draw = formSample.draw.value;
    }

    if (formSample.temperature && typeof formSample.temperature !== 'string') {
      savableSample.temperature = formSample.temperature.value;
    }

    if (formSample.drawTime) {
      savableSample.drawTime = formSample.drawTime;
    }

    savableSample.status =
      formSample.status === 'REMOVED' || formSample.isRemoved ? SAMPLE_STATUS.REMOVED : SAMPLE_STATUS.ACTIVE;

    return savableSample;
  }

  setId?: () => void = () => {
    this.id = uuid();
  };
}

// Region specific reference data sample
export interface SampleReferenceData {
  code: string;
  label: string;
  shortCode?: string;
  supportedAttributes?: SAMPLE_ATTRIBUTE_TYPE[];
  status?: SampleTypeStatus;
}

// A saved accession sample
export interface AccessionedSample {
  barcode: string;
  id: string;
  label: string;
  sampleTypeCode: string;
  shortCode?: string;
  draw?: SampleAttribute;
  temperature?: SampleAttribute;
  collectionMethod?: SampleAttribute;
  supportedAttributes?: SAMPLE_ATTRIBUTE_TYPE[];
  status?: SAMPLE_STATUS;
  drawTime?: DrawTime;
  sampleTypeStatus?: SampleTypeStatus;
}

export interface OrderValidationSample {
  sampleTypeCode: string;
  collectionMethod: string;
  draw: string;
  temperature: string;
}

export interface SavableSample {
  barcode?: string;
  id?: string;
  sampleTypeCode: string;
  associatedTests?: string[];
  collectionMethod?: string;
  draw?: string;
  temperature?: string;
  status: SAMPLE_STATUS;
  drawTime?: DrawTime;
}
