import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ComponentsModule } from '../shared/components/components.module';
import { OrderEntryComponent } from './order-entry.component';
import { OperationalRegionComponent } from './operational-regions/operational-region.component';
import { USRegionComponent } from './operational-regions/us-region/us-region.component';
import { CentralEuropeRegionComponent } from './operational-regions/central-europe-region/central-europe-region.component';
import { UKRegionComponent } from './operational-regions/uk-region/uk-region.component';
import { CanadaRegionComponent } from './operational-regions/canada-region/canada-region.component';
import { AustraliaRegionComponent } from './operational-regions/australia-region/australia-region.component';
import { TestCodesModule } from './test-codes/test-codes.module';
import { AppRouterStrategy } from '../app.router.strategy';
import { PetOwnerBillingComponent } from './pet-owner-billing/pet-owner-billing.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { DirectivesModule } from '../shared/directives/directives.module';
import { PlacesService } from '../shared/services/places/places.service';
import { SearchService } from '../shared/services/search/search.service';

import { OrderEntryService } from './order-entry.service';
import { OrderValidationModule } from './order-validation/order-validation.module';
import { ElectronicOrderService } from './electronic-order/electronic-order.service';
import {
  LuxLayoutModule,
  ModalContainerModule,
  PendingRequestService,
  SnackbarModule,
  LabNotesComponent,
  FeaturesModule,
} from '@lims-common-ux/lux';
import { DateFormModule } from '../shared/modules/date-form/date-form.module';
import { CvaConnectorComponent } from '../shared/utils';
import { AgeDobModule } from '../shared/modules/age-dob/age-dob.module';
import { EscalateToSupportComponent } from './escalate-to-support/escalate-to-support.component';

@NgModule({
  declarations: [
    OrderEntryComponent,
    OperationalRegionComponent,
    USRegionComponent,
    CentralEuropeRegionComponent,
    UKRegionComponent,
    AustraliaRegionComponent,
    CanadaRegionComponent,
    PetOwnerBillingComponent,
    CustomerSearchComponent,
    CvaConnectorComponent,
    EscalateToSupportComponent,
  ],
  exports: [OrderEntryComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    DirectivesModule,
    LuxLayoutModule,
    TranslateModule.forChild({}),
    TestCodesModule,
    OrderValidationModule,
    DateFormModule,
    AgeDobModule,
    ModalContainerModule,
    LabNotesComponent,
    SnackbarModule,
    FeaturesModule,
  ],
  providers: [
    TranslatePipe,
    PlacesService,
    SearchService,
    OrderEntryService,
    ElectronicOrderService,
    PendingRequestService,
    {
      provide: RouteReuseStrategy,
      useClass: AppRouterStrategy,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class OrderEntryModule {
  static forRoot(): ModuleWithProviders<OrderEntryModule> {
    return {
      ngModule: OrderEntryModule,
      providers: [OrderEntryService],
    };
  }
}
