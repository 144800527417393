import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cl-autocomplete-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss'],
})
export class MatchesComponent {
  @Input()
  matches: any[] = [];
  @Input()
  private _active: any;
  public get active(): any {
    return this._active;
  }
  public set active(value: any) {
    this._active = value;
  }
  @Input()
  matchesLimit: number = 200;
  @Input()
  showAll: boolean = false;
  @Output()
  onSelect = new EventEmitter<any>();
  @Input()
  matchesId: string = '';
  @Input()
  preventAutoCompleteActions: boolean = false;

  selectMatch($event, match) {
    this.onSelect.emit([$event, match]);
  }

  isActive(value: any): boolean {
    return this.active === value;
  }
}
