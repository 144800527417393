import { Owner } from './owner.model';
import { Veterinarian } from './veterinarian.model';
import { CancelReasonCode } from './test.model';

export enum KnownTags {
  DELIVERY = 'delivery',
  ESCALATION = 'escalation',
}

export interface Tags {
  [key: string]: string;
}

export class Order {
  accessionNumber: string;
  customer: { customerId: string };
  originatingLabId: string;
  owner: Owner;
  patient: {
    name: string;
    sex?: string;
    microChip?: string;
    dateOfBirth?: string;
    age?: string;
    animalTypeCode?: string;
    customAnimalType?: string;
  };
  validationId: string;

  petOwnerBillingEnabled?: boolean;
  requisitionId?: string;
  collectionDate?: string;
  cancelReasonCode?: CancelReasonCode;
  testIds?: string[];
  pimsOrderId?: string;
  vet?: Veterinarian;
  discountCode?: string;
  copyToEmails?: string;
  copyToFaxNumbers?: string;
  customerMessages?: string[];
  customerBarcode?: string;
  samples?: any;
  tags?: Tags;
}
