<cl-field>
  <button #button [ngClass]="getClass()" [disabled]="disabled" [attr.name]="name">
    <svg *ngIf="icon && iconPosition === 'left'" [ngClass]="getIconClass()" aria-labelledby="title">
      <title></title>
      <use [attr.xlink:href]="'/assets/icons/spot_icons.svg#' + icon"></use>
    </svg>
    <span>
      <ng-content></ng-content>
    </span>
    <svg *ngIf="icon && iconPosition === 'right'" [ngClass]="getIconClass()" aria-labelledby="title">
      <title></title>
      <use [attr.xlink:href]="'/assets/icons/spot_icons.svg#' + icon"></use>
    </svg>
  </button>
</cl-field>
