import { Component, OnInit } from '@angular/core';
import { KeyboardAction, KeyboardService, ModalContainerService } from '@lims-common-ux/lux';
import { KeyboardHelpItem } from '@lims-common-ux/lux/lib/keyboard-help-category/keyboard-help-category.component';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'cl-help-modal',
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent implements OnInit {
  private openModal: KeyboardAction = {
    name: 'open-modal-help',
    matchCallback: ($evt: KeyboardEvent) => {
      if (this.modalService.openModal) {
        return $evt;
      }
      this.open();
    },
  };

  private closeModal: KeyboardAction = {
    name: 'modal-close',
    eventMatch: { key: 'Escape' },
    matchCallback: ($event: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($event);
      this.close();
    },
    removeOnMatch: true,
  };

  visible: boolean = false;

  keyboardHelpItemsNav: KeyboardHelpItem[] = [
    {
      description: 'HELP.NAV_NEXT',
      bindings: ['SHORTCUTS.ARROW_DOWN', 'SHORTCUTS.TAB'],
    },
    {
      description: 'HELP.NAV_PREV',
      bindings: ['SHORTCUTS.ARROW_UP', 'SHORTCUTS.SHIFT_TAB'],
    },
    {
      description: 'HELP.EXECUTE_ACTION',
      bindings: ['SHORTCUTS.ENTER'],
    },
    {
      description: 'HELP.RETURN_FOCUS',
      bindings: ['SHORTCUTS.ESCAPE'],
    },
    {
      description: 'HELP.DELETE_HIGHLIGHTED',
      bindings: ['SHORTCUTS.DELETE'],
    },
    {
      description: 'HELP.DELETE_HIGHLIGHTED',
      bindings: ['SHORTCUTS.BACKSPACE'],
    },
  ];
  keyboardHelpItemsForms: KeyboardHelpItem[] = [
    {
      description: 'HELP.INCREMENT_NUMBER',
      bindings: ['SHORTCUTS.ALT_ARROW_UP'],
    },
    {
      description: 'HELP.DECREMENT_NUMBER',
      bindings: ['SHORTCUTS.ALT_ARROW_DOWN'],
    },
    {
      description: 'HELP.TOGGLE_EDIT',
      bindings: ['SHORTCUTS.ALT_E'],
    },
    {
      description: 'HELP.HIGHLIGHT_RESET',
      bindings: ['SHORTCUTS.ALT_R'],
    },
    {
      description: 'HELP.FORM_SAVE',
      bindings: ['SHORTCUTS.ALT_S'],
    },
    {
      description: 'HELP.FORM_CONTROL_TOGGLE',
      bindings: ['SHORTCUTS.SPACE'],
    },
    {
      description: 'HELP.ADD_NEW_LINE',
      bindings: ['SHORTCUTS.SHIFT_ENTER'],
    },
  ];
  keyboardHelpItemsGeneral: KeyboardHelpItem[] = [
    {
      description: 'HELP.MENU',
      bindings: ['SHORTCUTS.F2'],
    },
    {
      description: 'LABELS.LAB_NOTES',
      bindings: ['SHORTCUTS.F9'],
    },
    {
      description: 'HELP.HELP',
      bindings: ['SHORTCUTS.ALT_H'],
    },
    {
      description: 'LABELS.ACCESSION_INFORMATION',
      bindings: ['SHORTCUTS.ALT_A'],
    },
    {
      description: 'LABELS.ADVANCED_ACCESSION_SEARCH',
      bindings: ['SHORTCUTS.ALT_F'],
    },
  ];

  constructor(
    private modalService: ModalContainerService,
    private keyboardService: KeyboardService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.keyboardService.addActions([this.openModal]);
  }

  open() {
    this.keyboardService.addActions([this.closeModal]);
    this.visible = true;
  }

  close() {
    if (this.visible) {
      this.visible = false;
      requestAnimationFrame(() => {
        this.appStateService.helpModalClosed();
      });
    }
  }
}
