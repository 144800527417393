import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Field } from '../field/field.component';
import { ButtonType } from '../../models/button.type';

@Component({
  selector: 'cl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: Field.Providers(ButtonComponent),
})
export class ButtonComponent extends Field {
  @Input() type: ButtonType = 'primary';
  @Input() large: boolean = false;
  @Input() icon;
  @Input() iconPosition: 'right' | 'left' = 'left';
  @Input() disabled = false;
  @Input() name = '';
  @ViewChild('button', { static: true }) button;

  constructor(element: ElementRef) {
    super(element);
  }

  getClass() {
    return `spot-button ${
      this.type === 'primary' ||
      this.type === 'secondary' ||
      this.type === 'positive' ||
      this.type === 'alert' ||
      this.type === 'icon-only'
        ? `spot-button--${this.type}`
        : this.type === 'modal-cancel'
          ? 'spot-modal__footer-cancel-button'
          : ''
    }
            ${(this.type === 'sales' && 'spot-button--sales') || ''} ${this.icon && 'spot-button--with-icon'}`;
  }

  getIconClass() {
    return this.type !== 'icon-only' ? `spot-icon spot-button__icon spot-button__icon--${this.iconPosition}` : '';
  }

  focusInput() {
    this.button.nativeElement.focus();
  }

  blurInput() {
    this.button.nativeElement.blur();
  }
}
