import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DisplayType, Test, TestType } from '../../shared/models/test.model';
import { OrderValidationService } from './order-validation.service';
import { Sample } from '../../shared/models/sample.model';
import { SampleAssociationComponent } from '../../sample-association/sample-association.component';
import { SamplesAssociationsService } from '../../sample-association/samples-associations.service';
import { AppStateService } from '../../app-state.service';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { KeyboardService } from '@lims-common-ux/lux';
import { DomUtil } from '../../shared/utils';
import { Subscription } from 'rxjs';
import { Accession } from '../../shared/models/accession.model';

@Component({
  selector: 'cl-order-validation-details',
  templateUrl: './order-validation-details.component.html',
  styleUrls: ['./order-validation-details.component.scss'],
})
export class OrderValidationDetailsComponent implements OnInit, OnDestroy {
  @Input()
  orderedItems: Test[] | string;
  @Input()
  associations = [];
  @Input()
  samples: Sample[] = [];

  notRemovedSamples: Sample[] = [];

  @ViewChild('sampleAssociations')
  sampleAssociationComponent: SampleAssociationComponent;

  @ViewChild('closeButton')
  closeButton!: ButtonComponent;

  @ViewChild('orderValidationDetailsLink')
  orderValidationDetailsLink!: ElementRef;

  @ViewChildren('toggle')
  blockToggles!: NodeList;

  _visible: boolean = false;
  _editing: boolean = false;
  _suspend: boolean = false;

  showSampleAssociations: boolean = false;
  windowWidth = window.innerWidth;
  _onTouched: Function = Function.prototype;
  declinedBlockRecommendations: string[] = [];

  existingAccessionSub: Subscription = new Subscription();
  existingAccession: Accession;
  isExistingAccession: boolean;

  manuallyBlockedByUser: Test[] = [];

  get missingInformationGlyph(): string {
    return this.appState.referenceData.mandatoryFieldMissingValue;
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event?: KeyboardEvent) {
    if (!event || !this._visible) {
      return;
    }
    let response: () => any;
    switch (event.key.toUpperCase()) {
      case 'ESCAPE':
        response = this.close;
        break;
      case 'E':
        if (event.altKey) {
          response = this.toggleEditing;
        }
        break;
      case 'ENTER':
        if (this.showSampleAssociations) {
          response = this.handleAssociationSave;
        }
        break;
      case 'S':
        if (event.altKey && this.showSampleAssociations) {
          response = this.handleAssociationSave;
        }
        break;
      default:
        break;
    }
    if (response) {
      this.stopEvent(event);
      response.bind(this)();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (!event) {
      return;
    }
    this.windowWidth = event.target.innerWidth;
  }

  get uncanceledTests() {
    if (Array.isArray(this.orderedItems)) {
      return this.orderedItems.filter((test) => !test.cancelReasonCode);
    } else {
      return [];
    }
  }

  constructor(
    private translate: TranslateService,
    private orderValidationService: OrderValidationService,
    private appState: AppStateService,
    private samplesAssociationsService: SamplesAssociationsService,
    private keyboardService: KeyboardService
  ) {}

  ngOnInit() {
    this.onKeydown = this.onKeydown.bind(this);
    this.declinedBlockRecommendations = [];

    // We need to initialize the samples associations service with existing sample associations here
    this.existingAccessionSub = this.appState.existingAccession$.subscribe((accession) => {
      this.existingAccession = accession;
      this.isExistingAccession = !!accession;
    });

    // Do not display canceled tests in edit accession mode
    if (typeof this.orderedItems !== 'string' && !!this.existingAccession) {
      this.orderedItems = this.orderedItems.filter((test: Test) => !test?.cancelReasonCode);
    }

    this.notRemovedSamples = this.samples.filter((sample) => !sample.isRemoved && sample.status !== 'REMOVED');

    this.orderValidationService.manuallyBlockedByUser$.subscribe((val) => {
      this.manuallyBlockedByUser = val;
      if (val?.length > 0 && this._visible) {
        setTimeout(() => {
          this.orderValidationService.refreshPresentation();
        }, 0);
      }
    });
  }

  ngOnDestroy() {
    this.existingAccessionSub?.unsubscribe();
  }

  showSamplesAssociationToggle() {
    return !!(this.samples && this.samples.length && this.samples[0]?.id);
  }

  handleAssociationSave() {
    this.close(true);
  }

  openSampleAssociations() {
    this.showSampleAssociations = true;
    this.open();
  }

  handleViewToggleKeyDown($event) {
    this.stopEvent($event);
    this.handleModalViewChange();
  }

  open(): OrderValidationDetailsComponent {
    if (!this._visible) {
      this._visible = true;

      requestAnimationFrame(() => {
        this.closeButton.focusInput();
      });
    }
    return this;
  }

  close(persistChanges?: boolean): OrderValidationDetailsComponent {
    if (this.samplesAssociationsService && !persistChanges) {
      this.samplesAssociationsService.reset();
    }

    if (this._visible) {
      if (this.showSampleAssociations && persistChanges) {
        this.samplesAssociationsService.setResetState(
          this.sampleAssociationComponent.samplesAssociationForm.getRawValue()
        );
      }

      this._visible = false;
      this.showSampleAssociations = false;
    }

    requestAnimationFrame(() => {
      this.orderValidationDetailsLink.nativeElement.focus();
    });

    return this;
  }

  onDoubleClick($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.toggleEditing();
  }

  toggleEditing() {
    this._editing = !this._editing;
  }

  toggleBlock(item, event, webClientOnly?) {
    if (!this._suspend) {
      this._suspend = true;
      item._itemLoading = !webClientOnly;

      const nextFocusableToggle = this.getNextFocusableToggle(item, event);

      requestAnimationFrame(() => {
        nextFocusableToggle?.focus();
        if (!webClientOnly) {
          this.orderValidationService.declineBlockRecommendation(item).subscribe(() => {
            this._suspend = false;
            item._itemLoading = false;
            this.orderValidationService.addBlockRecommendationDeclined(item.contextualizedTestId);
          });
        } else {
          this.manuallyBlockTest(item);
          this._suspend = false;
          item._itemLoading = false;
        }
      });
    }
  }

  getNextFocusableToggle(item, event): HTMLElement {
    let focusElement: HTMLElement;

    // When removing a value via keyboard, set focus to the next available editable item.
    // When removing a value via mouse, set focus to the component input.
    // Focus rules are tested in acceptance tests.
    const allToggles = Array.from(this.blockToggles);
    const lastToggle = allToggles[allToggles.length - 1];

    // @ts-ignore
    const lastToggleElement = lastToggle as ElementRef;

    let isLastToggle;

    isLastToggle =
      lastToggleElement &&
      lastToggleElement['element']?.nativeElement?.classList &&
      Array.from(lastToggleElement['element']?.nativeElement?.classList).filter(
        (listItem) => listItem === item.contextualizedTestId
      ).length > 0;

    if (!event || ((!event.pointerId || event.pointerId < 0) && event.type !== 'click')) {
      if (!isLastToggle) {
        this.keyboardService.focusNext();
      } else {
        focusElement = this.closeButton.button.nativeElement;
      }
    } else {
      const focusableParent = DomUtil.queryUp(event.target, 'input');
      if (event?.pointerId) {
        // @ts-ignore
        focusableParent?.focus();
      }
      let focusIndex = 0;
      // Declinable items are listed in the template depending on whether they are stand-alone or part of
      // a panel or profile. The template logic for presentation is complex. We can focus the next element
      // by finding the contextualizedTestId of the selected item, and advancing focus to the next available node.
      this.blockToggles.forEach((declineToggle, i) => {
        // @ts-ignore
        const classList = declineToggle?.nativeElement.classList;

        const hasContextualizedTestId = Array.from(classList).filter(
          (listItem) => listItem === item.contextualizedTestId
        );
        if (hasContextualizedTestId.length > 0) {
          focusIndex = i + 1;
        }
        if (i === focusIndex) {
          // @ts-ignore
          focusElement = declineToggle?.nativeElement;
        }

        if (isLastToggle) {
          focusElement = this.closeButton.button.nativeElement;
        }
      });
    }
    return focusElement;
  }

  // GCUP/SDMA/DIFF/CUP scenario order validation presentation for mixed Profile/Panel/Assay and Stand-alone tests must work up and down the hierarchy
  manuallyBlockTest(item: Test): void {
    item.displayType = DisplayType.BLOCKED;
    this.orderValidationService.addTestBlockedStatusChangedByUser(item);
  }

  displayDeclineToggle(item: Test): boolean {
    const isAssay = item.testType === TestType.ASSAY;
    return (
      isAssay &&
      !item['_itemLoading'] &&
      this._editing &&
      this.orderValidationService.hasBlockedRecommendation(item.contextualizedTestId) &&
      !this.orderValidationService.isBlockRecommendationDeclined(item.contextualizedTestId) &&
      !this.orderValidationService.isManuallyBlockedByUser(item.contextualizedTestId)
    );
  }

  displayManualBlockToggle(item: Test): boolean {
    const isAssay = item.testType === TestType.ASSAY;
    return (
      isAssay &&
      !item['_itemLoading'] &&
      this._editing &&
      (item.displayType === DisplayType.VALIDATE_MANUALLY || item.displayType === DisplayType.POSITIVE) &&
      !this.orderValidationService.hasBlockedRecommendation(item.contextualizedTestId) &&
      !this.orderValidationService.isBlockRecommendationDeclined(item.contextualizedTestId) &&
      !this.orderValidationService.isManuallyBlockedByUser(item.contextualizedTestId)
    );
  }

  handleModalViewChange() {
    this.showSampleAssociations = !this.showSampleAssociations;
  }

  isBlocked(item: Test): boolean {
    return this.orderValidationService.isBlocked(item.contextualizedTestId);
  }

  stopEvent($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  hasOVResponse(): boolean {
    return this.orderValidationService.orderValidationResponse !== null;
  }
}
