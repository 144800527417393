import { CustomerMessage } from './customer-message.model';
import { Discount } from './discount.model';
import { Sample } from './sample.model';
import { SampleAttributesByTypeRef } from './sample-attribute.model';
import { PatientSex } from './patient-sex.model';
import { CancelReason } from './test.model';
import { Link } from '@lims-common-ux/lux';

export class ReferenceData {
  constructor(
    referenceData: ReferenceData,
    public sexes: PatientSex[] = referenceData.sexes?.map((item) => item as PatientSex),
    public discounts: Discount[] = referenceData.discounts?.map((item) => new Discount(item)),
    public standardCustomerMessages: CustomerMessage[] = referenceData.standardCustomerMessages?.map(
      (item) => new CustomerMessage(item)
    ), // The reference data resource calls samples 'sampleTypes'
    public samples: Sample[] = referenceData['sampleTypes']?.map((item) => Sample.createFromReferenceData(item)),
    public mandatoryFieldMissingValue: string = referenceData?.mandatoryFieldMissingValue,
    public sampleAttributesByType: SampleAttributesByTypeRef = referenceData?.sampleAttributesByType,
    public cancelReasons: CancelReason[] = referenceData?.cancelReasons?.map((item) => new CancelReason(item)),
    public _links: {
      [key in
        | 'addLabNote'
        | 'accession'
        | 'accessionSearch'
        | 'accessionAdvancedSearch'
        | 'getLabNotes'
        | 'places'
        | 'info'
        | 'features'
        | 'testSearch'
        | 'animalTypeSearch'
        | 'sampleTestAssociation'
        | 'barcodeTranslation'
        | 'electronicOrders'
        | 'updateAccession'
        | 'auditAccessionEvents'
        | 'serviceCloudOrigin']: Link;
    } = referenceData._links
  ) {}
}
