import { Injectable } from '@angular/core';
import { DateTimeUtil, StringUtil } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class DateFormService {
  // Accepts a string or a number, but returns a number for input.control and formGroup validators
  getPaddedValue(enteredValue: string): string {
    return StringUtil.padDigits(enteredValue.toString());
  }

  getParsedDate(formattedDateString: string): {
    year: string;
    month: string;
    date: string;
  } {
    let parsedDate = null;

    if (DateTimeUtil.isValidDate(formattedDateString)) {
      parsedDate = { year: '', month: '', date: '' };

      const formattedDateSplit = formattedDateString.split('-');
      parsedDate.year = this.inferFullYear(formattedDateSplit[0]);
      parsedDate.month = this.getPaddedValue(formattedDateSplit[1]);
      parsedDate.date = this.getPaddedValue(formattedDateSplit[2]);
    }

    return parsedDate;
  }

  getFormattedDate(parsedDate: { year: string; month: string; date: string }): string {
    if (!parsedDate || !parsedDate.year || !parsedDate.month || !parsedDate.date) {
      return null;
    } else {
      const inferredYearString = this.inferFullYear(parsedDate.year);
      const paddedMonthString = this.getPaddedValue(parsedDate.month);
      const paddedDateString = this.getPaddedValue(parsedDate.date);

      const fullDateString = inferredYearString + '-' + paddedMonthString + '-' + paddedDateString;

      if (DateTimeUtil.isValidDate(fullDateString)) {
        const isoDateString = DateTimeUtil.getISODateString(fullDateString);
        return DateTimeUtil.format(isoDateString, 'yyyy-MM-dd');
      }
    }
  }

  // Handle 2-digit year entry and update presentation and model with inferred 4-digit year
  inferFullYear(parsedDateYear: string, force?: boolean) {
    return DateTimeUtil.inferFullYear(parsedDateYear) + '';
  }
}
