import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  BehaviorSubject,
  tap,
  debounceTime,
  switchMap,
  distinctUntilChanged,
  share,
  map,
} from 'rxjs';
import { UrlUtil } from '../../utils';
import { OrderEntryService } from '../../../order-entry/order-entry.service';
import { PREVENTS_SAVE_REQUEST } from '../../../app.component';

export interface SearchParams {
  interpolate?: () => { [key: string]: any };
  params?: () => { [key: string]: any };
  url: string;
  value: string;
}

@Injectable()
export class SearchService<T> {
  static DEBOUNCE = 150;
  public context;

  service = new BehaviorSubject<SearchParams>(null).pipe(
    distinctUntilChanged(),
    debounceTime(SearchService.DEBOUNCE),
    switchMap((config) => this.requestValues(config)),
    share()
  ) as Subject<any>;

  constructor(
    public http: HttpClient,
    public orderEntryService: OrderEntryService
  ) {}

  mapResponse: (any) => T[];

  private requestValues({ interpolate, params, value, url }: SearchParams): Observable<Object> {
    const paramsValue = params ? params() || {} : {};
    const headers: HttpHeaders = this.orderEntryService.addTraceHeader(new HttpHeaders());

    let interpolateValue = interpolate ? interpolate() || {} : {};
    interpolateValue = { ...interpolateValue, q: value };
    url = UrlUtil.interpolateUrl(url, interpolateValue);
    this.context = new HttpContext().set(PREVENTS_SAVE_REQUEST, true);

    return this.http.get(url, { params: paramsValue, headers: headers, context: this.context }).pipe(
      map(this.mapResponse || ((v) => v)),
      tap(() => {
        this.context = null;
      })
    );
  }

  search({ interpolate, params, url, value }: SearchParams): Observable<T> {
    this.service.next({
      interpolate,
      params,
      value,
      url,
    });

    return this.service;
  }
}
