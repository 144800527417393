import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ViewChild, Component, Input, ElementRef } from '@angular/core';
import { StringUtil } from '../../utils';

interface EmailFaxFieldValue {
  emails: string[];
  faxNumbers: string[];
}

@Component({
  selector: 'cl-email-fax',
  templateUrl: './email-fax.component.html',
  styleUrls: ['./email-fax.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmailFaxComponent,
      multi: true,
    },
  ],
  animations: [
    trigger('transformInOut', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      state('out', style({ transform: 'translateY(0)', opacity: 0 })),
      transition('out => in', [
        style({
          transform: 'translateY(-100%)',
          opacity: 0,
        }),
        group([
          animate(
            '.5s 0.1s ease-in-out',
            style({
              transform: 'translateY(0)',
              opacity: 1,
            })
          ),
        ]),
      ]),
      transition('in => out', [
        group([
          animate(
            '.5s ease-in-out',
            style({
              transform: 'translateY(100%)',
              opacity: 0,
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class EmailFaxComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() maxValues: number = 10;
  @Input()
  errors;
  @ViewChild('input', { static: true }) input: ElementRef;
  hideErrors = false;
  value: EmailFaxFieldValue;
  displayedValues: string[] = [];
  showInvalidHint = false;
  addingValue: 'email' | 'fax' | null = null;
  onChange: any = () => {};

  onTouched: any = () => {};

  addEmail(email: string) {
    this.value.emails.push(email);
    this.displayedValues.push(email);
    this.clearInput();
    this.addingValue = null;
  }

  isEmail(possibleEmail: string) {
    return StringUtil.isEmail(possibleEmail);
  }

  addFaxNumber(faxNumber: string) {
    this.value.faxNumbers.push(faxNumber);
    this.displayedValues.push(faxNumber);
    this.clearInput();
    this.addingValue = null;
  }

  isFax(possibleFaxNumber: string) {
    return StringUtil.isFax(possibleFaxNumber);
  }

  handleEnter($event) {
    this.add($event.target.value);
  }

  // we add values on blur and enter
  add(value: string) {
    this.hideErrors = false;

    if (value && this.displayedValues.length < this.maxValues) {
      this.onValueChange(value);
    } else {
      this.addingValue = null;
      this.clearInput();
      this.onChange(this.value);
    }
  }

  clearInput() {
    this.input.nativeElement.value = '';
  }

  handleBlur($event) {
    this.add($event.target.value);
  }

  handleInput($event) {
    const value = $event.target.value;

    if (this.isEmail(value)) {
      this.addingValue = 'email';
    } else if (this.isFax(value)) {
      this.addingValue = 'fax';
    } else {
      this.addingValue = null;
    }

    // we only allow an error state when
    // trying to add() a poorly formed email/fax
    // any subsequent value change should remove the error state
    this.hideErrors = true;

    this.onChange(this.value);
  }

  removeValue(value: string) {
    let i = 0;

    for (i; i < this.displayedValues.length; i += 1) {
      const existingValue = this.displayedValues[i];
      if (existingValue === value) {
        this.displayedValues.splice(i, 1);
        break;
      }
    }

    i = 0;

    for (i; i < this.value.emails.length; i += 1) {
      const existingValue = this.value.emails[i];
      if (existingValue === value) {
        this.value.emails.splice(i, 1);
        break;
      }
    }

    i = 0;

    for (i; i < this.value.faxNumbers.length; i += 1) {
      const existingValue = this.value.faxNumbers[i];
      if (existingValue === value) {
        this.value.faxNumbers.splice(i, 1);
        break;
      }
    }

    this.onChange(this.value);
  }

  writeValue(value: string | null | EmailFaxFieldValue) {
    this.onValueChange(value, false);
  }

  onValueChange(value: string | null | EmailFaxFieldValue, emitChange = true) {
    if (typeof value !== 'string') {
      this.value = {
        emails: [],
        faxNumbers: [],
      };

      this.displayedValues = [];

      this.clearInput();
    } else {
      if (this.isEmail(value)) {
        this.addEmail(value);
      } else if (this.isFax(value)) {
        this.addFaxNumber(value);
      }
    }

    if (emitChange) {
      setTimeout(() => {
        this.onChange(this.value);
      }, 0);
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }
}
