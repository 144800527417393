import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, takeLast, Observable, of } from 'rxjs';
import { UrlUtil } from '../../shared/utils';
import { OrderEntryService } from '../order-entry.service';
import { ElectronicOrder, ElectronicOrderResponse } from '../../shared/models/electronic-order.model';
import { AppStateService } from '../../app-state.service';
import { LabsService } from '@lims-common-ux/lux';

export interface ErrorInterface {
  hasServiceError: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ElectronicOrderService {
  animalTypeFindUrl: string;

  constructor(
    private http: HttpClient,
    private appState: AppStateService,
    private labService: LabsService,
    // visible for testing
    public orderEntryService: OrderEntryService
  ) {}

  findAnimalTypeByCode(animalTypeCode: string): any {
    const headers: HttpHeaders = this.orderEntryService.addTraceHeader(new HttpHeaders());

    const url = UrlUtil.interpolateUrl(`${this.animalTypeFindUrl}`, {
      animalTypeCode: animalTypeCode,
      operationalRegionCode: this.labService.currentLab.operationalRegionCode,
    });

    return this.http.get(url, { headers: headers }).pipe(
      takeLast(1),
      map((data: any) => {
        return data;
      }),
      catchError(() =>
        of({
          data: null,
          hasServiceError: true,
        })
      )
    );
  }

  findByReqId(reqId: string): Observable<ElectronicOrder[]> {
    const headers: HttpHeaders = this.orderEntryService.addTraceHeader(new HttpHeaders());
    const url = UrlUtil.interpolateUrl(this.appState.referenceData._links.electronicOrders.href, {
      requisitionId: reqId,
      operationalRegionCode: this.labService.currentLab.operationalRegionCode,
    });

    return this.http.get<ElectronicOrderResponse>(url, { headers }).pipe(
      map((data: ElectronicOrderResponse) => {
        if (!data._embedded?.electronicOrders.length) {
          this.animalTypeFindUrl = null;
          return [];
        } else {
          this.animalTypeFindUrl = data._embedded.electronicOrders[0]._links.animalTypeFind.href;
          return data._embedded.electronicOrders;
        }
      })
    );
  }
}
