<cl-field>
  <div (click)="handleClick()">
    <label
      class="spot-form__checkbox spot-form__checkbox-indeterminate cl-checkbox-label-wrapper spot-form__checkbox--small">
      <input
        #input
        [ngClass]="getClassName()"
        type="checkbox"
        (blur)="doDefaultAction()"
        (focus)="onFocus()"
        (keydown.space)="toggleValue()"
        (change)="toggleValue()"
        [disabled]="disabled"
        [checked]="value"
        [id]="_id" />
      <label
        *ngIf="toggle"
        tabindex="-1"
        class="spot-toggle-label"
        [class.cl-checkbox-toggle-focus]="isFocused"
        [for]="_id"
        >{{ text }}</label
      >
      <span *ngIf="!toggle" class="spot-form__checkbox-inner">
        <span class="spot-form__checkbox-visual">
          <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/icons/spot_icons.svg#checkmark"></use>
          </svg>
          <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/icons/spot_icons.svg#checkbox-indeterminate"></use>
          </svg>
        </span>
        <span class="spot-form__checkbox-label">{{ text }}</span>
      </span>
    </label>
  </div>
</cl-field>
