<cl-field [label]="label">
  <input
    #input
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item"
    [value]="value"
    (keydown)="onKeyDown($event)"
    (input)="onInput($event)"
    (focus)="onFocus($event)"
    (ngModelChange)="onNgModelChange($event)"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [type]="inputType"
    [ngClass]="className"
    [disabled]="disabled" />
  <span *ngIf="functionIndicator || loading" class="spot-form__select-open-indicator">
    <svg *ngIf="loading" class="spot-icon spot-loading-spinner" aria-labelledby="title">
      <title></title>
      <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
    </svg>
    <svg
      *ngIf="!loading && functionIndicator === 'searcher'"
      class="spot-icon spot-form__select-open-icon"
      aria-labelledby="title">
      <title></title>
      <use xlink:href="/assets/icons/spot_icons.svg#search"></use>
    </svg>
    <svg
      *ngIf="!loading && functionIndicator === 'adder'"
      class="spot-icon spot-form__select-open-icon"
      aria-labelledby="title">
      <title></title>
      <use xlink:href="/assets/icons/spot_icons.svg#add"></use>
    </svg>
  </span>
</cl-field>
