import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { KeyboardService } from '@lims-common-ux/lux';

@Directive({ selector: 'input[clCapitalizedText]' })
export class CapitalizedTextDirective {
  @HostListener('input', ['$event.target'])
  onInput(field) {
    if (field.value) {
      field.value = field.value.toUpperCase();
    }
  }
}

@Directive({ selector: 'input[clMoveNextOnMissing]' })
export class MoveToNextOnMissingDirective {
  @Input()
  missingInformationGlyph = '?';

  constructor(private keyboardService: KeyboardService) {}

  @HostListener('input', ['$event.target'])
  onInput(field) {
    if (field.value === this.missingInformationGlyph) {
      this.keyboardService.focusNext();
    }
  }
}

@Directive({ selector: 'input[clSelectSelf]' })
export class SelectSelfDirective {
  @HostListener('click', ['$event.target'])
  onClick(field) {
    field.select();
  }

  @HostListener('focus', ['$event.target'])
  onFocus(field) {
    field.select();
  }
}

interface FieldError {
  key: string; // error id
  value: string; // translation key
  args: any; // arguments for translation key
}

@Component({
  selector: 'cl-field-wrapper',
  templateUrl: './field-wrapper.component.html',
  styleUrls: ['./field-wrapper.component.scss'],
})
export class FieldWrapperComponent implements OnChanges {
  @Input()
  label: string;
  @Input()
  disabled = false;
  @Input()
  required = false;
  @Input()
  microText: string[] = [];
  @Input()
  loading = false;
  @Input()
  searcher = false; // toggle to show magnifying glass in the field
  @Input()
  adder = false; // toggle to show addition icon in the field
  @Input()
  errors;

  @ViewChild('componentWrapper')
  componentWrapper: ElementRef;

  @Output() lostFocus: EventEmitter<Element> = new EventEmitter();

  currentErrors: FieldError[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.errors) {
      return;
    }
    if (changes.errors.currentValue) {
      const newErrors: FieldError[] = [];
      let prop;

      for (prop in changes.errors.currentValue) {
        if (changes.errors.currentValue[prop].message) {
          let val;

          if (changes.errors.currentValue[prop].message.text || changes.errors.currentValue[prop].message.text === '') {
            val = changes.errors.currentValue[prop].message.text;
          } else if (changes.errors.currentValue[prop].message) {
            val = changes.errors.currentValue[prop].message;
          }

          if (val) {
            const newError = {
              key: prop,
              value: val,
              args: null,
            };

            if (changes.errors.currentValue[prop].message.args) {
              newError.args = changes.errors.currentValue[prop].message.args;
            }

            newErrors.push(newError);
          }
        }
      }

      this.currentErrors = newErrors;
    } else if (!changes.errors.currentValue && changes.errors.previousValue) {
      this.currentErrors = [];
    }
  }

  handleFocusOut($event) {
    setTimeout(() => {
      if (
        document.activeElement === document.body ||
        !this.componentWrapper.nativeElement.contains(document.activeElement)
      ) {
        this.lostFocus.emit(document.activeElement);
      }
    }, 0);
  }
}
