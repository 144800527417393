import { Component, forwardRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DateFormService } from './date-form.service';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { DateTimeUtil, StringUtil } from '../../utils';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateSegmentInputComponent } from './date-segment-input/date-segment-input.component';
import { LocaleService } from '@lims-common-ux/lux';
import { AppService } from '../../../app.service';

@Component({
  selector: 'cl-date-form',
  templateUrl: './date-form.component.html',
  styleUrls: ['./date-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateFormComponent),
      multi: true,
    },
  ],
})
export class DateFormComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  // For collection date use default of 1 year in the past for boundaryYears.
  // For age/DOB this input allows configuration for dates in the past (e.g. the 300 year old tortoise).
  @Input()
  boundaryYears: number = 1;

  @ViewChildren('segmentInput')
  segmentInputs: QueryList<DateSegmentInputComponent>;

  private val: string;

  dateForm: FormGroup;
  onLangChangeSub: Subscription;
  dateFormSub: Subscription;

  // Order, placeholder, and separator values are updated via i18n files onLanguageChange()
  CONFIG = {
    ORDER: ['month', 'date', 'year'],
    SEPARATOR: '/',
    PLACEHOLDERS: { month: 'MM', date: 'DD', year: 'YYYY' },
    DATE: { MAX: '31', MIN: '1' },
    MONTH: { MAX: '12', MIN: '1' },
    YEAR: {
      MAX: DateTimeUtil.getCurrentYearString(),
      MIN: DateTimeUtil.minusYears(this.boundaryYears).toString(),
    },
  };

  i18n = {
    DATE: 'DATE',
  };

  order: string[] = this.CONFIG.ORDER;
  placeholders: any = this.CONFIG.PLACEHOLDERS;
  separator: string = this.CONFIG.SEPARATOR;

  constructor(
    public dateFormService: DateFormService,
    public translate: TranslateService,
    private localeService: LocaleService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.dateForm = new FormGroup(
      {
        date: new FormControl('', [
          Validators.required,
          Validators.max(parseInt(this.CONFIG.DATE.MAX, 0)),
          Validators.min(parseInt(this.CONFIG.DATE.MIN, 0)),
        ]),
        month: new FormControl('', [
          Validators.required,
          Validators.max(parseInt(this.CONFIG.MONTH.MAX, 0)),
          Validators.min(parseInt(this.CONFIG.MONTH.MIN, 0)),
        ]),
        year: new FormControl('', [
          Validators.required,
          Validators.max(parseInt(this.CONFIG.YEAR.MAX, 0)),
          Validators.min(parseInt(this.CONFIG.YEAR.MIN, 0)),
        ]),
      },
      { updateOn: 'change' }
    );

    this.dateFormSub = this.dateForm.valueChanges.subscribe((val) => {
      this.onValueChange(val);
    });

    this.onLangChangeSub = this.translate.onLangChange.subscribe((v) => this.onLanguageChange());

    this.onLanguageChange();
  }

  ngOnDestroy() {
    if (this.dateFormSub) {
      this.dateFormSub.unsubscribe();
    }

    if (this.onLangChangeSub) {
      this.onLangChangeSub.unsubscribe();
    }
  }

  handlePasteEvent($event) {
    // Update date form value if clipboardData is in yyyy-mm-dd format
    if (DateTimeUtil.isValidDate($event)) {
      this.dateForm.controls.dobEntryForm.setValue($event);
    } else {
      if (StringUtil.hasSpecial($event)) {
        this.handleDateWithSpecialCharacters($event);
      }
    }

    setTimeout(() => {
      this.segmentInputs.last.handleFocus();
    }, 0);
  }

  focus() {
    requestAnimationFrame(() => {
      this.segmentInputs.first.input.nativeElement.focus();
    });
  }

  // Update date form with date in mm-dd-yyyy, dd-mm-yyyy, m-d-yyyy, OR d-m-yyyy format.
  // Two digit years are handled by year inference.
  handleDateWithSpecialCharacters(dateWithSpecialCharacters: string) {
    const dateArray = dateWithSpecialCharacters.split('');

    const specialCharIndices = [];

    dateArray.forEach((char, index) => {
      if (StringUtil.hasSpecial(char)) {
        specialCharIndices.push(index);
      }
    });

    // Set values according to order. The localizedDateString format is assumed to be appropriate for the lab locale.
    let first = dateWithSpecialCharacters.substring(0, specialCharIndices[0]);
    let second = dateWithSpecialCharacters.substring(specialCharIndices[0] + 1, specialCharIndices[1]);
    let third = dateWithSpecialCharacters.substring(specialCharIndices[1] + 1, specialCharIndices[1] + 5);

    first = StringUtil.padDigits(first);
    second = StringUtil.padDigits(second);
    third = StringUtil.padDigits(third);

    this.dateForm.controls[this.order[0]].patchValue(first);
    this.dateForm.controls[this.order[1]].patchValue(second);
    this.dateForm.controls[this.order[2]].patchValue(third);
  }

  onLanguageChange() {
    const DATE = this.i18n.DATE;

    const localizedTranslations = this.localeService.selectedLocaleDateTimeTranslations;

    let value = this.translate.instant(DATE);
    value = !value || value === DATE ? { ...this.CONFIG } : { ...this.CONFIG, ...value };
    this.separator = localizedTranslations ? localizedTranslations.SEPARATOR : value.SEPARATOR;
    this.order = localizedTranslations ? localizedTranslations.ORDER : value.ORDER;
    this.placeholders = value.PLACEHOLDERS;
  }

  hasIncompleteDate() {
    if (!this.val && this.dateForm) {
      return !!(
        this.dateForm.controls.date.value ||
        this.dateForm.controls.month.value ||
        this.dateForm.controls.year.value
      );
    } else {
      return false;
    }
  }

  get value() {
    return this.val;
  }

  set value(val: string) {
    this.val = val;
    this.onChange(this.val);
    this.onTouched();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  onValueChange(val: { year: string; month: string; date: string } | string, emitChange = true) {
    if (!val) {
      // On reset
      this.dateForm.controls.year.setValue('', { emitEvent: false });
      this.dateForm.controls.month.setValue('', { emitEvent: false });
      this.dateForm.controls.date.setValue('', { emitEvent: false });
    } else if (typeof val === 'string') {
      // value from electronic order
      if (emitChange) {
        this.value = val;
      } else {
        this.val = val;
      }
      const parsedDate = this.dateFormService.getParsedDate(val);
      this.dateForm.setValue(parsedDate, { emitEvent: true });
    } else {
      // default
      const formattedDateString = this.dateFormService.getFormattedDate(val);
      if (emitChange) {
        this.value = formattedDateString;
      } else {
        this.val = formattedDateString;
      }
    }
  }

  writeValue(val: { year: string; month: string; date: string } | string): void {
    this.onValueChange(val, false);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.dateForm.disable() : this.dateForm.enable();
  }

  // All validations handled in parent form
  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
