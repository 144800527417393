<ng-container [ngSwitch]="true" class="address-format">
  <!-- US, GB, AU, CA TEMPLATE -->
  <ng-container
    *ngSwitchCase="
      addressFormat === 'US' || addressFormat === 'GB' || addressFormat === 'AU' || addressFormat === 'CA'
    ">
    <div class="address-display-line-item" *ngIf="isLineItemDisplay && address">
      <span class="customer-street1" *ngIf="address.street1">
        {{ address.street1 }}
      </span>
      <span class="customer-city">{{ address.city }}, </span>
      <span class="customer-province">{{ address.province }} </span>
      <span class="customer-postalCode">{{ address.postalCode }}</span>
    </div>

    <ul class="address-display" *ngIf="!isLineItemDisplay && address">
      <li *ngIf="address.street1" class="street1">
        {{ address.street1 }}
      </li>
      <li *ngIf="address.street2" class="street2">
        {{ address.street2 }}
      </li>
      <li *ngIf="address.street3" class="street3">
        {{ address.street3 }}
      </li>
      <li *ngIf="address.street4" class="street4">
        {{ address.street4 }}
      </li>
      <li class="address-format">
        <span class="city">{{ address.city }}<span *ngIf="address.city">, </span></span>
        <span class="province" *ngIf="address.province && !address.provinceCode">{{ address.province }} </span>
        <span class="province" *ngIf="address.provinceCode">{{ address.provinceCode }} </span>
        <span class="postalCode">{{ address.postalCode }}</span>
      </li>
      <li *ngIf="address.countryCode">
        <span class="country">{{ address.countryCode }}</span>
      </li>
      <li *ngIf="address.fiscalCode">
        <span class="fiscalCode">{{ address.fiscalCode }}</span>
      </li>
    </ul>
  </ng-container>
  <!-- /US, GB, AU, CA TEMPLATE -->

  <!-- DE TEMPLATE -->
  <ng-container *ngSwitchCase="addressFormat === 'DE'">
    <div class="address-display-line-item" *ngIf="isLineItemDisplay && address">
      <span class="customer-street1" *ngIf="address.street1">
        {{ address.street1 }}
      </span>
      <span class="customer-postalCode">{{ address.postalCode }} </span>
      <span class="customer-city">{{ address.city }}</span>
    </div>

    <ul class="address-display" *ngIf="!isLineItemDisplay && address">
      <li *ngIf="address.street2" class="street2">
        {{ address.street2 }}
      </li>
      <li *ngIf="address.street3" class="street3">
        {{ address.street3 }}
      </li>
      <li *ngIf="address.street4" class="street4">
        {{ address.street4 }}
      </li>
      <li *ngIf="address.street1" class="street1">
        {{ address.street1 }}
      </li>
      <li class="address-format">
        <span class="postalCode">{{ address.postalCode }} </span>
        <span class="city">{{ address.city }}</span>
      </li>
      <li *ngIf="address.countryCode">
        <span class="country">{{ address.countryCode }}</span>
      </li>
      <li *ngIf="address.fiscalCode">
        <span class="fiscalCode">{{ address.fiscalCode }}</span>
      </li>
    </ul>
  </ng-container>
  <!-- /DE TEMPLATE -->

  <!-- DEFAULT TEMPLATE -->
  <ng-container *ngSwitchDefault>
    <div class="address-display-line-item" *ngIf="isLineItemDisplay && address">
      <span class="customer-street1" *ngIf="address.street1">
        {{ address.street1 }}
      </span>
      <span class="customer-postalCode">{{ address.postalCode }} </span>
      <span class="customer-city">{{ address.city }} </span>
      <span class="customer-province">{{ address.province }}</span>
    </div>

    <ul class="address-display" *ngIf="!isLineItemDisplay && address">
      <li *ngIf="address.street2" class="street2">
        {{ address.street2 }}
      </li>
      <li *ngIf="address.street3" class="street3">
        {{ address.street3 }}
      </li>
      <li *ngIf="address.street4" class="street4">
        {{ address.street4 }}
      </li>
      <li *ngIf="address.street1" class="street1">
        {{ address.street1 }}
      </li>
      <li class="address-format">
        <span class="postalCode">{{ address.postalCode }} </span>
        <span class="city">{{ address.city }} </span>
        <span class="province" *ngIf="address.province && !address.provinceCode">{{ address.province }} </span>
        <span class="province" *ngIf="address.provinceCode">{{ address.provinceCode }} </span>
      </li>
      <li *ngIf="address.countryCode">
        <span class="country">{{ address.countryCode }}</span>
      </li>
      <li *ngIf="address.fiscalCode">
        <span class="fiscalCode">{{ address.fiscalCode }}</span>
      </li>
    </ul>
  </ng-container>
  <!-- /DEFAULT TEMPLATE -->
</ng-container>
