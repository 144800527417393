import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ComponentsModule } from '../../shared/components/components.module';
import { TestCodesService } from './test-codes.service';

@NgModule({
  imports: [CommonModule, ComponentsModule, FormsModule, TranslateModule.forChild({})],
  providers: [TranslatePipe, TestCodesService],
})
export class TestCodesModule {}
