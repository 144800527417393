import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { PatientSex } from '../../models/patient-sex.model';
import { KeyboardService } from '@lims-common-ux/lux';

@Component({
  selector: 'cl-sex-field',
  templateUrl: './patient-sex-field.component.html',
  styleUrls: ['../search-select/search-select.component.scss', './patient-sex-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PatientSexFieldComponent,
      multi: true,
    },
  ],
})
export class PatientSexFieldComponent extends SearchSelectComponent<PatientSex> implements ControlValueAccessor {
  value: PatientSex;
  @Input()
  displayedData: PatientSex[] = [];
  @Input()
  data: PatientSex[] = [];
  @Input()
  focusNextOnWrite = true;

  onValueChange = (value: PatientSex, emitChange = true) => {
    this.value = value;

    if (this.value) {
      this.displayValue = PatientSex.createLabel(value);

      if (this.focusNextOnWrite) {
        this.focusNext();
      }
    }

    if (emitChange) {
      this.onChange(value);
    }
  };

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor(private keyboardService: KeyboardService) {
    super();
  }

  writeValue(value: PatientSex) {
    this.onValueChange(value, false);
  }

  focusNext() {
    requestAnimationFrame(() => {
      this.input.nativeElement.focus();
      this.keyboardService.focusNext();
    });
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  handleEscape($event) {
    if (this.displayedData?.length > 0) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.hideOptions();
      this.input.nativeElement.focus();
    }
  }

  handleIncompleteInteraction() {
    requestAnimationFrame(() => {
      if (!this.isInputActiveElement() && !this.value) {
        this.selectItem(null);
        this.onValueChange(null);
      }
    });
  }
}
