export interface Veterinarian {
  name: string;
}

export class Veterinarian {
  constructor(
    veterinarian?: Veterinarian,
    public name: string = veterinarian && veterinarian.name ? veterinarian.name : ''
  ) {}
}
