<cl-field-wrapper
  luxFill
  id="requisitionId"
  (lostFocus)="handleLosingFocus($event)"
  [class.top-layer]="!!control?.errors"
  [label]="'LABELS.REQUISITION_ID' | translate"
  [loading]="searching"
  [errors]="control?.errors ? control?.errors : []"
  [microText]="showMicroText ? [orderNotFoundMicroText] : []">
  <!--suppress TypeScriptUnresolvedReference -->
  <input
    #input
    data-cy="reqId-input"
    clSelectSelf
    [disabled]="settingUpEorder"
    (input)="handleInput($event?.target?.value)"
    (keydown.enter)="search()"
    class="spot-form__input"
    spellcheck="false"
    [value]="value?.requisitionId ? value?.requisitionId : ''"
    autocomplete="new-item" />
</cl-field-wrapper>
<lux-modal-container
  luxFill
  data-cy="electronic-order-search-modal"
  [modalId]="'electronicOrderSearchModal'"
  [modalWidth]="'60%'"
  [modalHeight]="'40%'"
  [showHeader]="false"
  [visible]="showModal"
  (modalClose)="onModalClose()">
  <div luxLayout="row" class="spot-modal__header electronic-order-search-modal-header row">
    <div class="spot-modal__titles" luxLayout="40%">
      <h2 class="spot-modal__title">
        {{ 'LABELS.SELECT_CORRECT_ORDER' | translate }}
      </h2>
    </div>

    <cl-button
      #closeButton
      (click)="closeModal()"
      (keydown.escape)="closeModal()"
      [type]="'icon-only'"
      [icon]="'close'"
      class="spot-modal__header-cancel-button cl-modal-cancel cl-modal-close">
    </cl-button>
  </div>

  <div luxLayout="row" class="modal-form-content">
    <div luxLayout="column" luxFlex="100%" luxLayoutGap="20px">
      <table luxFlex id="electronic-order-search-results" class="spot-data-table electronic-order-search-results">
        <thead>
          <tr class="electronic-order-line-item">
            <th luxFlex="30%">{{ 'LABELS.DATE' | translate }}</th>
            <th luxFlex="30%">{{ 'LABELS.SOURCE' | translate }}</th>
            <th luxFlex="10%">{{ 'LABELS.CUSTOMER_CODE' | translate }}</th>
            <th luxFlex="20%">{{ 'LABELS.PATIENT_NAME' | translate }}</th>
            <th luxFlex="20%">{{ 'LABELS.OWNER_NAME' | translate }}</th>
          </tr>
        </thead>

        <tbody #electronicOrderSearchResults>
          <ng-container *ngIf="!searching && electronicOrders?.length > 0">
            <ng-container *ngFor="let electronicOrder of electronicOrders">
              <!--using mousedown instead of click to fix defect when click not firing after element get moved
    https://stackoverflow.com/questions/46813181/click-event-is-not-firing-for-element-which-changes-position-when-has-focus-->
              <tr
                class="electronic-order-line-item result-data"
                (keydown.tab)="onKeyDown($event)"
                (keydown.escape)="onKeyDown($event)"
                (mousedown)="selectOrder(electronicOrder)">
                <td luxFlex="30%">
                  <span>{{ electronicOrder.dateCreated | datePipe: (lab$ | async) }}</span>
                </td>
                <td luxFlex="30%">
                  <a
                    href="javascript:void(0)"
                    (click)="selectOrder(electronicOrder)"
                    class="source"
                    #electronicOrderLinks
                    >{{ 'LABELS.' + electronicOrder.source | translate }}</a
                  >
                </td>
                <td luxFlex="10%">
                  <span>{{ electronicOrder.customerCode }}</span>
                </td>
                <td luxFlex="20%">
                  <span>{{ electronicOrder.patient.name }}</span>
                </td>
                <td luxFlex="20%">
                  <span>{{ electronicOrder.owner.name }}</span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</lux-modal-container>
