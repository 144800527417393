<cl-field-wrapper
  luxFill
  [required]="false"
  [searcher]="false"
  [errors]="errors"
  [label]="label"
  [microText]="displayedValues.length >= maxValues ? ['ERRORS_AND_FEEDBACK.MAX_EMAIL_FAX'] : []"
  class="spot-typography__text--body">
  <div *ngIf="maxValues" class="cl-emailfax-counter">{{ displayedValues.length }} / {{ maxValues }}</div>
  <!-- /Email Fax Value Display -->
  <div luxFlexFill *ngIf="value">
    <div *ngFor="let value of displayedValues; let index = index; let first = first">
      <cl-pill class="added-value" [type]="'leopard-box'" [removable]="true" (remove)="removeValue(value)">
        {{ value }}
      </cl-pill>
    </div>
  </div>
  <!-- /Email Fax Value Display -->
  <div class="input-outer-wrapper spot-form__input email-fax">
    <div class="input-inner-wrapper">
      <input
        #input
        class="spot-form__input"
        spellcheck="false"
        autocomplete="new-item"
        clSelectSelf
        (blur)="handleBlur($event)"
        (keydown.enter)="handleEnter($event)"
        (input)="handleInput($event)"
        [attr.placeholder]="placeholder" />
    </div>
    <div class="after-input">
      <div
        luxFill
        luxLayoutAlign="center center"
        class="after-input-inner"
        *ngIf="true"
        [@transformInOut]="addingValue ? 'in' : 'out'">
        <svg class="after-input-svg" aria-labelledby="title">
          <use *ngIf="addingValue === 'fax'" xlink:href="/assets/icons/spot_icons.svg#phone-contact"></use>
          <use *ngIf="addingValue === 'email'" xlink:href="/assets/icons/spot_icons.svg#email"></use>
        </svg>
      </div>
    </div>
  </div>
</cl-field-wrapper>
