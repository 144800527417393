import { Component, Input } from '@angular/core';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Discount } from '../../models/discount.model';

@Component({
  selector: 'cl-discount-field',
  templateUrl: './discount-field.component.html',
  styleUrls: ['../search-select/search-select.component.scss', './discount-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DiscountFieldComponent,
      multi: true,
    },
  ],
})
export class DiscountFieldComponent extends SearchSelectComponent<Discount> implements ControlValueAccessor {
  value: Discount;
  @Input()
  displayedData: Discount[] = [];
  @Input()
  data: Discount[] = [];

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor() {
    super();
  }

  onValueChange(value: Discount, emit = true) {
    this.value = value;

    if (this.value) {
      this.displayValue = this.value.label;

      setTimeout(() => {
        this.goToNext.emit(true);
      });
    }
    if (emit) {
      this.onChange(value);
    }
  }

  writeValue(value: Discount) {
    this.onValueChange(value, false);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  handleEscape($event) {
    if (this.displayedData?.length > 0) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.hideOptions();
      this.input.nativeElement.focus();
    }
  }

  handleIncompleteInteraction() {
    requestAnimationFrame(() => {
      if (!this.isInputActiveElement() && !this.value) {
        this.selectItem(null);
        this.onValueChange(null);
      }
    });
  }
}
