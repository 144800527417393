export class Discount {
  constructor(
    discount: DiscountReference,
    public code: string = discount.code,
    public shortCode: string = discount.shortCode,
    public description: string = discount.description,
    public label: string = `${shortCode ? shortCode + ' - ' : ''}${description}`
  ) {}
}

export interface DiscountReference {
  code: string;
  shortCode: string;
  description: string;
}
