// PatientSex may be sourced from lab reference data, electronic orders, or on Accessions
export class PatientSex {
  code: string;
  shortCode: string;
  description?: string;
  label?: string;

  constructor() {}

  static createLabel(patientSex: PatientSex): string {
    let label;
    if (patientSex.description) {
      label = `${patientSex.shortCode ? patientSex.shortCode + ' - ' : ''}${patientSex.description}`;
    } else if (patientSex.label) {
      label = `${patientSex.shortCode ? patientSex.shortCode + ' - ' : ''}${patientSex.label}`;
    }
    return label;
  }
}
