import { EventEmitter, Injectable } from '@angular/core';
import { OrderForm } from './shared/models/order-form.model';
import { ReferenceData } from './shared/models/reference-data.model';
import { FeaturesService } from '@lims-common-ux/lux';
import { BehaviorSubject } from 'rxjs';
import { Accession } from './shared/models/accession.model';
import { Test } from './shared/models/test.model';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  // set during lab resolving, LabResolver, and the AppService.setupLab.
  referenceData: ReferenceData;
  private _initialState: OrderForm = new OrderForm();
  private _orderForm: OrderForm = { ...this._initialState };
  private _orderForm$ = new BehaviorSubject<OrderForm>(null);
  orderForm$ = this._orderForm$.asObservable();

  private _existingAccession: Accession = null;
  private _existingAccession$: BehaviorSubject<Accession> = new BehaviorSubject<Accession>(this._existingAccession);
  public existingAccession$ = this._existingAccession$.asObservable();

  helpModalClosedEvent: EventEmitter<void> = new EventEmitter<void>();
  snackbarErrorClosedEvent: EventEmitter<void> = new EventEmitter<void>();

  accessionId: string;

  constructor(private featuresService: FeaturesService) {}

  set orderForm(value) {
    this._orderForm = { ...this._initialState, ...value };
    this._orderForm.patient = { ...this._initialState.patient, ...value.patient };
    this._orderForm$.next(value);
  }

  get orderForm(): OrderForm {
    return this._orderForm;
  }

  set existingAccession(value: Accession) {
    this._existingAccession = value;
    this._existingAccession$.next(this._existingAccession);
  }

  get existingAccession(): Accession {
    return this._existingAccession$.value;
  }

  get orderedTests(): Test[] {
    return this.existingAccession?.orderedTests;
  }

  helpModalClosed() {
    this.helpModalClosedEvent.emit();
  }

  snackbarErrorClosed() {
    this.snackbarErrorClosedEvent.emit();
  }

  resetAccession() {
    this.existingAccession = null;
    this.accessionId = null;
    this._orderForm = new OrderForm();
    this._orderForm$.next(null);
  }

  isActiveFeature(featureName: string): boolean {
    return this.featuresService.hasFeature(featureName);
  }
}
