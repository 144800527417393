import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NgControl,
  NgForm,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ElectronicOrder } from '../../shared/models/electronic-order.model';
import { RequisitionInfo } from '../../shared/models/requisition-info.model';
import { ElectronicOrderService } from '../electronic-order/electronic-order.service';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs';
import { Lab } from '@lims-common-ux/lux';

@Component({
  selector: 'cl-electronic-order-field',
  templateUrl: './electronic-order-field.component.html',
  styleUrls: ['./electronic-order-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ElectronicOrderFieldComponent),
      multi: true,
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ElectronicOrderFieldComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild('input', { static: false })
  input: ElementRef;
  @ViewChildren('electronicOrderLinks')
  electronicOrderLinks: QueryList<any>;
  control: AbstractControl = null;
  @Input()
  searching = false;
  @Input()
  searchable = false;
  showMicroText: boolean = false;
  @Input()
  settingUpEorder: boolean = false;
  @Input()
  editing: boolean = false;
  disabled = false;
  orderNotFoundMicroText: string = 'ERRORS_AND_FEEDBACK.ORDER_NOT_FOUND';
  @Output()
  beginSearch: EventEmitter<true> = new EventEmitter<true>();
  @Output()
  endSearch: EventEmitter<ElectronicOrder | null | undefined> = new EventEmitter<ElectronicOrder | null | undefined>();
  previousSearchValue = '';
  value: RequisitionInfo = null;
  onChange: Function = () => {};
  onTouched: Function = () => {};
  showModal = false;
  electronicOrders: ElectronicOrder[];
  lab$: Observable<Lab>;
  previousActiveElement: HTMLElement;

  constructor(
    private electronicOrderService: ElectronicOrderService,
    private translate: TranslateService,
    private injector: Injector,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.orderNotFoundMicroText = this.translate.instant(this.orderNotFoundMicroText);
    this.lab$ = this.appService.labSubject;
  }

  ngAfterViewInit(): void {
    // we use the control to more easily get ahold of error structures
    // which we pass into the field wrapper component for display
    const model = this.injector.get(NgControl);
    this.control = model.control;
  }

  focus(): void {
    this.input.nativeElement.focus();
  }

  enable(): void {
    this.setDisabledState(false);
  }

  disable(): void {
    this.setDisabledState(true);
  }

  handleInput(fieldVal: string): void {
    if (this.editing) {
      this.onValueChange({
        requisitionId: fieldVal,
        electronicOrderId: this.value?.electronicOrderId,
        electronicOrderVersion: this.value?.electronicOrderVersion,
      });
    } else {
      this.onValueChange({
        requisitionId: fieldVal,
        electronicOrderId: '',
        electronicOrderVersion: null,
      });
    }
  }

  handleLosingFocus($event: Element) {
    if (($event as HTMLButtonElement)?.name !== 'reset' && ($event as HTMLButtonElement)?.name !== 'save') {
      this.search();
    }
  }

  private trimLeadingZero() {
    if (this.value && this.value.requisitionId[0] === '0') {
      this.value.requisitionId = this.value.requisitionId.substring(1, this.value.requisitionId.length);
    }
  }

  search(): void {
    this.onTouched(this.value);

    if (this.canSearch()) {
      this.focus();

      this.beginSearch.emit(true);

      this.trimLeadingZero();

      this.searching = true;

      this.previousSearchValue = this.value.requisitionId;

      this.electronicOrderService.findByReqId(this.value.requisitionId).subscribe({
        next: (electronicOrders) => {
          this.searching = false;
          this.showMicroText = false;

          if (electronicOrders.length > 1) {
            this.electronicOrders = electronicOrders;
            this.openModal();
          } else if (electronicOrders.length === 1) {
            this.publishSearchResult(electronicOrders[0]);
          } else {
            this.publishSearchResult(null);
          }
        },
        error: (err) => {
          this.publishSearchResult(null);
        },
      });
    } else if (this.searching) {
      this.focus();
    }
  }

  publishSearchResult(eOrder: ElectronicOrder | null) {
    if (eOrder) {
      this.value.electronicOrderId = eOrder.electronicOrderId;
    }
    this.searching = false;

    this.onChange(this.value);

    this.endSearch.emit(eOrder);

    this.showMicroText = !(eOrder || eOrder !== null);
  }

  canSearch(): boolean {
    return !!(
      this.value?.requisitionId &&
      this.searching === false &&
      this.value.requisitionId !== this.previousSearchValue &&
      this.settingUpEorder === false &&
      !this.editing &&
      this.searchable
    );
  }

  onValueChange(val: RequisitionInfo, emitChange = true) {
    if (val !== this.value) {
      this.value = val;

      if (!this.value || !this.value.requisitionId) {
        this.showMicroText = false;
        this.previousSearchValue = '';
        this.searching = false;
      }

      if (this.input && emitChange) {
        this.onChange(this.value);
      }
    }
  }

  // Start ControlValueAccessor implementation
  writeValue(value: RequisitionInfo): void {
    this.onValueChange(value, false);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any): void {
    this.onTouched = onTouch;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // End ControlValueAccessor implementation

  selectOrder(electronicOrder: ElectronicOrder) {
    this.publishSearchResult(electronicOrder);
    this.showModal = false;
  }

  onKeyDown(event) {
    if (!event) {
      return;
    }

    // This component contains both the search input and the modal. We require the
    // following check for the modal._visible to allow for event bubbling.
    // If the search modal isn't visible, then bubble the event for handling
    if (!this.showModal) {
      return event;
    }

    switch (event.key.toUpperCase()) {
      case 'ESCAPE':
        this.preventDefaultStopPropagation(event);
        this.closeModal();
        break;
      case 'TAB':
        if (
          this.electronicOrderLinks.last &&
          this.electronicOrderLinks.last?.nativeElement === event.target &&
          !event.shiftKey
        ) {
          // Trap tab on last search result card and focus first search result card
          this.preventDefaultStopPropagation(event);
          this.electronicOrderLinks.first?.nativeElement.focus();
        }
        break;
      default:
        return;
    }
  }

  openModal() {
    this.showModal = true;
    this.previousActiveElement = document.activeElement as HTMLElement;
    setTimeout(() => {
      this.electronicOrderLinks.first?.nativeElement.focus();
    }, 0);
  }

  closeModal() {
    this.showModal = false;
    this.onModalClose();
  }

  preventDefaultStopPropagation(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onModalClose() {
    if (!this.value?.electronicOrderId) {
      this.publishSearchResult(undefined);
      if (this.previousActiveElement) {
        setTimeout(() => this.previousActiveElement.focus(), 0);
      }
    } else {
      this.reset();
    }

    if (this.showModal) {
      this.showModal = false;
      this.onTouched();
    }
  }

  reset() {
    this.electronicOrders = [];
    this.value.electronicOrderId = '';
    this.value.electronicOrderVersion = null;
  }
}
