import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DrawTime } from '../../models/sample.model';

@Component({
  selector: 'cl-sample-draw-time',
  templateUrl: './sample-draw-time.component.html',
  styleUrls: ['./sample-draw-time.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SampleDrawTimeComponent,
      multi: true,
    },
  ],
})
export class SampleDrawTimeComponent implements ControlValueAccessor {
  value: DrawTime = null;
  fieldValue: string = '';
  hasError = false;
  minUnitIdentifier = this.translate.instant('LABELS.DRAW_TIME_MINUTE_UNIT');
  hourUnitIdentifier = this.translate.instant('LABELS.DRAW_TIME_HOUR_UNIT');

  @ViewChild('input')
  input!: ElementRef;

  @Output()
  arrowUp: EventEmitter<true> = new EventEmitter<true>();

  @Output()
  arrowDown: EventEmitter<true> = new EventEmitter<true>();

  @Output()
  arrowRight: EventEmitter<true> = new EventEmitter<true>();

  @Output()
  arrowLeft: EventEmitter<true> = new EventEmitter<true>();

  @Output()
  confirm: EventEmitter<true> = new EventEmitter<true>();

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(public translate: TranslateService) {}

  handleInput($event): void {
    $event.preventDefault();
    $event.stopPropagation();

    const fieldVal = $event.target.value;

    let validationCheck = this.validateInput(fieldVal);

    if (validationCheck.valid) {
      this.hasError = false;

      if (validationCheck.hasHourUnit) {
        this.value = {
          interval: {
            time: validationCheck.value,
            unit: 'HOURS',
          },
        };
      } else if (validationCheck.hasMinuteUnit) {
        this.value = {
          interval: {
            time: validationCheck.value,
            unit: 'MINUTES',
          },
        };
      } else {
        this.value = {
          time: validationCheck.value,
        };
      }

      this.onChange(this.value);
    } else {
      this.hasError = true;
      this.value = null;

      this.onChange(this.value);
    }
  }

  // is the input a valid input
  // does not break down/parse the value for saving
  validateInput(value: string) {
    value = value.trim().toLowerCase();

    if (value) {
      let validationCheck = this.isValidUnitEntry(value);

      if (validationCheck.valid) {
        return validationCheck;
      } else {
        validationCheck = this.isValidTimeEntry(value);

        return validationCheck;
      }
    } else {
      return { valid: true, value: value };
    }
  }

  isValidUnitEntry(value: string) {
    let isValid = false;
    let numericValue;
    let hasMinuteUnit = (value.match(this.minUnitIdentifier) || []).length === 1;
    const hasHourUnit = (value.match(this.hourUnitIdentifier) || []).length === 1;

    if (hasMinuteUnit && hasHourUnit) {
      return { valid: false, value: value };
    }

    if (hasMinuteUnit) {
      numericValue = Number(value.replace('m', ''));
    } else if (hasHourUnit) {
      numericValue = Number(value.replace('h', ''));
    } else {
      hasMinuteUnit = true;
      numericValue = Number(value);
    }

    if (Number.isInteger(numericValue) && numericValue <= 9999 && numericValue >= 0) {
      isValid = true;
    }

    return { valid: isValid, hasMinuteUnit: hasMinuteUnit, hasHourUnit: hasHourUnit, value: numericValue };
  }

  isValidTimeEntry(value: string) {
    let isValid = false;
    const hasColon = value.indexOf(':') !== -1;

    if (value.length <= 5 && hasColon) {
      const hoursValue = value.replace(/[:].*/g, '');
      const minutesValue = value.replace(/.{0,1000}[:]/g, '');

      if (minutesValue.length === 2 && hoursValue.length >= 1) {
        const hoursValueNum = Number(hoursValue);
        const minutesValueNum = Number(minutesValue);

        if ((hoursValueNum < 24 && minutesValueNum <= 59) || (hoursValueNum === 24 && minutesValueNum === 0)) {
          isValid = true;
        }

        if (hoursValue.length === 1) {
          value = '0' + value;
        }
      }
    }

    return { valid: isValid, time: true, value: value };
  }

  writeValue(value: DrawTime) {
    this.value = value;

    if (this.value) {
      if (this.value.time) {
        this.fieldValue = this.value.time;
      } else if (this.value.interval) {
        this.fieldValue =
          this.value.interval.time +
          (this.value.interval.unit === 'MINUTES' ? this.minUnitIdentifier : this.hourUnitIdentifier);
      }
    }
  }

  focus() {
    this.input.nativeElement.focus();
  }

  handleEnter($event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.hasError) {
      this.confirm.emit(true);
    }
  }

  handleArrowUp() {
    this.arrowUp.emit(true);
  }

  handleArrowRight() {
    if (
      !this.input.nativeElement.value ||
      this.input.nativeElement.selectionStart === this.input.nativeElement.value.length
    ) {
      this.arrowRight.emit(true);
    }
  }

  handleArrowDown() {
    this.arrowDown.emit(true);
  }

  handleArrowLeft() {
    if (this.input.nativeElement.selectionStart === 0) {
      this.arrowLeft.emit(true);
    }
  }

  handleBackspace($event) {
    $event.stopPropagation();
  }

  /* CVA BOILERPLATE */
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }
}
