<cl-field-wrapper
  #componentWrapper
  id="testCodes"
  class="autocomplete-wrapper"
  [loading]="searching"
  [required]="true"
  (lostFocus)="handleFocusOut($event)"
  (keydown.escape)="handleEscape($event)"
  [errors]="errors"
  [searcher]="true"
  [microText]="microText"
  [label]="'LABELS.TEST_CODES' | translate">
  <input
    #input
    clSelectSelf
    [placeholder]="placeholder"
    [(ngModel)]="displayValue"
    (keydown.backspace)="handleBackspace()"
    (dblclick)="toggleEdit()"
    (blur)="onChange(this.value)"
    (keydown.arrowDown)="onArrowDown($event)"
    (keydown.arrowUp)="onArrowUp($event)"
    (keydown.tab)="onTabDown($event)"
    [disabled]="disableInput"
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item" />
  <div [hidden]="!displayedData?.length" class="search-results-wrapper">
    <button
      luxLayout="row"
      class="result-list-item"
      data-cy="autocomplete-item"
      type="button"
      (click)="handleClick($event, match)"
      *ngFor="let match of displayedData; let index = index; let first = first"
      [class.selection]="first">
      <span class="content" *ngIf="match.testCode">
        <span class="short-code">
          {{ match.testCode }}
        </span>
        -
        {{ match.name }}
      </span>
    </button>
  </div>
</cl-field-wrapper>
<div class="spot-form__microcopy" *ngIf="warningSameTestCode">
  <div class="cl-microcopy test-warning" data-cy="same-test-warning">
    <svg class="spot-icon test-warning-icon" aria-labelledby="title">
      <title>{{ 'ERRORS_AND_FEEDBACK.REMOVE_OR_CANCEL_TEST' | translate: { value: warningSameTestCode } }}</title>
      <use xlink:href="/assets/icons/spot_icons.svg#alert-notification"></use>
    </svg>
    {{ 'ERRORS_AND_FEEDBACK.REMOVE_OR_CANCEL_TEST' | translate: { value: warningSameTestCode } }}
  </div>
</div>
<div class="spot-form__microcopy" *ngIf="disableInput && edit">
  <div class="cl-microcopy test-warning" data-cy="canceled-accession-warning">
    <svg class="spot-icon test-warning-icon" aria-labelledby="title">
      <title>{{ 'ERRORS_AND_FEEDBACK.CANNOT_ADD_TEST' | translate }}</title>
      <use xlink:href="/assets/icons/spot_icons.svg#alert-notification"></use>
    </svg>
    {{ 'ERRORS_AND_FEEDBACK.CANNOT_ADD_TEST' | translate }}
  </div>
</div>
<div class="spot-form__microcopy" *ngIf="disableInput && areAllTestsCanceled()">
  <div class="cl-microcopy test-warning" data-cy="canceled-transit-warning">
    <svg class="spot-icon test-warning-icon" aria-labelledby="title">
      <title>{{ 'ERRORS_AND_FEEDBACK.TRANSIT_CANCEL' | translate }}</title>
      <use xlink:href="/assets/icons/spot_icons.svg#alert-notification"></use>
    </svg>
    {{ 'ERRORS_AND_FEEDBACK.TRANSIT_CANCEL' | translate }}
  </div>
</div>
<div *ngIf="value && value !== missingInformationGlyph">
  <ng-content *ngIf="showAccessionCancelReasonSelector()"></ng-content>
  <div *ngFor="let addedTest of value; let index = index; let first = first">
    <cl-pill
      class="added-test added-test-short-code-{{ addedTest.testCode }} {{ addedTest.displayType }} {{
        addedTest.reviewDisplayType
      }}"
      (dblclick)="toggleEdit()"
      [type]="addedTest.displayType || 'leopard-box'"
      [removable]="isRemovable(addedTest.testId) || (addedTest.isUncanceled && edit)"
      (remove)="removeTest(addedTest, $event)">
      <div luxLayout="row" luxLayoutAlign="start center">
        <span luxLayout="row" class="review-indicators">
          <span class="review-indicator-alert"></span>
          <span class="review-indicator-positive"></span>
        </span>

        <span
          [luxFlex]="getLabelMaxWidth(addedTest)"
          data-cy="test-short-code-and-name-container"
          [class]="addedTest.cancelReasonCode || disableInput ? 'canceled' : ''"
          [class.has-review-alert]="addedTest['reviewAlert']"
          [class.has-review-positive]="addedTest['reviewPositive']"
          ><span class="short-code-selected">{{ addedTest.testCode }}</span
          >&nbsp;-&nbsp;{{ addedTest.name }}</span
        >

        <div class="add-on-wrapper">
          <span *ngIf="displayAddOnTestToggle(addedTest)">
            <cl-checkbox
              #addOnControl
              data-cy="add-on-control"
              class="add-on-control"
              (updateParent)="setIsAddOn(addedTest, $event)"
              [value]="addedTest.isAddOn"
              [toggle]="true"></cl-checkbox>
          </span>
          <!-- The add-on-indicator is a static indicator. It is not focusable. It is not clickable -->
          <svg
            *ngIf="isAddOnTest(addedTest)"
            class="add-on-indicator"
            [class.inactive]="!addedTest.isAddOn"
            [class.existing]="isExistingAddOn(addedTest)"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 32 32">
            <path d="M19.984 12.016H31v7.968H19.984V31h-7.968V19.984H1v-7.968h11.016V1h7.968v11.016z" />
          </svg>
        </div>
      </div>

      <div
        luxLayout="row"
        luxLayoutAlign="end center"
        class="cancel-reason-localized"
        data-cy="cancel-reason-localized"
        *ngIf="showCancelReason(addedTest)">
        {{ 'LABELS.CANCELED' | translate }}:&nbsp;&nbsp;{{ getLocalizedCancelReason(addedTest) }}
      </div>

      <div
        luxLayout="row"
        luxLayoutAlign="end center"
        class="cancel-reason-selector-wrapper"
        data-cy="cancel-reason-selector"
        *ngIf="showCancelReasonSelector(addedTest)">
        <label
          >{{ 'LABELS.CANCEL_TEST_REASON' | translate
          }}<select (change)="updateCancelReason(addedTest, $event)">
            <option *ngIf="!isExistingCanceledTest(addedTest)"></option>
            <ng-container *ngFor="let cancelReason of cancelReasons">
              <option value="{{ cancelReason.code }}" [selected]="addedTest.cancelReasonCode === cancelReason.code">
                {{ cancelReason.description }}
              </option>
            </ng-container>
          </select>
        </label>
      </div>
    </cl-pill>
  </div>
</div>
