import { PatientAgeDOB } from '../../shared/modules/age-dob/age-dob.model';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Response } from '../../shared/services/response/response';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OrderEntryService, OrderIdResource } from '../order-entry.service';
import { OrderValidationService } from '../order-validation/order-validation.service';
import { SamplesAssociationsService } from '../../sample-association/samples-associations.service';
import { BrowserUtil, DomUtil } from '../../shared/utils';
import { FieldValidators } from '../../shared/components/field/field.validators';
import { Field } from '../../shared/components/field/field.component';
import { InputComponent } from '../../shared/components/input/input.component';
import { Customer } from '../../shared/models/customer.model';
import { Discount } from '../../shared/models/discount.model';
import { CanceledTest, Test } from '../../shared/models/test.model';
import { Sample, SAMPLE_STATUS, SampleFormValue, SampleTypeStatus } from '../../shared/models/sample.model';
import { CustomerMessage } from '../../shared/models/customer-message.model';
import { PetOwnerBilling, PetOwnerBillingComponent } from '../pet-owner-billing/pet-owner-billing.component';
import { CustomerSearchComponent } from '../customer-search/customer-search.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { of, Subject, Subscription, map, takeUntil, Observable, forkJoin } from 'rxjs';
import { ListComponent } from '../../shared/components/list/list.component';
import { EmailFaxComponent } from '../../shared/components/email-fax/email-fax.component';

import { CheckboxComponent } from '../../shared/components/checkbox/checkbox.component';
import { ElectronicOrderService } from '../electronic-order/electronic-order.service';
import { SampleTestAssociationRes } from '../../sample-association/sample-test-association';

import { OrderValidationDetailsComponent } from '../order-validation/order-validation-details.component';
import { PatientSexFieldComponent } from '../../shared/components/sex-field/patient-sex-field.component';
import { DiscountFieldComponent } from '../../shared/components/discount-field/discount-field.component';
import { TestCodeFieldComponent } from '../../shared/components/test-code-field/test-code-field.component';
import { DateFormComponent } from '../../shared/modules/date-form/date-form.component';
import { Router } from '@angular/router';
import { AnimalTypeInputValue, AnimalTypeStatus } from '../../shared/models/animal-type.model';
import {
  GlobalErrorHandlerService,
  KeyboardService,
  Lab,
  LabsService,
  LoggerService,
  PendingRequestService,
  LocaleService,
  SnackbarComponent,
  LabNotesService,
  LabNotesComponent,
} from '@lims-common-ux/lux';
import { AppService } from '../../app.service';
import { AppStateService } from '../../app-state.service';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { PatientSex } from '../../shared/models/patient-sex.model';
import { ElectronicOrderFieldComponent } from '../electronic-order-field/electronic-order-field.component';
import { CustomerBarcodeFieldComponent } from '../customer-barcode-field/customer-barcode-field.component';
import { CustomerCodeFieldComponent } from '../customer-code-field/customer-code-field.component';
import { ElectronicOrder } from '../../shared/models/electronic-order.model';
import { Accession } from '../../shared/models/accession.model';
import { SamplesComponent } from '../../shared/components/samples/samples.component';
import { PREVENTS_SAVE_REQUEST } from '../../app.component';
import { OVResponse } from '../../shared/models/ov-response';
import { ReferenceData } from '../../shared/models/reference-data.model';

export type RESTRICTED_FIELDS = 'customerBarcodeId' | 'accessionNumber' | 'requisitionInfo';

export interface OrderValidationChanges {
  newTests: Test[];
  samplesChanged: boolean;
  animalTypeChanged: boolean;
}

@Component({
  template: '', // templates are always defined in the region component
  styleUrls: ['./operational-region.component.scss'],
})
export class OperationalRegionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  animalTypeSearchUrl: string;
  @Input()
  availablePatientSexes: PatientSex[] = [];
  _availableCustomerMessages: CustomerMessage[] = [];
  @Input()
  set availableCustomerMessages(value: CustomerMessage[]) {
    this._availableCustomerMessages = value;

    this.defaultAvailableCustomerMessages = [];
    this._availableCustomerMessages.forEach((customerMsg: CustomerMessage) => {
      this.defaultAvailableCustomerMessages.push(customerMsg);
    });
  }

  get availableCustomerMessages() {
    return this._availableCustomerMessages;
  }

  defaultAvailableCustomerMessages: CustomerMessage[] = [];
  _availableSamples: Sample[] = [];
  @Input()
  set availableSamples(value: Sample[]) {
    this._availableSamples = value.filter((sample) => sample.sampleTypeStatus === SampleTypeStatus.ACTIVE);

    this.defaultAvailableSamples = [];
    this._availableSamples.forEach((sample: Sample) => {
      this.defaultAvailableSamples.push(sample);
    });
  }

  get availableSamples() {
    return this._availableSamples;
  }

  defaultAvailableSamples: Sample[] = [];

  @Input()
  availableDiscounts: Discount[] = [];
  @Input()
  supportsBarcodeTranslation: boolean;
  @Input()
  editViewDefaultField: string;

  testSearchAnimalTypeCode: string;

  @ViewChild('saveButton') saveButton: ButtonComponent;
  @ViewChild('resetButton') resetButton: ButtonComponent;
  @ViewChild('accessionNumber', { static: true }) accessionNumber: ElementRef;
  @ViewChild('vetName') vetName: ElementRef;
  @ViewChild('ownerName') ownerName: ElementRef;
  @ViewChild('patientName') patientName: ElementRef;
  @ViewChild('patientSex', { static: true })
  patientSex: PatientSexFieldComponent;
  @ViewChild('customerCode') customerCode: CustomerCodeFieldComponent;
  @ViewChild('customerBarcodeId')
  customerBarcodeId: CustomerBarcodeFieldComponent;
  @ViewChild('pimsOrderId') pimsOrderId: InputComponent;
  // the test code component reference is used directly when processing an incoming EOrder that has tests
  @ViewChild('testCodes', { static: true }) testCodes: TestCodeFieldComponent;
  @ViewChild('microchipId') microchipId: ElementRef;
  @ViewChild('collectionDate', { static: true })
  collectionDate: DateFormComponent;
  @ViewChild('discount', { static: true }) discount: DiscountFieldComponent;
  @ViewChild('emailFax', { static: true }) emailFax: EmailFaxComponent;
  @ViewChild('customerMessages', { static: true })
  customerMessages: ListComponent;
  @ViewChild('samples', { static: true }) samples: SamplesComponent;
  @ViewChild('customAnimalType')
  customAnimalTypeComponent: ElementRef;
  @ViewChild('petOwnerBilling', { static: true })
  petOwnerBilling: PetOwnerBillingComponent;
  @ViewChild('customerSearch')
  customerSearch: CustomerSearchComponent;
  @ViewChild('courierPickup')
  courierPickup: CheckboxComponent;
  @ViewChild('requisitionId') requisitionId: ElectronicOrderFieldComponent;
  @ViewChild('orderValidationDetails')
  ovDetailsComponent: OrderValidationDetailsComponent;
  @ViewChild('olderThenDaysError')
  olderThenDaysError: SnackbarComponent;
  @ViewChild('labNotes')
  labNotes: LabNotesComponent;
  // Really only used to allow for automated testing at the moment. It's present on each of the entry
  // templates for access, and if we can, should be removed when we have another way to access Accessions.
  accessionSub: Subscription;
  validateAccessionNumberResponse: Response = {};
  savedAccessionLink: string;

  orderEntryForm: FormGroup;
  maxCustomerMessages: number = 100;
  selectedCustomer: Customer;

  loading: boolean = false;
  orderSaveResponseRequested: boolean = false;
  orderSaveResponseReceived: boolean = false;
  orderSaveSuccess: boolean = false;

  lastAccessionNumber: string;
  orderResourceSub: Subscription;
  orderResource: OrderIdResource;
  orderId: string = null;
  orderValidationSub: Subscription;
  samplesAssociationsSub: Subscription;
  onDestroy$ = new Subject<void>();

  orderValidationRequested: boolean = false;
  orderValidationReceived: boolean = false;

  samplesAssociationsRequested: boolean = false;
  samplesAssociationsReceived: boolean = false;
  samplesAssociationsError: boolean = false;

  validationId: string = null;
  showOrderNotFoundMicroText: boolean = false;
  orderNotFoundMicroText: string;
  componentRequiresReset: boolean = false;
  // if the electronic order is being filled out this will be true
  loadingOrderByReqId: boolean = false;
  lastReqId: string;
  preventSave: boolean = false; // disable save button
  referenceData: ReferenceData;

  pendingRequestsSub: Subscription;
  hasPendingRequests = false;
  pendingRequests: HttpRequest<any>[];

  searchingForCustomerData = false;

  snackbarChange: any;
  snackbarSub: Subscription;

  labNotesDialogOpen: boolean;

  duplicateAccessionErrorMessageOnSave: string;
  unsavedChangesMessage = this.translate.instant('ERRORS_AND_FEEDBACK.HAS_CHANGES_WARNING');

  unresolvedEorderTestCodes: string[] = [];

  private animalTypeHasChanged = false;
  private samplesHaveChanged = false;

  isExistingAccession: boolean;
  protected readonly AnimalTypeStatus = AnimalTypeStatus;

  get lab(): Lab {
    return this.labsService.currentLab;
  }

  get isEOrder(): boolean {
    return !!this?.requisitionId?.value?.requisitionId;
  }

  get ovFieldChanges(): OrderValidationChanges {
    const changes: OrderValidationChanges = {
      newTests: [], // tests that are not yet saved
      samplesChanged: false,
      animalTypeChanged: false,
    };
    let tests = this.orderEntryForm.get('testCodes').value;
    let samples = this.orderEntryForm.get('samples').value?.samples;

    if (this.appStateService.existingAccession) {
      if (!tests) {
        tests = [];
      }

      if (!samples) {
        samples = [];
      }

      let sampleChanges = [];

      if (Array.isArray(tests)) {
        tests.forEach((test) => {
          let fnd = false;

          this.appStateService.existingAccession.orderedTests.forEach((exTest) => {
            if (!fnd && test.testId === exTest.testId) {
              fnd = true;

              let existingTestWasCanceled = false;

              this.appStateService.existingAccession.canceledTests.forEach((cTest) => {
                if (cTest.testId === exTest.testId) {
                  existingTestWasCanceled = true;
                }
              });

              if (!test.cancelReasonCode && existingTestWasCanceled) {
                fnd = false;
              }
            }
          });

          if (!fnd) {
            changes.newTests.push(test);
          }
        });
      }

      if (Array.isArray(samples)) {
        samples.forEach((sample) => {
          let fnd = false;

          this.appStateService.existingAccession.samples.forEach((exSample) => {
            if (!fnd && sample.id === exSample.id) {
              fnd = exSample?.draw?.value === sample?.draw?.value;

              if (exSample?.collectionMethod?.value !== sample?.collectionMethod?.value) {
                fnd = false;
              }

              if (exSample?.temperature?.value !== sample?.temperature?.value) {
                fnd = false;
              }

              if (exSample?.status !== sample.status || sample.isRemoved) {
                fnd = false;
              }
            }
          });

          if (!fnd) {
            sampleChanges.push(sample);
          }
        });
      }

      if (sampleChanges?.length || this.samplesHaveChanged) {
        changes.samplesChanged = true;
        this.samplesHaveChanged = true;
      }

      changes.animalTypeChanged = this.animalTypeHasChanged;
    }

    return changes;
  }

  // Capture and suppress all keydown events when saving an order
  @HostListener('window:keydown', ['$event'])
  hostKeydown(event) {
    if (this.isFormDisabled() || (this.displayFormOverlay() && !this.shouldKeepFocus())) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  hostBeforeUnload(event) {
    if (this.orderSaveResponseRequested && !this.orderSaveResponseReceived && !this.isExistingAccession) {
      event.preventDefault();
    }
  }

  onKeydown(event: KeyboardEvent) {
    const key = BrowserUtil.isMac() && event.altKey ? this.keyboardService.unwrapEventKey(event) : event.key;
    if (key.toLowerCase() === 's' && event.altKey) {
      if (this.labNotesService.labNotesOpen) {
        this.labNotes.addLabNote();
      } else {
        this.loggerService.logAction('user-action-save-shortcut', this.getLogPayload());
        this.save(event);
      }
    } else if (key.toLowerCase() === 'r' && event.altKey) {
      this.loggerService.logAction('user-action-reset-focus-shortcut', this.getLogPayload());
      this.jumpReset(event);
    }
  }

  constructor(
    public readonly orderEntryService: OrderEntryService,
    public readonly electronicOrderService: ElectronicOrderService,
    public readonly orderValidationService: OrderValidationService,
    public readonly samplesAssociationsService: SamplesAssociationsService,
    private translate: TranslateService,
    private element: ElementRef,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private loggerService: LoggerService,
    private pendingRequestService: PendingRequestService,
    private router: Router,
    public keyboardService: KeyboardService,
    protected appService: AppService,
    public appStateService: AppStateService,
    private labsService: LabsService,
    private localeService: LocaleService,
    private labNotesService: LabNotesService,
    @Inject('Window') private window: Window
  ) {}

  // order-entry component is destroyed on lab change
  // please consider unsubscribing to persistent observable, subjects, etc
  ngOnDestroy() {
    this.element.nativeElement.removeEventListener('blur', this.onInputBlur);

    if (this.orderResourceSub) {
      this.orderResourceSub.unsubscribe();
    }

    if (this.orderValidationSub) {
      this.orderValidationSub.unsubscribe();
    }

    if (this.snackbarSub) {
      this.snackbarSub.unsubscribe();
    }

    if (this.pendingRequestsSub) {
      this.pendingRequestsSub.unsubscribe();
    }

    if (this.accessionSub) {
      this.accessionSub.unsubscribe();
    }

    if (this.samplesAssociationsSub) {
      this.samplesAssociationsSub.unsubscribe();
    }

    // Good practice to manage subscriptions it takeUntil, so you don't have to unsubscribe from each subscription separately
    // Won't work for observables, that should be unsubscribed before component destroy
    // url describing it:
    // https://medium.com/angular-in-depth/the-best-way-to-unsubscribe-rxjs-observable-in-the-angular-applications-d8f9aa42f6a0
    // TODO: rewrite other subscriptions to follow same practice
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    this.onInputBlur = this.onInputBlur.bind(this);
    this.element.nativeElement.addEventListener('blur', this.onInputBlur, true);
    this.referenceData = this.appStateService.referenceData;
    this.pendingRequestsSub = this.pendingRequestService.pendingRequestsList
      .pipe(
        map((pendingRequests) => {
          const requestsWithContext = pendingRequests.filter((req) => req.context.get(PREVENTS_SAVE_REQUEST) === true);
          this.hasPendingRequests = !!requestsWithContext.length;
          return this.hasPendingRequests;
        })
      )
      .subscribe();

    this.orderNotFoundMicroText = this.translate.instant('ERRORS_AND_FEEDBACK.ORDER_NOT_FOUND');

    this.orderEntryForm = this.orderEntryService.createOrderEntryForm(this.appStateService.orderForm, this);

    // clear the customer if the customer code changes
    this.orderEntryForm.controls.customerCode.valueChanges.subscribe((val) => {
      if (this.customerCode && this.customerCode.value !== this.selectedCustomer?.customerCode) {
        this.selectedCustomer = null;
      }
    });

    this.orderEntryForm.controls.patientSex.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
      if (val && !this.loadingOrderByReqId) {
        this.keyboardService.focusNext();
      }
    });

    this.snackbarSub = this.globalErrorHandlerService.errors.subscribe((error) => {
      this.snackbarChange = error;
    });

    this.appStateService.helpModalClosedEvent.subscribe(() => {
      this.accessionNumber.nativeElement.focus();
    });

    this.appStateService.snackbarErrorClosedEvent?.subscribe(() => {
      requestAnimationFrame(() => {
        if (!this.appStateService.existingAccession) {
          this.accessionNumber.nativeElement.focus();
        } else {
          this.handleEditViewFocusDefault();
        }
      });
    });

    this.appStateService.orderForm$.subscribe((order) => {
      const resetForm = !order;

      this.handleOrderChange(resetForm);

      this.isExistingAccession = !!this.appStateService.existingAccession;

      if (this.isExistingAccession) {
        this.lastAccessionNumber = order.accessionNumber;
        this.validateAccessionNumberResponse = { data: { value: order.accessionNumber } };

        // the readonly attribute is not a boolean value in the DOM
        this.accessionNumber.nativeElement.setAttribute('readonly', true);
      }

      // Update the order customer when it exists on an Accession
      if (order && order.customer) {
        this.orderCustomer({ customer: order.customer });
      } else if (order && !order.customer && this.isExistingAccession) {
        this.orderEntryForm.controls.customerCode.markAsTouched();
      }

      // Handle missing information glyph for Test Codes
      if (this.appStateService.existingAccession && order && order.orderedTests.length === 0) {
        setTimeout(() => {
          this.orderEntryForm.controls.testCodes.setValue(this.orderEntryService.missingInformationGlyph);

          this.orderEntryForm.controls.testCodes.updateValueAndValidity();
        }, 0);
      }

      const allSamplesRemoved =
        this.appStateService.existingAccession?.samples.length &&
        this.appStateService.existingAccession?.samples?.filter((sample) => sample.status === SAMPLE_STATUS.REMOVED)
          .length === this.appStateService.existingAccession?.samples?.length;
      if (this.appStateService.existingAccession && order && allSamplesRemoved) {
        setTimeout(() => {
          this.samples.input.nativeElement.value = this.orderEntryService.missingInformationGlyph;
          this.samples.onValueChange(this.orderEntryService.missingInformationGlyph, false);
          this.orderEntryForm.controls.samples.updateValueAndValidity();
        }, 0);
      }

      // Only update Pet Owner Billing Address if the form control exists for a given region and the
      //  information exists for the Accession owner
      if (order && order.owner && order.owner.address && this.petOwnerBilling) {
        requestAnimationFrame(() => {
          const pob = new PetOwnerBilling(order.owner);
          this.petOwnerBilling.onValueChange(pob);
        });
      }
    });

    if (this.appStateService.existingAccession) {
      this.setDefaults();
    }

    this.appService.showLoadingSpinner?.subscribe((value) => {
      const olderThen30Days = this.appStateService.existingAccession
        ? this.appStateService.existingAccession.oldAccession
        : false;
      requestAnimationFrame(() => {
        if (!this.appStateService.existingAccession) {
          this.accessionNumber.nativeElement.focus();
        } else if (!olderThen30Days) {
          this.handleEditViewFocusDefault();
        } else {
          this.olderThenDaysError.show();
        }
      });
    });

    this.orderEntryForm.controls.samples.valueChanges.subscribe((value) => {
      this.handleSampleChange(value);
    });

    this.labNotesService.labNotesOpen$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.labNotesDialogOpen = value;
    });
  }

  ngAfterViewInit(): void {
    if (this.appStateService.existingAccession) {
      this.updateExistingAccessionSampleAssociations();

      setTimeout(() => {
        const parseTests = this.orderEntryForm.get('testCodes').value;
        if (Array.isArray(parseTests)) {
          this.orderValidationService.parseRecommendations(parseTests);
        }
      }, 0);
    }
  }

  handleNewTestAddition(hasNewTests: boolean) {
    if (hasNewTests) {
      this.orderEntryForm.get('cancelReasonCode').disable();
    } else {
      this.orderEntryForm.get('cancelReasonCode').enable();
    }
  }

  // In edit view, focus the correct default form field as defined in operational region template.
  // If no default field is defined in the template, then focus the collection date field (Central Europe).
  handleEditViewFocusDefault() {
    requestAnimationFrame(() => {
      const fieldName = this.editViewDefaultField;

      switch (fieldName) {
        case 'customerCode':
          this.customerCode.focus();
          break;
        case 'requisitionInfo':
          this.requisitionId.focus();
          break;
        case 'collectionDate':
          this.collectionDate.focus();
          break;
      }
    });
  }

  handleShowOlderThenDaysErrorVisibilityChange(visible: boolean) {
    if (!visible) {
      this.handleEditViewFocusDefault();
    }
  }

  handleOrderChange(resetForm: boolean) {
    this.orderEntryForm.enable();

    // Reset the order entry form when there is no order to display
    if (resetForm) {
      requestAnimationFrame(() => {
        this.setDefaults();
        this.accessionNumber.nativeElement.focus();
      });
    }
  }

  handleAnimalTypeChange(value: AnimalTypeInputValue | string) {
    if (!this.loadingOrderByReqId && !this.appStateService.existingAccession) {
      setTimeout(() => {
        this.readyOrderValidation();
        this.orderEntryForm.controls.customAnimalType.reset();
      }, 0);
    } else if (this.appStateService.existingAccession) {
      setTimeout(() => {
        if (this.orderEntryForm.controls.animalType.dirty) {
          this.animalTypeHasChanged = true;
          this.readyOrderValidation();
          this.orderEntryForm.controls.customAnimalType.reset();
        }
      }, 0);
    }
  }

  handleTestChange(testValue: Test[] | string) {
    setTimeout(() => {
      if (!this.loadingOrderByReqId && this.orderEntryForm.controls.testCodes.dirty) {
        this.readyOrderValidation();
        this.readySamplesAssociations(null);
      }
    }, 0);
  }

  handleSampleChange(sampleValue: SampleFormValue) {
    if (!this.loadingOrderByReqId && this.orderEntryForm.controls.samples.dirty) {
      this.readyOrderValidation();
      this.readySamplesAssociations(null);
    }
  }

  jumpReset(event) {
    if (event) {
      event.preventDefault();
    }
    this.resetButton.focusInput();
  }

  handleCustomerSearch($event) {
    this.searchingForCustomerData = true;
  }

  onInputBlur(event) {
    // Prevent navigation while form overlay/field mask is displayed
    // the customer search check is an ugly hack. It lets the search modal focus fields when doing a e-order search
    // that returns multiple customers and the user must pick one. With out the check this overrides and attempt
    // by the modal to gain focus.
    if (
      event.target.tagName === 'INPUT' &&
      this.displayFormOverlay() &&
      !this.customerSearch.visible &&
      !this.loadingOrderByReqId
    ) {
      if (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      event.target.focus();
    }
  }

  reqIdDirtyScanDetected(requisitionId) {
    this.loggerService.logAction('oelog-req-id-scanned-into-dirty-form', this.getLogPayload(requisitionId));
  }

  resetSamplesAssociations() {
    if (this.samplesAssociationsSub) {
      this.samplesAssociationsSub.unsubscribe();
    }

    this.samplesAssociationsRequested = false;
    this.samplesAssociationsReceived = false;
    this.samplesAssociationsError = false;

    this.samplesAssociationsService.resetAll();
  }

  resolveSamplesAssociations() {
    this.samplesAssociationsRequested = false;
    this.samplesAssociationsReceived = true;
  }

  readySamplesAssociations($event) {
    // Set to a clean state
    this.resetSamplesAssociations();

    const runPartially =
      this.appStateService.existingAccession &&
      !this.ovFieldChanges.samplesChanged &&
      !this.ovFieldChanges.animalTypeChanged;

    // Gather prerequisites
    let _samples;
    if (this.orderEntryForm.get('samples').value && this.orderEntryForm.get('samples').value.samples.length > 0) {
      _samples = this.orderEntryForm.get('samples').value.samples;
    }

    if (Array.isArray(_samples)) {
      _samples = _samples.filter((sample) => {
        return !sample.isRemoved && sample.status !== SAMPLE_STATUS.REMOVED;
      });
    }

    if (_samples?.length < 1) {
      _samples = null;
    }

    let _tests = null;

    if (Array.isArray(this.orderEntryForm.get('testCodes').value)) {
      _tests = this.orderEntryForm.get('testCodes').value.filter((addedTest) => !addedTest?.cancelReasonCode);
    } else {
      _tests = this.orderEntryForm.get('testCodes').value;
    }
    const tests = runPartially ? this.ovFieldChanges.newTests : _tests;

    // Test prerequisites
    // Roll on if conditions are not met
    if (
      !_samples ||
      _samples.length < 1 ||
      _samples === this.orderEntryService.missingInformationGlyph ||
      !tests ||
      tests.length < 1 ||
      tests === this.orderEntryService.missingInformationGlyph
    ) {
      this.resolveSamplesAssociations();
      if (runPartially) {
        this.updateExistingAccessionSampleAssociations();
      }
    } else {
      // Toggle UX behavior flags
      this.samplesAssociationsRequested = true;
      this.samplesAssociationsReceived = false;
      this.loggerService.logAction('oelog-sample-association-requested', {
        tests,
        samples: _samples,
      });

      // Regardless of service response, or error state, do not lock UX. The User should always be allowed to save.
      this.samplesAssociationsSub = this.samplesAssociationsService
        .associateSamples(_samples, tests, runPartially)
        .subscribe({
          next: (res: SampleTestAssociationRes) => {
            this.resolveSamplesAssociations();
            this.loggerService.logAction('oelog-sample-association-received', {
              ...res,
            });
          },
          error: (err) => {
            // we reset the associations when an error occurs
            // and let the user continue working
            this.resolveSamplesAssociations();
            this.samplesAssociationsError = true;
            this.loggerService.logAction('oelog-sample-association-error', {
              error: err,
            });
          },
        });
    }
  }

  readyOrderValidation() {
    this.orderValidationRequested = false;
    this.orderValidationReceived = false;

    if (this.orderValidationSub) {
      this.orderValidationSub.unsubscribe();
    }

    const _animalType = this.orderEntryForm.get('animalType').value;

    let _samples;
    if (this.orderEntryForm.get('samples').value && this.orderEntryForm.get('samples').value.samples.length > 0) {
      _samples = this.orderEntryForm.get('samples').value.samples;
    }

    if (Array.isArray(_samples)) {
      _samples = _samples.filter((sample) => {
        return !sample.isRemoved && sample.status !== SAMPLE_STATUS.REMOVED;
      });
    }

    if (this.orderEntryForm.get('samples').value?.missingSample) {
      _samples = this.orderEntryService.missingInformationGlyph;
    }

    let _tests: Test[] | string = this.orderEntryForm.get('testCodes').value;

    const fullValidation: boolean =
      this.ovFieldChanges.animalTypeChanged ||
      this.ovFieldChanges.samplesChanged ||
      !!(
        this.appStateService.existingAccession &&
        this.appStateService.existingAccession.orderedTests.length === 0 &&
        _tests.length
      );

    this.orderValidationService.reset(_tests, this.orderEntryService.missingInformationGlyph);

    if (
      _samples &&
      _samples.length &&
      _samples === this.orderEntryService.missingInformationGlyph &&
      !this.samples.input.nativeElement.value
    ) {
      _samples = [];
    }

    if (Array.isArray(_tests)) {
      _tests = this.orderEntryForm.get('testCodes').value.filter((addedTest) => !addedTest?.cancelReasonCode);
    }

    const runPartially =
      !!this.appStateService.existingAccession &&
      !this.ovFieldChanges.samplesChanged &&
      !this.ovFieldChanges.animalTypeChanged;

    let tests: Test[] | string;

    if (this.appStateService.existingAccession) {
      const existingTests = _tests.length ? _tests : this.orderEntryService.missingInformationGlyph;

      let newTests;
      if (Array.isArray(this.orderEntryForm.get('testCodes').value)) {
        newTests = this.orderEntryForm.get('testCodes').value.filter((item) => {
          return this.ovFieldChanges.newTests.filter(
            (newTest) => newTest.contextualizedTestId === item.contextualizedTestId
          ).length > 0
            ? item
            : false;
        });
      }
      tests = runPartially ? newTests : existingTests;
    } else {
      tests = _tests;
    }

    if (
      _animalType &&
      _samples?.length > 0 &&
      (tests?.length > 0 || tests === this.orderEntryService.missingInformationGlyph)
    ) {
      this.orderValidationRequested = true;
      this.orderValidationReceived = false;
      this.validationId = null;

      let resource: OrderIdResource | Accession = this.orderResource;

      if (this.appStateService.existingAccession) {
        resource = this.appStateService.existingAccession;
      }

      // Remove recommended customer messages for re-validation
      if (!this.appStateService.existingAccession || fullValidation) {
        this.customerMessages.purgeRecommendations();
      }

      this.orderValidationSub = this.orderValidationService
        .validateOrder(
          this.lab.id,
          resource,
          _animalType,
          _samples,
          tests,
          this.orderEntryService.missingInformationGlyph
        )
        .subscribe({
          next: (response) => {
            let orderValidationResponse;
            orderValidationResponse = response.body as OVResponse;
            this.orderValidationRequested = false;
            this.orderValidationReceived = true;

            this.validationId = orderValidationResponse['validationId'];

            this.loggerService.logAction('oelog-order-validation-id-added', this.validationId);

            // Parse orderValidationResponse compared to ordered items
            if (Array.isArray(tests)) {
              this.orderValidationService.resetUserChanges();

              this.orderValidationService.parseRecommendations(tests, orderValidationResponse, runPartially);
            }

            // Display recommended customer messages
            if (orderValidationResponse['actions'] && orderValidationResponse['actions'].customerMessages) {
              // we want to overwrite the current available customer message decline link
              // with the value returned from order validation
              orderValidationResponse['actions'].customerMessages.forEach((recommendedMessage) => {
                this.availableCustomerMessages.forEach((customerMessage) => {
                  if (customerMessage.customerMessageId === recommendedMessage.customerMessageId) {
                    customerMessage['_links'] = recommendedMessage._links;

                    this.customerMessages.add(this.customerMessages.searchChar + customerMessage.shortCode, true);
                  }
                });
              });
            }

            // Set displayType for order save button color/style
            this.orderValidationService.setOrderDisplayType();
          },
          error: (err) => {
            this.loggerService.logAction('oelog-order-validation-failure', err);
          },
        });
    } else if (_animalType && _samples?.length > 0 && tests?.length === 0 && runPartially) {
      this.validationId = null;
    }
  }

  readyDeclineRecommendation(item) {
    if (item.value._links && item.value._links.decline) {
      this.preventSave = true;
      this.orderValidationService.declineBlockRecommendation(item).subscribe((confirmation) => {
        this.preventSave = false;
        item.info.recommended = false;
        if (
          this.orderValidationService.orderValidationResponse &&
          this.orderValidationService.orderValidationResponse['actions']
        ) {
          this.orderValidationService.orderValidationResponse['actions'].customerMessages.forEach(
            (recommendedMessage, index) => {
              if (item.value.customerMessageId === recommendedMessage.customerMessageId) {
                this.orderValidationService.orderValidationResponse['actions'].customerMessages.splice(index, 1);
              }
            }
          );
        }
        this.customerMessages.delete(item);
        this.orderValidationService.setOrderDisplayType();
      });
    } else {
      item.info.recommended = false;
      this.customerMessages.delete(item);
    }
  }

  onAccessionNumberInput($event) {
    if (!this.orderEntryForm.controls.accessionNumber.value) {
      this.validateAccessionNumberResponse = {};
    } else {
      this.validateAccessionNumberResponse = {
        data: { value: this.orderEntryForm.controls.accessionNumber.value },
      };
    }

    if (this.accessionSub) {
      this.accessionSub.unsubscribe();
    }

    this.accessionSub = null;

    this.duplicateAccessionErrorMessageOnSave = '';

    this.safeStringFormat($event, 'accessionNumber');
  }

  hasNonSearchableErrors(controlName: string) {
    return FieldValidators.containsErrorsThatAreNot(this.orderEntryForm.get(controlName), [
      'serviceError',
      'serviceLoading',
      'noMatches',
    ]);
  }

  checkValidity(control: AbstractControl) {
    control.updateValueAndValidity();
  }

  validateAccessionNumber(accessionNumber: string) {
    if (
      this.supportsBarcodeTranslation &&
      accessionNumber &&
      !this.hasNonSearchableErrors('accessionNumber') &&
      !this.appStateService.existingAccession &&
      accessionNumber !== this.lastAccessionNumber
    ) {
      this.accessionNumber.nativeElement.focus();
      this.accessionSub = this.orderEntryService.validateAccessionNumber(accessionNumber).subscribe((response) => {
        this.validateAccessionNumberResponse = response;
        this.accessionSub = null;

        if (response.data) {
          this.lastAccessionNumber = response.data.value;

          this.orderEntryForm.patchValue({
            accessionNumber: response.data.value,
          });

          this.keyboardService.focusNext();
        } else {
          this.accessionNumber.nativeElement.focus();
        }

        setTimeout(() => {
          this.orderEntryForm.get('accessionNumber').updateValueAndValidity();
        });
      });
    }
  }

  handleAnimalTypeResponse(animalType: AnimalTypeInputValue) {
    if (!animalType) {
      return;
    }

    if (this.orderEntryForm.get('animalType') && this.orderEntryForm.get('animalType').value) {
      this.orderEntryForm.get('animalType').reset();
      this.orderEntryForm.get('customAnimalType').reset(null);
    }

    if (animalType.animalTypeCode) {
      this.orderEntryForm.get('animalType').setValue(animalType);

      // Set testSearchAnimalTypeCode to allow test search w/animal type
      this.testSearchAnimalTypeCode = animalType.animalTypeCode;
    } else {
      // since the animal type is invalid we want to display the validation error message
      this.orderEntryForm.controls.animalType.markAsDirty();
      this.orderEntryForm.controls.animalType.updateValueAndValidity();

      // Set testSearchAnimalTypeCode to allow test search w/animal type
      this.testSearchAnimalTypeCode = null;
    }
  }

  handleCustomerSearchResponse(customers: Customer[] | null, focusNext: boolean = true) {
    if (!customers || !customers.length) {
      this.searchingForCustomerData = false;
      return;
    }

    const activeCustomers = customers.filter((customer) => !!customer.active);
    if (customers.length === 1 || activeCustomers.length === 1) {
      const customer = customers.length === 1 ? customers[0] : activeCustomers[0];
      this.orderCustomer({ customer }, false, () => {
        this.customerCode.previousSearchValue = this.customerCode.value;
        if (focusNext) {
          this._focusNextField();
        }
        this.searchingForCustomerData = false;
      });
    } else if (customers.length > 1) {
      // Open search modal with customers payload
      this.customerSearch.open(of(customers), this.isEOrder);

      setTimeout(() => {
        this.customerSearch.focusFirstResult();
        this.searchingForCustomerData = false;
      }, 0);
    }
  }

  private _focusNextField() {
    setTimeout(() => {
      if (!this.isEOrder) {
        this.requisitionId.focus();
      } else if (this.isEOrder && !this.appStateService.existingAccession) {
        this.samples.focusInput();
      }
    });
  }

  // resets the order of autocomplete items
  resetDataDefaults() {
    this._availableCustomerMessages = [];
    this.defaultAvailableCustomerMessages.forEach((item) => {
      this._availableCustomerMessages.push(item);
    });

    this._availableSamples = [];
    this.defaultAvailableSamples.forEach((item) => {
      this._availableSamples.push(item);
    });
  }

  reset(suspendReload?: boolean) {
    if (this.appStateService.existingAccession && !suspendReload) {
      this.window.location.reload();
      return;
    }

    // reset any non form state data
    this.resetDataDefaults();

    this.unresolvedEorderTestCodes = [];
    this.validateAccessionNumberResponse = {};
    this.accessionSub = null;
    this.selectedCustomer = null;
    this.testSearchAnimalTypeCode = null;

    // reset any form or child component data
    this.patientSex.resetInputValue();
    this.discount.resetInputValue();

    this.samples.resetInputValue();
    this.orderEntryForm.reset({
      emailFax: { emails: [], faxNumbers: [] },
      samples: {
        samples: [],
        missingSample: false,
      },
    });
    this.orderEntryForm.markAsUntouched();
    this.orderEntryForm.markAsPristine();

    this.orderEntryForm.get('collectionDate').patchValue(null);
    this.labNotesService.labNotesOpen = false;

    this.setDefaults();

    this.lastAccessionNumber = '';

    this.accessionNumber.nativeElement.focus();
  }

  setDefaults() {
    this.loading = false;
    this.orderId = null;
    this.orderEntryService.electronicOrderVersion = null;
    this.orderResource = null;
    this.validationId = null;
    this.orderValidationRequested = false;
    this.orderValidationReceived = false;
    this.animalTypeHasChanged = false;
    this.samplesHaveChanged = false;

    this.orderValidationService.reset();

    this.resetSamplesAssociations();

    const done = () => {
      setTimeout(() => {
        this.appService.loadingSpinnerVisible = this.loading = false;
        this.orderSaveResponseReceived = false;
        this.orderSaveResponseRequested = false;
      }, 10);
    };
    if (!this.appStateService.existingAccession) {
      this.orderResourceSub = this.orderEntryService.getOrderId().subscribe({
        next: (response) => {
          // Response contains orderId and order create href
          this.orderId = response.orderId;
          this.orderResource = response;
          this.loggerService.logAction('oe-order-resource-update-added', {
            orderId: this.orderId,
            orderResourceLink: this.orderResource._links.create.href,
          });
          done();
        },
        error: () => {
          this.loggerService.logAction('oe-order-resource-update-failed', {
            orderId: this.orderId,
            orderResourceLink: this.orderResource._links.create.href,
          });

          this.router.navigateByUrl('/unavailable');
        },
      });
    } else {
      this.loggerService.logAction('oe-accession-resource-loaded', {
        orderId: this.appStateService.existingAccession,
      });
      done();
    }
  }

  orderCustomer(orderData?: { customer?: Customer }, navigateToNext: boolean = false, callback?: Function) {
    // we reset the customer request here to fix async possibility (long request through getCustomer,
    // customerSearch is opened and result picked)...long request could override search result
    let customer: Customer = null;

    if (orderData) {
      customer = orderData.customer;
    }

    if (customer && customer.customerCode) {
      this.selectedCustomer = customer;

      setTimeout(() => {
        this.customerSearch.previousActiveElement = null;

        this.customerCode.patch(customer.customerCode);

        if (!this.isEOrder) {
          if (!customer && this.customerSearch.previousActiveElement) {
            this.customerSearch.previousActiveElement.focus();
          } else if (navigateToNext) {
            // Focus behaviors tested in acceptance tests
            this.customerSearch.openButton.nativeElement.focus();
            this.keyboardService.focusNext();
          }
        } else if (this.isEOrder && !callback) {
          this.samples.focusInput();
        }
        this.orderEntryForm.controls.customerCode.updateValueAndValidity();

        if (callback) {
          callback();
        }
      }, 0);
    } else if (!orderData || customer === null) {
      this.orderEntryForm.patchValue({ customerCode: '' });
      this.selectedCustomer = null;

      setTimeout(() => {
        if (!this.isEOrder) {
          this.customerSearch.openButton.nativeElement.focus();
        }

        if (callback) {
          callback();
        }
      }, 0);
    }
  }

  save(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    // Check all conditionals that should prevent order save
    if (!this.preventOrderSave(true) && this.checkSampleAssociations()) {
      this.onSubmit(this.orderEntryForm);
    }
  }

  checkSampleAssociations(): boolean {
    // OK to save
    if (
      this.samplesAssociationsService.isReadyToSave(this.orderValidationService.getBlockedContextualizedTestIds()) ||
      this.orderEntryForm.controls.cancelReasonCode.value
    ) {
      return true;
    }

    if (document.activeElement === document.body) {
      const focusElement = document.querySelector('#orderValidationDetailsLink') as HTMLElement;
      if (focusElement) {
        focusElement.focus();
      }
    }

    // Pop sample association modal
    this.ovDetailsComponent.openSampleAssociations();

    this.loggerService.logAction('oelog-sample-association-required', this.getLogPayload('order-save-incomplete'));

    return false;
  }

  // a wrapper for the private logger service so components building from this class can access the logger
  logAction(actionName: string, payload: any): void {
    this.loggerService.logAction(actionName, payload);
  }

  onSubmit(form: FormGroup): void {
    // This condition should never happen. Failure logic should be handled in preventOrderSave method.
    if (this.preventOrderSave(true)) {
      throw new Error('Error: Save is not allowed. Please check logs.');
    }

    this.loading = true;
    this.orderSaveResponseReceived = false;
    this.orderSaveResponseRequested = true;
    this.duplicateAccessionErrorMessageOnSave = '';

    if (this.appStateService.existingAccession) {
      this.orderEntryService
        .updateAccession(
          this.appStateService.existingAccession,
          form,
          this.selectedCustomer,
          this.validationId,
          this.samplesAssociationsService.getSavableAssociations(form.get('testCodes').value),
          this.orderValidationService.manuallyBlockedContextualizedTestIds
        )
        .subscribe({
          next: () => {
            this.loading = false;
            this.reset();
          },
          error: (err) => {
            // flush open requests and show an error to the user
            this.orderSaveFailed();
            this.loading = false;
            this.globalErrorHandlerService.handleError(err);
          },
        });
    } else {
      this.orderEntryService
        .saveOrder(
          this.orderResource,
          form,
          this.selectedCustomer,
          this.validationId,
          this.samplesAssociationsService.getSavableAssociations(form.get('testCodes').value),
          this.orderValidationService.manuallyBlockedContextualizedTestIds
        )
        .subscribe({
          next: (response) => {
            this.savedAccessionLink = response['_links'].self.href;
            this.loading = false;
            this.reset();
            this.orderSavedSuccessfully();

            this.loggerService.logAction('oelog-save-complete', this.getLogPayload('order-save-complete'));
          },
          error: (err) => {
            // flush open requests and show an error to the user
            this.orderSaveFailed();
            this.loading = false;

            // Pop snackbar error
            this.globalErrorHandlerService.handleError(err);

            if (err.error && err.error.exceptionType === 'SampleAssociationException') {
              this.loggerService.logAction(
                'oelog-sample-association-correction-required',
                this.getLogPayload(err.error.displayErrorMessage)
              );
            }

            if (err.error && err.error.exceptionType === 'DuplicateAccessionException') {
              this.duplicateAccessionErrorMessageOnSave = err.error.displayErrorMessage;

              this.orderEntryForm.controls.accessionNumber.updateValueAndValidity();

              this.displayFormOverlay();

              this.loggerService.logAction(
                'oelog-Duplicate-accession-number',
                this.getLogPayload(err.error.displayErrorMessage)
              );
            }

            this.loggerService.logAction('oelog-save-failed', this.getLogPayload(err));
          },
        });
    }
  }

  public onReset($event?) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.loggerService.logAction('user-action-reset-button', this.getLogPayload());

      this.reset();
    }
  }

  orderSavedSuccessfully() {
    this.orderSaveResponseReceived = true;
    this.orderSaveSuccess = true;
  }

  orderSaveFailed() {
    this.orderSaveResponseReceived = true;
    this.orderSaveSuccess = false;
  }

  safeStringFormat($event, field?: string): void {
    DomUtil.persistCursor($event.target, async () => {
      this.orderEntryForm.get(field).setValue(($event.target.value = $event.target.value.toUpperCase()));
    });
  }

  logPreventSaveError(errorDescription: string, submitLog: boolean): void {
    if (submitLog) {
      this.loggerService.logAction('oelog-order-save-attempt', this.getLogPayload(errorDescription));
    }
  }

  getLogPayload(description?: string) {
    return {
      description: description || 'GENERAL',
      orderId: this.orderId,
      validationId: this.validationId,
      pendingRequests: this.pendingRequests,
    };
  }

  // Returns true if not in a savable state
  preventOrderSave(submitLog?: boolean): boolean {
    // Step through conditionals and log failure/eject as quickly as possible
    // This method fires via user action AND Angular lifecycle (e.g. OnChange, etc.)
    // Incremental/fail fast will prevent UX performance issues
    const hasPendingRequests = this.hasPendingRequests;
    if (hasPendingRequests) {
      this.logPreventSaveError('hasPendingRequests', submitLog);
      return true;
    }

    const hasUnsavedLabNotesError = this.labNotes?.hasUnsavedLabNotes;
    if (hasUnsavedLabNotesError) {
      this.logPreventSaveError('hasUnsavedLabNotesError', submitLog);
      return true;
    }

    const isLoadingError = this.loading;
    if (isLoadingError) {
      this.logPreventSaveError('isLoadingError', submitLog);
      return true;
    }

    if (!this.appStateService.existingAccession) {
      const hasOrderResourceError = !this.orderId || !this.orderResource;
      if (hasOrderResourceError) {
        this.logPreventSaveError('hasOrderResourceError', submitLog);
        return true;
      }
    }

    const hasSnackbarAuthError =
      this.snackbarChange && this.snackbarChange instanceof HttpErrorResponse && this.snackbarChange.status === 401;
    if (hasSnackbarAuthError) {
      this.logPreventSaveError('hasSnackbarAuthError', submitLog);
      return true;
    }

    const invalidFormError = this.orderEntryForm.invalid;
    if (invalidFormError) {
      this.logPreventSaveError('invalidFormError', submitLog);
      return true;
    }

    const testCodeFieldInUseError = this.preventSave;
    if (testCodeFieldInUseError) {
      this.logPreventSaveError('testCodeFieldInUseError', submitLog);
      return true;
    }

    const hasPetOwnerBillingError = this.petOwnerBilling && this.petOwnerBilling._form.invalid;
    if (hasPetOwnerBillingError) {
      this.logPreventSaveError('hasPetOwnerBillingError', submitLog);
      return true;
    }

    const hasCustomerMessageErrors =
      this.customerMessages &&
      (this.customerMessages.showNoMatchesFound || this.customerMessages.containsMaxTextSizeError());
    if (hasCustomerMessageErrors) {
      this.logPreventSaveError('hasCustomerMessageErrors', submitLog);
      return true;
    }

    const hasIncompleteInteractionErrors = this.customerMessages?.hasIncompleteInteractionError();
    if (hasIncompleteInteractionErrors) {
      this.logPreventSaveError('hasIncompleteInteractionErrors', submitLog);
      return true;
    }

    const hasCustomerCodeErrors =
      !this.orderEntryForm.get('customerCode').value ||
      (this.orderEntryForm.get('customerCode').value &&
        this.orderEntryForm.get('customerCode').value['customerCode'] === '') ||
      (!this.selectedCustomer && this.orderEntryForm.get('customerCode').value);
    if (hasCustomerCodeErrors) {
      this.logPreventSaveError('hasCustomerCodeErrors', submitLog);
      return true;
    }

    const orderValidationInProgress = this.orderValidationRequested && !this.orderValidationReceived;
    if (orderValidationInProgress) {
      this.logPreventSaveError('hasOrderValidationErrors', submitLog);
      return true;
    }

    const orderValidationMissing = !this.validationId;
    if (!this.appStateService.existingAccession && orderValidationMissing && this.orderValidationRequested) {
      this.logPreventSaveError('validationIdMissing', submitLog);
      return true;
    }

    const hasSamplesAssociationPendingError = this.samplesAssociationsRequested && !this.samplesAssociationsReceived;
    if (hasSamplesAssociationPendingError) {
      this.logPreventSaveError('hasSamplesAssociationPendingError', submitLog);
      // Prevent order save
      return true;
    }

    const hasBarcodeTranslationError = this.supportsBarcodeTranslation
      ? !this.validateAccessionNumberResponse.data
      : false;
    if (hasBarcodeTranslationError) {
      this.logPreventSaveError('hasBarcodeTranslationError', submitLog);
      // Prevent order save
      return true;
    }

    if (this.appStateService.existingAccession && this.orderEntryForm.pristine) {
      this.logPreventSaveError('noChangesInOrderEntryForm', submitLog);
      return true;
    }

    // No Errors, allow order save.
    return false;
  }

  getErrorValidations(name) {
    const field = this.orderEntryForm.get(name);
    // TODO: Why is owner validation fired repeatedly on load?
    // console.log('get error validations', name);
    let validations;

    if (field && field.dirty) {
      validations = Field.getErrorValidations(field.errors);
    }
    return validations || [];
  }

  displayFormOverlay() {
    const orderSavePending = this.orderSaveResponseRequested && !this.orderSaveResponseReceived;
    const invalidOrderId = !this.orderId && !this.appStateService.existingAccession;
    const fieldFocusRestricted = this.shouldKeepFocus();
    const electronicOrderLoadPending = this.getEorderLoadingStatus();

    return orderSavePending || invalidOrderId || fieldFocusRestricted || electronicOrderLoadPending;
  }

  shouldKeepFocus(
    restricted: RESTRICTED_FIELDS | RESTRICTED_FIELDS[] = ['accessionNumber', 'requisitionInfo', 'customerBarcodeId']
  ) {
    const form = this.orderEntryForm;
    let i, field;

    if (this.loadingOrderByReqId && Array.isArray(restricted)) {
      return true;
    } else if (this.loadingOrderByReqId) {
      return false;
    }

    if (!Array.isArray(restricted)) {
      restricted = [restricted];
    }

    const ln: number = restricted.length;

    for (i = 0; i < ln; i++) {
      field = form.get(restricted[i]);
      if (
        FieldValidators.containsErrorsThatAreNot(field, ['required', 'text']) ||
        (restricted[i] === 'accessionNumber' && this.accessionSub) ||
        this.duplicateAccessionErrorMessageOnSave
      ) {
        return true;
      }
    }

    return false;
  }

  getEorderLoadingStatus() {
    return this.loadingOrderByReqId;
  }

  isFormDisabled(): boolean {
    return this.orderSaveResponseRequested && !this.orderSaveResponseReceived;
  }

  private updateExistingAccessionSampleAssociations(): void {
    const sampleAssociations = this.appStateService.existingAccession.sampleAssociations;
    this.samplesAssociationsService.setExpectedSampleTestAssociations(sampleAssociations);
  }

  displayViewDetails(): boolean {
    return (
      (!this.orderSaveResponseReceived && this.orderValidationReceived && !this.samplesAssociationsRequested) ||
      (this.orderSaveResponseReceived && !this.orderSaveSuccess && this.orderValidationReceived) ||
      !!this.appStateService.existingAccession
    );
  }

  handleReqIdSearch($event) {
    this.loadingOrderByReqId = true;
  }

  handleElectronicOrder(electronicOrder: ElectronicOrder) {
    if (electronicOrder) {
      const isCanceled = electronicOrder.status === 'CANCELED';

      this.orderEntryService.electronicOrderVersion = electronicOrder.version;

      this.unresolvedEorderTestCodes = [];

      const requisitionId = this.orderEntryForm.controls.requisitionInfo.value.requisitionId;
      const existingCustomerCode = !!this.orderEntryForm.controls.customerCode.value;

      this.setElectronicOrderFormData(electronicOrder);

      let customers: Observable<Response<Customer[]>>;

      if (!existingCustomerCode) {
        customers = this.customerCode.getCustomerByCode();
      } else {
        customers = of({ data: [] });
      }

      if (this.orderSaveResponseReceived && !this.orderSaveSuccess) {
        this.reqIdDirtyScanDetected(requisitionId);
      }

      // Clear any existing order validations on order in case of existing entries
      this.orderValidationService.reset(
        this.orderEntryForm.get('testCodes').value,
        this.orderEntryService.missingInformationGlyph
      );

      let animalType: Observable<AnimalTypeInputValue>;

      if (electronicOrder?.patient?.animalTypeCode) {
        animalType = this.electronicOrderService.findAnimalTypeByCode(electronicOrder.patient.animalTypeCode);
      } else {
        animalType = of(null);
      }

      let orderedTestsRequests = [];

      const allTestsAreResolved = electronicOrder?.tests?.every((eoTest) => eoTest.resolved === true);

      if (!allTestsAreResolved) {
        this.unresolvedEorderTestCodes = electronicOrder?.tests?.map((eoTest) => eoTest.code);
      }

      if (electronicOrder?.tests?.length > 0 && allTestsAreResolved) {
        this.orderEntryForm.get('testCodes').reset();

        orderedTestsRequests = this.testCodes.prepareTestRequests(
          electronicOrder.tests.map((eoTest) => {
            return {
              testCode: eoTest.code,
              _links: eoTest._links,
            } as Test;
          })
        );
      } else if (!allTestsAreResolved) {
        this.loggerService.logAction(
          'eorder-has-unresolved-tests',
          this.getLogPayload('Electronic Order Had Unresolved Tests')
        );
      }

      forkJoin([customers, animalType].concat(orderedTestsRequests)).subscribe({
        next: (results) => {
          const customerRes = results[0] as Response<Customer[]>;

          this.handleCustomerSearchResponse(customerRes.data, false);

          this.handleAnimalTypeResponse(results[1] as AnimalTypeInputValue);

          const addedTests = results.slice(2).filter((ele) => ele !== null) as Test[];

          if (isCanceled && !this.unresolvedEorderTestCodes.length) {
            addedTests.forEach((test) => {
              test.cancelReasonCode = 'CUSTOMER_REQUEST';
            });
          }

          this.testCodes.writeAddedTests(addedTests);

          if (electronicOrder.customerMessages) {
            electronicOrder.customerMessages.forEach((recommendedMessageId) => {
              this.availableCustomerMessages.forEach((customerMessage) => {
                if (customerMessage.customerMessageId === recommendedMessageId) {
                  setTimeout(() => {
                    this.customerMessages.add(this.customerMessages.searchChar + customerMessage.shortCode, true);
                  }, 0);
                }
              });
            });
          }

          if (isCanceled) {
            if (!this.unresolvedEorderTestCodes.length) {
              this.orderEntryForm.get('testCodes').disable();
            }
          }
        },
        error: (err) => {
          // ERROR
          this.logAction('oelog-electronic-order-search-failure', this.getLogPayload(requisitionId));
          this.logAction('oelog-electronic-order-search-error', err);

          this.loadingOrderByReqId = false;
        },
        complete: () => {
          // COMPLETE
          this.logAction('oelog-electronic-order-add', this.getLogPayload(requisitionId));
          this.loadingOrderByReqId = false;

          setTimeout(() => {
            if (!this.customerSearch.visible) {
              this.samples.focusInput();
            }
          }, 0);
        },
      });
    } else {
      this.loadingOrderByReqId = false;
      if (electronicOrder !== null) {
        this.orderEntryService.electronicOrderVersion = null;
        this.orderEntryForm.patchValue({
          requisitionInfo: { requisitionId: '', electronicOrderId: '', electronicOrderVersion: null },
        });
      }

      setTimeout(() => {
        this.requisitionId.focus();
      });
    }
  }

  setElectronicOrderFormData(electronicOrder: ElectronicOrder) {
    if (!this.orderEntryForm.controls.customerCode.value && electronicOrder.customerCode) {
      this.orderEntryForm.controls.customerCode.setValue(electronicOrder.customerCode);
    }

    if (electronicOrder.collectionDate) {
      this.orderEntryForm.get('collectionDate').setValue(electronicOrder.collectionDate);
    }

    if (electronicOrder.vet) {
      this.orderEntryForm.get('vetName').reset();
      this.orderEntryForm.controls.vetName.setValue(electronicOrder.vet);
    }
    if (electronicOrder.owner) {
      if (electronicOrder.owner.name) {
        this.orderEntryForm.get('ownerName').reset();
        this.orderEntryForm.controls.ownerName.setValue(electronicOrder.owner.name);
      }

      if (this.petOwnerBilling) {
        if (
          (this.orderEntryForm.get('owner') && electronicOrder.owner.address?.city) ||
          electronicOrder.owner.address?.countryCode ||
          electronicOrder.owner.address?.postalCode ||
          electronicOrder.owner.address?.street1 ||
          electronicOrder.owner.fiscalCode ||
          electronicOrder.owner.address?.province
        ) {
          this.orderEntryForm.get('owner').reset();
          this.petOwnerBilling.reset();
        }

        if (electronicOrder.owner.address?.city) {
          this.petOwnerBilling._form.controls.city.setValue(electronicOrder.owner.address.city);
        }

        if (electronicOrder.owner.address?.countryCode) {
          this.petOwnerBilling._form.controls.countryCode.setValue(electronicOrder.owner.address.countryCode);
        }

        if (electronicOrder.owner.address?.postalCode) {
          this.petOwnerBilling._form.controls.postalCode.setValue(electronicOrder.owner.address.postalCode);
        }

        if (electronicOrder.owner.address?.street1) {
          this.petOwnerBilling._form.controls.street1.setValue(electronicOrder.owner.address.street1);
        }

        if (electronicOrder.owner.fiscalCode) {
          this.petOwnerBilling._form.controls.fiscalCode.setValue(electronicOrder.owner.fiscalCode);
        }

        if (electronicOrder.owner.address?.province) {
          this.petOwnerBilling._form.controls.province.setValue(electronicOrder.owner.address.province);
        }

        this.petOwnerBilling.save(null, true);
      }
    }

    if (electronicOrder.patient) {
      if (electronicOrder.patient.name) {
        this.orderEntryForm.get('patientName').reset();
        this.orderEntryForm.get('patientName').patchValue(electronicOrder.patient.name);
      }

      // we use the incoming patient sex code to find the match in our reference data
      if (electronicOrder.patient.sex) {
        this.orderEntryForm.get('patientSex').reset();
        this.availablePatientSexes.forEach((availablePatientSex) => {
          if (electronicOrder.patient.sex.code === availablePatientSex.code) {
            this.orderEntryForm.controls.patientSex.setValue(availablePatientSex, { emitEvent: false });
          }
        });
      }

      if (electronicOrder.patient && electronicOrder.patient.dateOfBirth) {
        this.orderEntryForm.get('patientAge').reset();
        this.orderEntryForm
          .get('patientAge')
          .setValue(new PatientAgeDOB(null, electronicOrder.patient.dateOfBirth, this.translate));
      }

      if (electronicOrder.patient.microChip) {
        if (this.orderEntryForm.get('microchipId') && this.orderEntryForm.get('microchipId').value) {
          this.orderEntryForm.get('microchipId').reset();
        }
        this.orderEntryForm.controls.microchipId.setValue(electronicOrder.patient.microChip);
      }
    }
  }
}
