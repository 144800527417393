import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, catchError, switchMap, take, tap } from 'rxjs';
import { AppStateService } from '../../app-state.service';
import { AppService } from '../../app.service';
import { TranslateService } from '@ngx-translate/core';
import { AuditService, GlobalErrorHandlerService } from '@lims-common-ux/lux';
import { OrderForm } from '../models/order-form.model';
import { Accession } from '../models/accession.model';
import { AccessionService } from '../services/accession/accession.service';

@Injectable({
  providedIn: 'root',
})
export class AccessionResolver {
  constructor(
    private appStateService: AppStateService,
    private appService: AppService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private translate: TranslateService,
    private auditService: AuditService,
    private accessionService: AccessionService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Accession | { error: string }> {
    this.appStateService.existingAccession = null;
    this.appStateService.accessionId = null;
    // This isn't that great, as it does the whole app spinner with a giant white overlay. Really, this should
    // probably be conditional depending on the users navigation. Direct nav should show the form overlay/spinner, while
    // landing right on an accession would do the whole app overlay.
    this.appService.loadingSpinnerVisible = true;

    return this.appStateService.orderForm$.pipe(
      take(1),
      switchMap(() => {
        return this.accessionService.loadAccession(route.params.accessionId);
      }),
      tap((accession: Accession) => {
        this.auditService.fireAccessionLoaded(route.params.accessionId);
        this.appStateService.orderForm = OrderForm.createOrderFormFromAccession(accession);
        this.appStateService.existingAccession = accession;
        this.appStateService.accessionId = route.params.accessionId;
      }),
      catchError(() => {
        this.appStateService.resetAccession();
        this.appStateService.existingAccession = null;
        this.appStateService.accessionId = null;

        const accessionNotFoundMessage = this.translate.instant('ACCESSION_NOT_FOUND');

        this.globalErrorHandlerService.handleError(accessionNotFoundMessage);

        return of({ error: accessionNotFoundMessage });
      })
    );
  }
}
