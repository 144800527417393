<div class="matches">
  <div class="popup-overlay" *ngIf="matches?.length">
    <ul class="dropdown-menu">
      <ng-container *ngFor="let match of matches; let i = index">
        <li
          *ngIf="i < matchesLimit || showAll"
          [class.active]="isActive(match)"
          (mousedown)="selectMatch($event, match)">
          <!-- SEXES & DISCOUNTS -->
          <a href="javascript:void(0)" tabindex="-1" class="spot-link" *ngIf="!match['$value'] && match['description']">
            <span *ngIf="match['shortCode']"
              ><span class="short-code">{{ match['shortCode'] }}</span
              >&nbsp;-&nbsp;</span
            >{{ match['description'] }}</a
          >
          <!-- SEXES & DISCOUNTS -->
          <!-- TEST CODE MATCHES -->
          <a href="javascript:void(0)" tabindex="-1" class="spot-link" *ngIf="!match['$value'] && match['testCode']">
            <span *ngIf="match['testCode']"
              ><span class="short-code">{{ match['testCode'] }}</span
              >&nbsp;-&nbsp;</span
            >{{ match['name'] }}</a
          >
          <!-- /TEST CODE MATCHES -->
          <!-- CUSTOMER MESSAGE MATCHES -->
          <a
            href="javascript:void(0)"
            tabindex="-1"
            class="spot-link"
            *ngIf="match['$value']"
            style="display: inline-block">
            <span *ngIf="match['shortCode']"
              ><span class="short-code">{{ match['shortCode'] }}</span
              >&nbsp;-&nbsp;</span
            >{{ match['$value'].text }}</a
          >
          <!-- /CUSTOMER MESSAGE MATCHES -->
        </li>
      </ng-container>
    </ul>
  </div>
</div>
