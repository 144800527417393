import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateSegmentInputService {
  incrementValue(value: string, min: number, max: number): string {
    const numericValue = this.getNumericValue(value);

    let updatedValue = null;

    if (!numericValue) {
      updatedValue = min.toString();
    } else if (numericValue < max) {
      updatedValue = String(numericValue + 1);
    }

    return updatedValue;
  }

  decrementValue(value: string, min: number, max: number): string {
    const numericValue = this.getNumericValue(value);

    let updatedValue = null;

    if (!numericValue) {
      updatedValue = max.toString();
    } else if (numericValue > min) {
      updatedValue = String(numericValue - 1);
    }

    return updatedValue;
  }

  getNumericValue(value: string) {
    if (value && value.trim()) {
      return parseInt(value, 0);
    } else {
      return null;
    }
  }
}
