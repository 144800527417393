<!--
It would be nice to use input[type]=number here, but some cursor selection and navigation options become
difficult. Using type=text provides greater utility at the expense of having to write/split validators.
The trade-off is close, but validators are easier to work with than custom code for value selection,
highlight, and cursor position handling at the moment.
-->
<input
  #input
  class="cl-date-segment-input"
  type="text"
  (blur)="handleBlur()"
  (focusin)="handleFocus()"
  (keydown)="handleKeydown($event)"
  [formControl]="control"
  [max]="max"
  [min]="min"
  [maxLength]="formControlName === 'year' ? 4 : 2"
  [placeholder]="placeholder" />
