import { inject, Injectable, InjectionToken } from '@angular/core';
import { firstValueFrom, forkJoin, of, Observable, filter, map, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IndexResource } from './interfaces/indexResource.interface';
import { FeaturesService, Lab, LabsService, Link, LoggerService, User, UserService } from '@lims-common-ux/lux';
import { StaticAppData } from './interfaces/application-data.interface';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const pageResourcePath = '/index';
const baseAppTitle = 'Clouded Leopard';

interface Info {
  environment: string;
}

export const INDEX_RESOURCE = new InjectionToken<IndexResource>('indexResource', {
  factory(): IndexResource {
    const service = inject(ApplicationInitService);
    return service.staticAppData.indexResource;
  },
  providedIn: 'root',
});

export const LABS = new InjectionToken<Lab[]>('labs', {
  factory(): Lab[] {
    const service = inject(ApplicationInitService);
    return service.staticAppData.labs;
  },
  providedIn: 'root',
});

export const CL_ENV = new InjectionToken<string>('clEnv', {
  factory(): string {
    const service = inject(ApplicationInitService);
    return service.staticAppData.environment;
  },
  providedIn: 'root',
});

export const CURRENT_USER = new InjectionToken<User>('currentUser', {
  factory(): User {
    const service = inject(ApplicationInitService);
    return service.staticAppData.currentUser;
  },
  providedIn: 'root',
});

@Injectable({
  providedIn: 'root',
})
export class ApplicationInitService {
  // tslint:disable-next-line:variable-name
  private _staticAppData = { indexResource: {} as IndexResource } as StaticAppData;

  constructor(
    private http: HttpClient,
    private labsService: LabsService,
    private userService: UserService,
    private featureService: FeaturesService,
    private titleService: Title,
    private broadcaster: MsalBroadcastService,
    private loggerService: LoggerService
  ) {}

  get appEnvironment(): { production: boolean } {
    return environment;
  }

  /**
   * Promise used here because it's required by Angular's initialization code.
   */
  initialize(): Promise<StaticAppData> {
    return firstValueFrom(
      this.broadcaster.inProgress$.pipe(
        filter((progress) => progress === InteractionStatus.None),
        switchMap(() => {
          return this.http.get<IndexResource>(pageResourcePath).pipe(
            switchMap((indexResource: IndexResource) => {
              Object.assign(this._staticAppData.indexResource, indexResource);
              return forkJoin([
                this.labsService.getLabs(indexResource._links.labs),
                this.userService.getUser(indexResource._links.user),
                this.loadEnvironmentData(indexResource._links.info),
                this.featureService.getFeatures(indexResource._links.features),
                this.appEnvironment.production ? this.loggerService.init() : of({}),
              ]);
            }),
            tap((forkResp) => {
              this._staticAppData.labs = forkResp[0];
              this._staticAppData.currentUser = forkResp[1];
              this._staticAppData.environment = forkResp[2].environment;
            }),
            map(() => this._staticAppData)
          );
        })
      )
    );
  }

  private loadEnvironmentData(infoLink: Link): Observable<Info> {
    return this.http.get<Info>(infoLink.href).pipe(
      tap((environmentInfo) => {
        const env = environmentInfo.environment || 'prod';
        let appTitle = baseAppTitle;
        if (env !== 'prod') {
          appTitle = `${appTitle} (${env})`;
        }
        this.titleService.setTitle(appTitle);
      })
    );
  }

  get staticAppData(): StaticAppData {
    return this._staticAppData;
  }
}
