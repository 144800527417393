<cl-field-wrapper
  luxFlex
  *ngIf="control !== null"
  id="customerCode"
  (lostFocus)="handleLosingFocus($event)"
  [errors]="control?.errors ? control?.errors : []"
  [required]="true"
  [label]="'LABELS.CUSTOMER_CODE' | translate"
  [loading]="searching">
  <!--suppress TypeScriptUnresolvedReference -->
  <input
    #input
    data-cy="customer-code-input"
    clCapitalizedText
    clSelectSelf
    [disabled]="disabled"
    (input)="handleInput($event?.target?.value)"
    (keydown.enter)="search()"
    [value]="val ? val : ''"
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item" />
</cl-field-wrapper>
