<cl-field-wrapper
  luxFill
  id="customerBarcodeId"
  [class.top-layer]="!!control?.errors"
  [label]="'LABELS.CUSTOMER_BARCODE_ID' | translate"
  [loading]="searching"
  (lostFocus)="handleLosingFocus($event)"
  [errors]="control?.errors ? control?.errors : []">
  <!--suppress TypeScriptUnresolvedReference -->
  <input
    #input
    data-cy="customer-barcode-input"
    clSelectSelf
    [disabled]="disabled"
    (input)="handleInput($event?.target?.value)"
    (keydown.enter)="search()"
    (keydown.tab)="search()"
    (keydown.arrowdown)="search()"
    (keydown.arrowup)="search()"
    (blur)="handleBlur()"
    [value]="val ? val : ''"
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item" />
</cl-field-wrapper>
