<cl-field-wrapper
  #componentWrapper
  class="autocomplete-wrapper"
  (lostFocus)="handleFocusOut($event)"
  (keydown.escape)="handleEscape($event)"
  [errors]="errors"
  [searcher]="true"
  [label]="'LABELS.PATIENT_SEX' | translate">
  <input
    #input
    clSelectSelf
    [placeholder]="placeholder"
    [value]="displayValue"
    (keydown.arrowDown)="onArrowDown($event)"
    (keydown.arrowUp)="onArrowUp($event)"
    (keydown.tab)="onTabDown($event)"
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item" />
  <div [hidden]="!displayedData?.length" class="search-results-wrapper">
    <button
      luxLayout="row"
      class="result-list-item"
      data-cy="autocomplete-item"
      type="button"
      (click)="handleClick($event, item)"
      *ngFor="let item of displayedData; let index = index; let first = first"
      [class.selection]="first">
      <span luxLayoutAlign="start" luxFlex="65%" *ngIf="index <= displayCnt" class="content">
        <span class="short-code">{{ item.shortCode }}</span
        >&nbsp;-&nbsp;{{ item.label }}
      </span>
    </button>
  </div>
</cl-field-wrapper>
