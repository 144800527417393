import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../shared/components/components.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LuxLayoutModule } from '@lims-common-ux/lux';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SampleAssociationComponent } from './sample-association.component';

@NgModule({
  declarations: [SampleAssociationComponent],
  exports: [SampleAssociationComponent],
  providers: [TranslatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    TranslateModule.forChild({}),
    LuxLayoutModule,
    ReactiveFormsModule,
  ],
})
export class SampleAssociationModule {}
