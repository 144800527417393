export class ObjectUtil {
  // ObjectMutil.merge mutates the original object(s).  Use carefully...
  static merge(source: any, target: any): any {
    let key, item;

    for (key in target) {
      if (target.hasOwnProperty(key)) {
        item = target[key];
        if (key in source && source[key] && source[key].constructor === Object && item && item.constructor === Object) {
          source[key] = this.merge(source[key], item);
        } else {
          source[key] = item;
        }
      }
    }
    return source;
  }

  static each(source: any, fn: (value?: any, key?: string, parent?: Object, path?: string) => void, root = ''): any {
    const keys = Object.keys(source);
    const ln: number = keys.length;
    let item, i, key, path;

    for (i = 0; i < ln; i++) {
      key = keys[i];
      if (source.hasOwnProperty(key)) {
        path = root ? `${root}.${key}` : key;
        item = source[key];
        if (typeof item === 'object' && item && (item.constructor === Object || Array.isArray(item))) {
          this.each(item, fn, path);
        }
        // occurs after iteration because we want to take actions on properties before deciding to take action on root
        fn(item, key, source, path);
      }
    }

    return source;
  }

  // ObjectMutil.delete mutates the original object(s).  Use carefully...
  static delete(source: any, fn: (value?: any, key?: string, parent?: Object, path?: string) => boolean): any {
    return this.each(source, (item, key, parent, path) => {
      if (!fn(item, key, parent, path)) {
        delete parent[key];
      }
    });
  }

  static dereference(source: any, path: string = '', slice?: number) {
    let reducer = path.split('.');
    reducer = reducer.slice(0, typeof slice === 'number' ? slice : reducer.length);
    if (reducer.length) {
      reducer[0] = source[reducer[0]];
      return reducer.reduce((current, nextProp, d) => {
        return current ? current[nextProp] : current;
      });
    } else {
      return source;
    }
  }

  // lack of a global map term -- intended to allow inline mapping (e.g ObjectUtil.swap(input, (input) => input2) outputs input2)
  // not called map because map should iterate on children properties
  static swap(source: any, fn: (source: any) => any) {
    return fn(source);
  }
}
