import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Field } from '../field/field.component';
import { DomUtil } from '../../utils';

@Component({
  selector: 'cl-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: Field.Providers(InputComponent),
})
export class InputComponent extends Field implements ControlValueAccessor, OnChanges {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Input()
  functionIndicator: string = '';
  @Input()
  inputType = 'text';
  @Input()
  placeholder = '';
  @Input()
  loading: boolean = false;
  focused: boolean = false;
  @Input() className: string;
  @Output()
  defaultAction = new EventEmitter();

  _value: any;

  @Input()
  set value(value) {
    if (value !== this._value) {
      const original = this._value;
      this._value = value;
      if (value !== null && this._onChange && (original || value)) {
        this._onChange(value);
      }
    }
  }

  get value() {
    return this._value;
  }

  _last_attributes: any = undefined;

  constructor(
    public renderer: Renderer2,
    element: ElementRef
  ) {
    super(element);
  }

  doDefaultAction() {
    if (this._onTouched) {
      this._onTouched();
    }

    this.defaultAction.emit(this._value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  blurInput(): void {
    this.input.nativeElement.blur();
  }

  focusInput(): void {
    this.input.nativeElement.focus();
  }

  ngOnChanges(): void {
    let p;
    const renderer = this.renderer,
      input = this.input.nativeElement,
      attributes = this.getAttributes() || {},
      last = this._last_attributes;

    for (p in attributes) {
      if (attributes.hasOwnProperty(p)) {
        renderer.setAttribute(input, p, attributes[p]);
        if (last && last[p] !== undefined) {
          delete last[p];
        }
      }
    }
    for (p in last) {
      if (last.hasOwnProperty(p)) {
        renderer.removeAttribute(input, p);
      }
    }
    this._last_attributes = Object.assign({}, attributes);
  }

  getAttributes() {
    return {};
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  onKeyDown($event) {
    this.doDefaultAction();
  }

  onNgModelChange($event) {}

  onInput($event) {}

  onFocus($event) {
    this.focused = true;
    DomUtil.selectContents(this.input.nativeElement);
  }

  onBlur($event) {
    setTimeout(() => {
      this.focused = false;
      this._onChange(this.value);
    });
  }

  reset() {
    this._value = null;
  }
}
