<form
  clModal="content"
  id="customer-search-form"
  class="spot-form"
  autocomplete="off"
  (keydown)="onKeyDown($event)"
  name="_form">
  <!-- MODAL TRIGGER -->
  <button
    #openButton
    (keydown.enter)="handleTriggerEvent($event)"
    (mousedown)="handleTriggerEvent($event)"
    title="{{ 'BUTTONS.SEARCH' | translate }}"
    class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon customer-search"
    luxFlex="35px">
    <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
      <title>{{ 'BUTTONS.SEARCH' | translate }}</title>
      <use xlink:href="/assets/icons/spot_icons.svg#search"></use>
    </svg>
  </button>
  <!-- /MODAL TRIGGER -->

  <lux-modal-container
    luxFill
    (modalClose)="close()"
    [formGroup]="_form"
    [modalId]="'customerSearchModal'"
    [modalWidth]="'80%'"
    [modalHeight]="'70%'"
    [showHeader]="false"
    [visible]="visible">
    <!-- HEADER -->
    <div luxLayout="row" class="spot-modal__header customer-search-modal-header row">
      <div class="spot-modal__titles" luxLayout="40%">
        <h2 class="spot-modal__title">
          {{ 'LABELS.CUSTOMER_SEARCH' | translate }}
        </h2>
      </div>

      <cl-button
        #closeButton
        (click)="close()"
        (keydown.escape)="close()"
        [type]="'icon-only'"
        [icon]="'close'"
        class="spot-modal__header-cancel-button cl-modal-cancel cl-modal-close">
      </cl-button>
    </div>
    <!-- /HEADER -->

    <div luxLayout="row" class="modal-form-content">
      <div luxLayout="column" luxFlex="100%" luxLayoutGap="20px">
        <div luxLayout="row" luxLayoutGap="20px" id="customer-search-first-row">
          <cl-input
            luxFlex="10%"
            #customerSearchCode
            id="customerSearchCode"
            formControlName="customerSearchCode"
            (keydown)="onKeyDown($event)"
            [label]="'LABELS.CODE' | translate"></cl-input>
          <cl-input
            luxFlex="40%"
            #customerSearchName
            id="customerSearchName"
            formControlName="customerSearchName"
            (keydown)="onKeyDown($event)"
            [label]="'LABELS.CUSTOMER_NAME' | translate"></cl-input>

          <cl-input
            luxFill
            luxFlex="50%"
            #customerSearchAddress
            id="customerSearchAddress"
            formControlName="customerSearchAddress"
            (keydown)="onKeyDown($event)"
            [label]="'LABELS.ADDRESS' | translate"></cl-input>
        </div>

        <div luxLayout="row" luxLayoutGap="20px" id="customer-search-second-row">
          <cl-input
            luxFlex="10%"
            #customerSearchPostalCode
            id="customerSearchPostalCode"
            formControlName="customerSearchPostalCode"
            (keydown)="onKeyDown($event)"
            [label]="'LABELS.POSTAL_CODE' | translate"></cl-input>
          <cl-input
            luxFlex="20%"
            #customerSearchCity
            id="customerSearchCity"
            formControlName="customerSearchCity"
            (keydown)="onKeyDown($event)"
            [label]="'LABELS.CITY' | translate"></cl-input>

          <cl-input
            luxFlex="30%"
            #customerSearchProvince
            id="customerSearchProvince"
            formControlName="customerSearchProvince"
            (keydown)="onKeyDown($event)"
            [label]="'LABELS.PROVINCE' | translate"></cl-input>

          <cl-input
            luxFlex="20%"
            #customerSearchCountry
            id="customerSearchCountry"
            formControlName="customerSearchCountry"
            (keydown)="onKeyDown($event)"
            [label]="'LABELS.COUNTRY' | translate"></cl-input>

          <cl-input
            luxFlex="20%"
            #customerSearchPhone
            id="customerSearchPhone"
            formControlName="customerSearchPhone"
            (keydown)="onKeyDown($event)"
            [microText]="phoneMicroText"
            [label]="'LABELS.PHONE' | translate"></cl-input>
        </div>

        <div id="customer-search-buttons" luxLayout="row" luxLayoutGap="20px" luxLayoutAlign="end center">
          <cl-button
            #searchButton
            id="customer-search-button"
            [disabled]="!readyToSearch"
            (click)="search()"
            [icon]="'search'">
            {{ 'BUTTONS.SEARCH' | translate }}
          </cl-button>
          <cl-button
            #resetButton
            (click)="reset()"
            id="customer-search-reset-button"
            [icon]="'refresh-redo'"
            [type]="'secondary'">
            {{ 'BUTTONS.RESET' | translate }}
          </cl-button>
        </div>
      </div>
    </div>

    <div luxLayout="row" class="modal-form-content">
      <div luxLayout="column" luxFlex="100%" class="customer-search-results-wrapper">
        <p class="spot-typography__text--body spot-typography__font-weight--bold" *ngIf="duplicateIndicator">
          {{ 'ERRORS_AND_FEEDBACK.DUPLICATE_FOUND' | translate }}
        </p>

        <table luxFlex id="customer-search-results" class="spot-data-table customer-search-results">
          <thead>
            <tr class="customer-line-item">
              <th luxFlex="15%">{{ 'LABELS.CODE' | translate }}</th>
              <th luxFlex="30%">{{ 'LABELS.CUSTOMER_NAME' | translate }}</th>
              <th luxFlex="30%">{{ 'LABELS.ADDRESS' | translate }}</th>
              <th luxFlex="10%">{{ 'LABELS.COUNTRY' | translate }}</th>
              <th luxFlex="20%">{{ 'LABELS.PHONE' | translate }}</th>
            </tr>
          </thead>

          <tbody #customerSearchResults>
            <!-- LOADING SPINNER -->
            <ng-container *ngIf="displayResults && loading && !customerMatches">
              <tr>
                <td colspan="6" align="center">
                  <span class="search-loading-spinner-container">
                    <svg *ngIf="loading" class="spot-icon spot-loading-spinner" aria-labelledby="title">
                      <title></title>
                      <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
                    </svg>
                  </span>
                </td>
              </tr>
            </ng-container>
            <!-- /LOADING SPINNER -->

            <!-- FOUND MATCHES -->
            <ng-container *ngIf="displayResults && !loading && customerMatches && customerMatches.length > 0">
              <ng-container *ngFor="let customer of customerMatches">
                <!--using mousedown instead of click to fix defect when click not firing after element get moved
https://stackoverflow.com/questions/46813181/click-event-is-not-firing-for-element-which-changes-position-when-has-focus-->
                <tr
                  class="customer-line-item result-data"
                  [ngClass]="{ 'inactive-customer': !customer.active }"
                  (keydown.tab)="onKeyDown($event)"
                  (keydown.escape)="onKeyDown($event)"
                  (mousedown)="selectCustomer(customer)"
                  valign="top">
                  <td luxFlex="15%">
                    <span *ngIf="!customer.active">
                      <span class="customer-code">{{ customer.customerCode }}</span>
                      <span class="inactive-indicator">{{ 'ERRORS_AND_FEEDBACK.INACTIVE' | translate }}</span>
                    </span>
                    <a
                      *ngIf="customer.active"
                      href="javascript:void(0)"
                      (focus)="setFocusPosition($event)"
                      (click)="selectCustomer(customer)"
                      class="customer-code"
                      #customerCardLinks
                      >{{ customer.customerCode }}</a
                    >
                  </td>
                  <td luxFlex="30%">
                    <span class="customer-name">{{ customer.name }}</span>
                    <span class="customer-street2" *ngIf="customer.primaryAddress.street2">
                      {{ customer.primaryAddress.street2 }}
                    </span>
                    <span class="customer-street3" *ngIf="customer.primaryAddress.street3">
                      {{ customer.primaryAddress.street3 }}
                    </span>
                    <span class="customer-street4" *ngIf="customer.primaryAddress.street4">
                      {{ customer.primaryAddress.street4 }}
                    </span>
                  </td>
                  <td luxFlex="30%">
                    <cl-address-display
                      *ngIf="customer.primaryAddress"
                      [isLineItemDisplay]="true"
                      [address]="customer.primaryAddress"></cl-address-display>
                  </td>
                  <td luxFlex="10%" align="left">
                    <span class="customer-country">{{ customer.primaryAddress.countryCode }}</span>
                  </td>
                  <td luxFlex="20%">
                    <span class="customer-phone">{{ customer.phone }}</span>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <!-- /FOUND MATCHES -->

            <!-- NO MATCHES FOUND MESSAGE -->
            <ng-container *ngIf="!displayResults && customerMatches && customerMatches.length < 1">
              <tr class="spot-data-table__row--out-of-range search-response-message">
                <td colspan="6">
                  <span id="noResultsMessage">{{ 'ERRORS_AND_FEEDBACK.NO_MATCHES' | translate }}</span>
                </td>
              </tr>
            </ng-container>
            <!-- /NO MATCHES FOUND MESSAGE -->

            <!-- DEFAULT VIEW SPACER CONTENT TO PREVENT UI WIGGLE -->
            <ng-container *ngIf="!customerMatches">
              <tr class="customer-line-item">
                <td colspan="6">&nbsp;</td>
              </tr>
              <tr class="customer-line-item">
                <td colspan="6">&nbsp;</td>
              </tr>
              <tr class="customer-line-item">
                <td colspan="6">&nbsp;</td>
              </tr>
              <tr class="customer-line-item">
                <td colspan="6">&nbsp;</td>
              </tr>
              <tr class="customer-line-item">
                <td colspan="6">&nbsp;</td>
              </tr>
            </ng-container>
            <!-- /DEFAULT VIEW SPACER CONTENT TO PREVENT UI WIGGLE -->
          </tbody>
        </table>
      </div>
    </div>
  </lux-modal-container>
</form>
