import { Component, forwardRef, Input } from '@angular/core';
import { CancelReason, CancelReasonCode } from '../../models/test.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cl-cancel-reason-selector',
  templateUrl: './cancel-reason-selector.component.html',
  styleUrls: ['./cancel-reason-selector.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CancelReasonSelectorComponent), multi: true },
  ],
})
export class CancelReasonSelectorComponent implements ControlValueAccessor {
  @Input() cancelReasons: CancelReason[];
  @Input() set cancelReasonCode(value: CancelReasonCode) {
    this.cancelReasonCodeSelected = value;
  }
  cancelReasonCodeSelected: CancelReasonCode;
  disabled: boolean = false;

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  writeValue(value: CancelReasonCode) {
    this.cancelReasonCodeSelected = value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateCancelReason(event) {
    this.cancelReasonCodeSelected = event.target.value;
    if (this.cancelReasonCodeSelected) {
      this.onChange(this.cancelReasonCodeSelected);
    } else {
      this.onChange(null);
    }
  }
}
