import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { LoggerService } from '@lims-common-ux/lux';
import { AppStateService } from '../../app-state.service';
import { PREVENTS_SAVE_REQUEST } from '../../app.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestCodesService {
  constructor(
    private appState: AppStateService,
    private http: HttpClient,
    private loggerService: LoggerService
  ) {}

  get searchUrl(): string {
    return this.appState.referenceData._links.testSearch.href;
  }

  getTestCode(testCode): Observable<any> {
    this.loggerService.logAction('oelog-test-code-search', {
      testCode: testCode,
    });

    return this.http.get(testCode._links.self.href, {
      context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
    });
  }

  isExistingAccession(): boolean {
    return !!this.appState.existingAccession;
  }

  isOrderedTest(testId: string): boolean {
    const found = this.appState.orderedTests?.filter((orderedTest) => orderedTest.testId === testId);
    return found?.length > 0;
  }

  isExistingAddOn(testId: string): boolean {
    const found = this.appState.existingAccession?.addOns?.filter((existingAddOn) => existingAddOn.testId === testId);
    return found?.length > 0;
  }
}
