import { TestBed, TestModuleMetadata } from '@angular/core/testing';

export class CustomFocusEvent extends FocusEvent {
  private _target: any;

  get target() {
    return this._target;
  }
  set target(value: any) {
    this._target = value;
  }

  constructor(className, props) {
    super(props);
    this.target = document.createElement('input');
    if (className) {
      this.target.setAttribute('class', className);
    }
  }
}

export class TestUtil {
  static keys = {
    special: '`~!@#$%^&*()-=_+[]{}:"\';,./<>?\\|'.split(/(.)/g).filter((v) => v),
    letters: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(/(.)/g).filter((v) => v),
    numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  };

  static missingInformationGlyph = '?';

  // requestAnimationFrame is used instead of setTimeout to specifically time programmatic behaviors and side effects
  // that rely on presentation screen paint. The standard HTML event loop microtask timing is 16ms.
  static microtaskTimeout = 16;

  static setupTestBed(moduleDef: TestModuleMetadata) {
    const resetTestingModule = TestBed.resetTestingModule,
      preventAngularFromResetting = () => (TestBed.resetTestingModule = () => TestBed),
      allowAngularToReset = () => (TestBed.resetTestingModule = resetTestingModule),
      before = window['beforeAll'],
      after = window['afterAll'];

    before((done) =>
      (async () => {
        resetTestingModule();
        preventAngularFromResetting();
        TestBed.configureTestingModule(moduleDef);
        await TestBed.compileComponents();

        // prevent Angular from resetting testing module
        TestBed.resetTestingModule = () => TestBed;
      })()
        .then(done)
        .catch(done.fail)
    );
    after(() => allowAngularToReset());
  }

  static generateLongString(length: number, seed: string = TestUtil.keys.letters.join('')) {
    const s: string = TestUtil.pad('', length, seed);
    return s;
  }

  static pad(str, len, pad) {
    if (len + 1 >= str.length) {
      str =
        str +
        Array(len + 1 - str.length)
          .join(pad)
          .slice(0, len);
    }
    return str;
  }
}
