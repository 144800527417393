<lux-modal-container
  [visible]="visible"
  [modalWidth]="'1375px'"
  [title]="'HELP.HELP' | translate"
  (modalClose)="close()"
  data-cy="help-modal"
  modalId="helpModal">
  <div luxLayout="row" luxLayoutGap="13px">
    <div luxLayout="column" luxFlex="33">
      <h3 class="spot-typography__heading--level-5">{{ 'HELP_CATEGORY.NAVIGATION' | translate }}</h3>
      <lux-keyboard-help-category [keyboardHelpItems]="keyboardHelpItemsNav"></lux-keyboard-help-category>
    </div>
    <div luxLayout="column" luxFlex="33">
      <h3 class="spot-typography__heading--level-5">{{ 'HELP_CATEGORY.FORMS' | translate }}</h3>
      <lux-keyboard-help-category [keyboardHelpItems]="keyboardHelpItemsForms"></lux-keyboard-help-category>
    </div>
    <div luxLayout="column" luxFlex="33">
      <h3 class="spot-typography__heading--level-5">{{ 'HELP_CATEGORY.GENERAL' | translate }}</h3>
      <lux-keyboard-help-category [keyboardHelpItems]="keyboardHelpItemsGeneral"></lux-keyboard-help-category>
    </div>
  </div>
</lux-modal-container>
