export class CustomerMessage {
  constructor(
    customerMessage: CustomerMessageReference,
    public customerMessageId: string = customerMessage.customerMessageId,
    public shortCode: string = customerMessage.shortCode || '',
    public text: string = customerMessage.text,
    public accountType: 'USER' | 'SYSTEM' = customerMessage?.accountType ? customerMessage?.accountType : 'USER',
    public label: string = shortCode && shortCode.length ? `${shortCode} - ${text}` : `${text}`
  ) {}
}

// This is the shape of a customer message in reference data, and also on Accessions
export interface CustomerMessageReference {
  customerMessageId: string;
  shortCode: string;
  text: string;
  accountType: 'USER' | 'SYSTEM';
}
