import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderEntryComponent } from './order-entry.component';
import { AccessionResolver } from '../shared/resolvers/accession.resolver';
import { LabResolver } from '../shared/resolvers/lab.resolver';

export const orderEntryRoutes: Routes = [
  {
    path: 'order-entry',
    redirectTo: '/order-entry/NOV',
    pathMatch: 'full',
  },
  {
    path: 'order-entry/:lab',
    resolve: {
      lab: LabResolver,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: OrderEntryComponent,
        canDeactivate: [
          (component: OrderEntryComponent) => {
            if (
              component.operationalRegionComponent.orderSaveResponseRequested &&
              !component.operationalRegionComponent.orderSaveResponseReceived &&
              !component.operationalRegionComponent.isExistingAccession
            ) {
              return confirm(component.operationalRegionComponent.unsavedChangesMessage);
            }
            return true;
          },
        ],
        runGuardsAndResolvers: 'always',
        data: {
          shouldReuseRoute: false,
        },
      },
      {
        path: 'accessions/:accessionId',
        component: OrderEntryComponent,
        data: { shouldReuseRoute: false },
        runGuardsAndResolvers: 'always',
        resolve: {
          accessionInfo: AccessionResolver,
        },
      },
    ],
  },
];

export const orderEntryRouting: ModuleWithProviders<any> = RouterModule.forChild(orderEntryRoutes);
